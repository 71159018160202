import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { iif } from 'rxjs';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';
import { CatalogsService } from '../../catalogs/catalogs.service';
import { AccidentService } from '../../demo/service/accidentservice';

@Component({
    templateUrl: './accident.component.html'
})
export class AccidentComponent implements OnInit {
    /*case: any;*/
    accident: any;

    states: any[] = [
        {name: 'Maryland', value: 1},
        {name: 'Virginia', value: 2},
        {name: 'Washington DC', value: 3}
    ];   

    cities: any[] = [
        {name: 'Arlington', value: 1},
        {name: 'Falls Church', value: 2},
        {name: 'Washington DC', value: 3},
        {name: 'Takoma Park', value: 4}
    ];

    // sex: any[] = [
    //     {name: 'Male', value: 'MALE'},
    //     {name: 'Female', value: 'FEMALE'}
    // ];

    // languages: any[] = [];

    // caseType: any[] = [
    //     {name: 'Car vrs Car', value: '1'},
    //     {name: 'Pedestrian vrs Car', value: '2'},
    //     {name: 'Uninsured Motorist', value: '3'},
    //     {name: 'Bike vrs Car', value: '4'},        
    // ];

     weatherConditions: any[] = [
         {name: 'Sunny', value: '1'},
         {name: 'Partially Cloudy', value: '2'},
         {name: 'Cloudy', value: '3'},
         {name: 'Overcast - cover with clouds', value: '4'},      
         {name: 'Rain', value: '5'},
         {name: 'Drizzle - light rain', value: '6'},
         {name: 'Snow', value: '7'},
         {name: 'Stormy', value: '8'},
         {name: 'Fog', value: '9'},
         {name: 'Hurricane', value: '10'}  
     ];

     liabilityStatus: any[] = [
        {name: 'Unknown', value: '0'},
        {name: 'Still Investigating', value: '1'},
        {name: 'Liability Accepted', value: '2'},
        {name: 'Liability Denied', value: '3'}
    ];

    yesno: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];

    county: any[] = [
        {name: 'Anne Arundel', value: '1'},
        {name: 'Baltimore County', value: '2'},
        {name: 'Baltimore City', value: '3'},
        {name: 'Calvert', value: '4'},
        {name: 'Carroll', value: '5'},
        {name: 'Dorchester', value: '6'},
        {name: 'Frederick', value: '7'},
        {name: 'Howard', value: '8'},
        {name: 'Montgomery', value: '9'},
        {name: 'Prince George', value: '10'},
        {name: 'Queen Anne', value: '11'},
        {name: 'Saint Mary', value: '12'}      
    ];

    // paralegal: any[] = [
    //     {name: 'Martha Isaacoff', value: '1'},
    //     {name: 'Tina', value: '2'}
    // ];

    /*personalIdType: any[] = []; */

    /* phoneType: any[] = [
        {name: 'Home', value: '1'},
        {name: 'Work', value: '2'}
    ];
    econtactRel: any[] = [
        {name: 'Son/Daughter', value: '1'},
        {name: 'In Law', value: '2'},
        {name: 'Most Significant One', value: '3'},
        {name: 'Boyfriend/Girlfriend', value: '4'},
        {name: 'Friend', value: '5'},
    ];
    yesno: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];
    entitle: any[] = [
        {name: 'Part A', value: 1},
        {name: 'Part B', value: 2}
    ];
    maritalStatus: any[] = [
        {name: 'Married', value: '1'},
        {name: 'Divorced', value: '2'}
    ]; */

    constructor(private breadcrumbService: AppBreadcrumbService, 
                private route: ActivatedRoute, 
                private accidentService: AccidentService, 
                private catalogsService: CatalogsService, 
                private router: Router) {
                    this.breadcrumbService.setItems([
                        { label: 'Step 1: New Accident Information', routerLink: ['/uikit/formlayout'] }
            /* { label: 'Accident Information' } */
        ]);
    }

    ngOnInit(): void {
        this.accident = {address:{}};
        this.route.paramMap.subscribe(params => {
            let accidentId = params.get("idAcc");
            if(accidentId) {
                this.accidentService.findById(accidentId).then(accident => {
                    this.accident = accident;
                    if(this.accident.accidentDate) {
                        this.accident.accidentDate=moment(this.accident.accidentDate, 'YYYY-MM-DD').toDate();
                        /*accident.doa = new Date(accident.doa);*/
                    }
                    if(this.accident.statuteDate) {
                        this.accident.statuteDate=moment(this.accident.statuteDate, 'YYYY-MM-DD').toDate();
                        /*accident.doa = new Date(accident.doa);*/
                    }
                    this.accident = accident;
                });
            }
        })

        /* this.catalogsService.findIdTypesAll().then(types => this.personalIdType = types);
        this.catalogsService.findLanguagesAll().then(response => this.languages = response); */
    }

    store() {
        
         if(this.accident.accidentDate) {
             this.accident.accidentDate = moment(this.accident.accidentDate).format('YYYY-MM-DD');
         }     

        //  if(this.accident.timeAcc) {
        //     this.accident.timeAcc = moment(this.accident.timeAcc).format('YYYY-MM-DD HH:MM AM/PM');
        // }   
        
        this.accidentService.store(this.accident)
        .then(() => this.router.navigate(['/accidents']));

      /*  this.case.accident = this.accident;*/
/*
        this.caseService.store(this.case)
         .then(() => this.router.navigate(['uikit/table']));
*/        
    }

    cancel(){
        this.router.navigate(['uikit/table']);
    }

}