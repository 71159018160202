<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'760px'}" [formGroup]="mainForm">    
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="insurance"><label style="color:red">* </label>Insurer</label>
                <p-dropdown id="insurance" formControlName="insurer" [options]="insurances" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose">
                </p-dropdown>                   
            </div>              
            <div class="p-field p-col-12 p-md-2">
                <label for="policyNumber">Policy Number</label>
                <p-inputMask id="policyNumber"  mask="*?**-***-***-***-***-***-***-***" unmask="true" formControlName="policyNumber"></p-inputMask>
            </div>                          
            <div class="p-field p-col-12 p-md-2">
                <label for="claimNumber">Claim No</label>
                <p-inputMask id="claimNumber"  mask="*?**-***-***-***-***-***-***-***" unmask="true" formControlName="claimNumber"></p-inputMask>
            </div>                        
            <div class="p-field p-col-12 p-md-2">
                <label for="insurancesource"><label style="color:red">* </label>Claim Recipient</label>
                <span class="p-float-label">
                        <p-dropdown id="insurancesource" formControlName="insurancesource" [options]="insuranceSource" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"  (onChange)="onInsuranceSourceChange($event)"></p-dropdown>                    
                </span>
            </div>
            <div class="p-field p-col-12 p-md-1">
                <label for="policyState"><label style="color:red">* </label>State</label>
                <input id="policyState" type="text" pInputText formControlName="policyState" 
                pTooltip="Specify the State where policy was issued" 
                tooltipPosition="top"
                autocomplete="doNotAutoComplete"
                maxlength="2">
            </div>  
            <div class="p-field p-col-12 p-md-2">
                <label for="filingDeadline" style="color:red">Deadline</label>
                <p-calendar id="filingDeadline" dateFormat="D, MM dd, yy" formControlName="filingDeadline" readonly="true" [disabled]="true"></p-calendar>
            </div>                                     
        </div>
    </div>
    <p-tabView [style]="{'height':'500px'}" >   
        <p-tabPanel header="1. Status Notes">
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="claimstatus">Liability Status</label>
                        <p-dropdown formControlName="claimstatus" [options]="cstatus" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div> 
                    <div class="p-field p-col-12 p-md-3">
                        <label for="liabilityStatusChangeDate">Liability Status Changed</label>
                        <p-calendar id="liabilityStatusChangeDate"showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="liabilityStatusChangeDate" disabled="true"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-3" formGroupName="insuranceCoverage">
                        <label for="pipStatus">PIP Status</label>
                        <p-dropdown [disabled]="selectedInsuranceSource === 'D' || mainForm.get('insurancesource').value === 'D'" #pipStatusDropdown formControlName="pipStatus" [options]="pipstatuses" 
                        optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div>
                    <div class="p-field p-col-12 p-md-3" formGroupName="insuranceCoverage">
                        <label for="pipStatusChangeDate">PIP Status Changed</label>
                        <p-calendar id="pipStatusChangeDate"showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="pipStatusChangeDate" disabled="true"></p-calendar>
                    </div> 
                    <p-messages [severity]="'error'" *ngIf="mainForm.hasError('claimstatusAndPipStatusRequired')" for="claimstatus pipStatus"></p-messages>
                    
                    <!-- <div class="p-field p-col-12 p-md-2">
                        <label for="status">Claim Status</label>
                        <input id="status" type="text" class="bold" pInputText formControlName="claimstatus" readonly autocomplete="doNotAutoComplete">                        
                    </div>   -->
                    <!-- <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1" style="width: 10%;">
                        <button pButton pRipple label="Pending" icon="pi pi-circle-off" class="p-button-text p-button-rounded"></button>                         
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1" style="width: 10%;">
                        <button pButton pRipple label="Investigating" icon="pi pi-search" class="p-button-text p-button-rounded" (click)="investigating()"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1">
                        <button pButton pRipple label="Denied" icon="pi pi-times" class="p-button-danger p-button-text"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-4 p-md-1" style="width: 10%;">
                        <button pButton pRipple label="Accepted" icon="pi pi-thumbs-up" class="p-button-success p-button-text"></button>
                    </div> -->
                    
                </div>
            </div>
            <p-table #dtc [value]="statusClaimNotes" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Created By</th>
                            <th pSortableColumn="noteCreatedOn">Created On
                                <p-sortIcon field="noteCreatedOn"></p-sortIcon>
                            </th>
                            <th>Notes</th>
                            <th>
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addNote()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="note">
                            <td>{{object.username}}</td>    
                            <td>{{object.noteCreatedOn | date:'short'}}</td>                                            
                            <!-- <td>{{(object.comments | slice:0:100)+'...'}}</td> -->
                            <td>{{(object.comments.length>100)? (object.comments | slice:0:100)+'...':(object.comments)}}</td>

                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="editNote(object)"></button>
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{statusClaimNotes ? statusClaimNotes.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No notes found.</td>
                        </tr>
                    </ng-template>
            </p-table>  
        </p-tabPanel>
        <p-tabPanel header="2. Adjuster(s)">
            <p-table #dtc [value]="adjusters" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" sortField="statusLastModified" sortOrder="-1"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                        <ng-template pTemplate="header">
                            <tr>
                               
                                <th>Adjuster Name</th>                                
                                <th>Adjuster Phone</th>                                
                                <th>Adjuster Fax</th>
                                <th>Type</th>
                                <th class="actions">
                                    <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                        (click)="addAdjuster()"></p-button>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="liability">
                                <td>{{object.name}}</td>
                                <td>{{object.phone}}</td>                                
                                <td>{{object.fax}}</td>
                                <td>{{object.adjusterType}}</td>                                
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="editAdjuster(object)"></button>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                <!-- Total of {{case.defendants ? case.defendants.length : 0 }}. -->
                            </div>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No adjuster found.</td>
                            </tr>
                        </ng-template>
                </p-table>
        </p-tabPanel>        
        <p-tabPanel [disabled]="selectedInsuranceSource === 'P' || mainForm.get('insurancesource').value === 'P'" header="3. Liability Coverage" formGroupName="insuranceCoverage">            
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="BI Liability Each Person">BI Liability Each Person</label>
                    <p-inputNumber mode="currency" currency="USD" id="biLEachPerson" type="text" formControlName="biLEachPerson"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="biLEachOccurrence">BI Liability Each Occurrence</label>
                    <p-inputNumber mode="currency" currency="USD" id="biLEachOccurrence" type="text" formControlName="biLEachOccurrence"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="pdLCoverage">Property Damage Liability</label>
                    <p-inputNumber mode="currency" currency="USD" id="pdLCoverage" type="text" formControlName="pdLCoverage"></p-inputNumber>
                </div>                    
            </div>            
        </p-tabPanel>        
        <p-tabPanel [disabled]="selectedInsuranceSource === 'D' || mainForm.get('insurancesource').value === 'D'" header="4. PIP Coverage & UM/UIM" formGroupName="insuranceCoverage">
            <div class="p-grid p-formgrid p-fluid">     
                <div class="p-field p-col-12 p-md-2">
                    <label for="gapInsurance">GAP Insurance</label>
                    <span class="p-float-label">
                        <p-dropdown id="gapInsurance" formControlName="gapInsurance" [options]="yesnouk" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>        
                <div class="p-field p-col-12 p-md-2">
                    <label for="limitCarRental">Limit Car Rental Expense</label>
                    <span class="p-float-label">
                        <p-dropdown id="limitCarRental" formControlName="limitCarRental" [options]="yesnouk" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>        
                <div class="p-field p-col-12 p-md-2">
                    <label for="deductibleCollision">Collision Deductible</label>
                    <p-inputNumber mode="currency" currency="USD" id="deductibleCollision" type="text" formControlName="deductibleCollision">
                    </p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="pipCoverage">PIP Coverage</label>
                    <p-inputNumber mode="currency" currency="USD" id="pipCoverage" type="text" formControlName="pipCoverage"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="medPay">Medical Payments</label>
                    <p-inputNumber mode="currency" currency="USD" id="medPay" type="text" formControlName="medPay"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umBIEachPerson">UM BI Each Person</label>
                    <p-inputNumber mode="currency" currency="USD" id="umBIEachPerson" type="text" formControlName="umBIEachPerson"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umBIEachOccurrence">UM BI Each Occurrence</label>
                    <p-inputNumber mode="currency" currency="USD" id="umBIEachOccurrence" type="text" formControlName="umBIEachOccurrence"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umPDDeductible">UM PD Deductible</label>
                    <p-inputNumber mode="currency" currency="USD" id="umPDDeductible" type="text" formControlName="umPDDeductible"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umPDCoverage">UM PD</label>
                    <p-inputNumber mode="currency" currency="USD" id="umPDCoverage" type="text" formControlName="umPDCoverage"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umCollision">UM Collision</label>
                    <p-inputNumber mode="currency" currency="USD" id="umCollision" type="text" formControlName="umCollision"></p-inputNumber>
                </div>                
                <div class="p-field p-col-12 p-md-2">
                    <label for="rentalCoverage">Rental Coverage</label>
                    <p-inputNumber mode="currency" currency="USD" id="rentalCoverage" type="text" formControlName="rentalCoverage"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="rentalEachDay">Rental Coverage per day</label>
                    <p-inputNumber mode="currency" currency="USD" id="rentalEachDay" type="text" formControlName="rentalEachDay"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="rentalEachLoss">Rental Coverage Each Loss</label>
                    <p-inputNumber mode="currency" currency="USD" id="rentalEachLoss" type="text" formControlName="rentalEachLoss"></p-inputNumber>
                </div>
            </div>
    </p-tabPanel>
        <p-tabPanel header="5. Defendant" [disabled]="selectedInsuranceSource === 'P' || mainForm.get('insurancesource').value === 'P'">
            <p-table #dtc [value]="defendants" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" sortField="statusLastModified" sortOrder="-1"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Defendant Name</th>                                
                                <th>Defendant Phone</th>                                
                                <th>Defendant Address</th>
                                <th>Type</th>
                                <th class="actions">
                                    <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                        (click)="addNDefendant()"></p-button>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="defendant">
                                <td>{{object.name}}</td>
                                <td>{{object.phone}}</td>                                
                                <td> <div>{{object.address.street}},
                                    <strong *ngIf="object.address.apartment">Apt No: {{object.address.apartment}}.</strong>
                                    {{object.address.city}}. {{object.address?.state}}. {{object.address?.zipcode}}</div></td>
                                <td>{{object.defendantType}}</td>                                 
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="editNDefendant(object)"></button>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                <!-- Total of {{case.defendants ? case.defendants.length : 0 }}. -->
                            </div>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No defendant found.</td>
                            </tr>
                        </ng-template>
                </p-table>
        </p-tabPanel>
    </p-tabView>
</div>