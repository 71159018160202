<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'600px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-3">
                <label for="name">Name</label>
                <input id="name" type="text" pInputText formControlName="name" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="dob">Date of Birth</label>
                <p-calendar id="dob" formControlName="dob"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="isex">Sex</label>
                <p-dropdown inputId="isex" formControlName="isex" [options]="sexList" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="isCompany">Is a Company</label>
                <br>
               <p-inputSwitch formControlName="isCompany" ></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="phone">Phone</label>
                <p-inputMask id="phone" mask="(999) 999-9999" formControlName="phone" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="email">Email</label>
                <input id="email" type="text" pInputText formControlName="email" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="defendantType"><label style="color:red">*</label>Type</label>
                <p-dropdown inputId="defendantType" formControlName="defendantType" [options]="defendantTypes" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="driverLicenseNo">Driver License Number</label>
                <p-inputMask id="driverLicenseNo"  mask="*?**-***-***-***-***-***" formControlName="driverLicenseNo"></p-inputMask>                
            </div>
            <div class="p-field p-col-12 p-md-1">
                <label for="licenseState">State</label>
                <input id="licenseState" type="text" pInputText formControlName="licenseState" 
                pTooltip="Specify the State where DL was issued" 
                tooltipPosition="top"
                autocomplete="doNotAutoComplete">
            </div>                                   
            <div class="p-field p-col-12 p-md-2">
                <label for="taxDocType">Other Id type</label>
                <p-dropdown id="taxDocType" formControlName="taxDocType" [options]="personalIdTypes" optionValue="value" optionLabel="name"                  
                autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>                                                
            <div class="p-field p-col-12 p-md-3">
                <label for="idNumber">Personal Id Number</label>
                <p-inputMask id="idNumber"  mask="*?**-***-***-***-***-***" formControlName="idNumber"></p-inputMask>                
            </div>
            <br>
            <div class="p-field p-col-12 p-md-20">
                <address *ngIf="addressEnabled" formGroupName="address"></address>    
            </div>   
        </div>                       
    </div>  
    <div class="card">
        <h5>Additional Information about the Driver</h5>
        <div class="p-fluid p-formgrid p-grid">                                                 
            <div class="p-field p-col-12 p-md-2">
                <label for="driverAlsoInsured">Also the Insured</label>
                <br>
               <p-inputSwitch formControlName="driverAlsoInsured" ></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="noDriverLicense">Doesn't have a License</label>
                <br>                                                    
               <p-inputSwitch formControlName="noDriverLicense" ></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="authorizedDriver">Is not an authorized Driver</label>
                <br>                                                     
                <p-inputSwitch formControlName="authorizedDriver" ></p-inputSwitch>
            </div>
            <div class="p-field p-col-12 p-md-2">                                                    
                <label for="wasGivenTicket">Was given a ticket</label>
                <br>
               <p-inputSwitch formControlName="wasGivenTicket" ></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-2"> 
                <label for="duiCharges">DUI Charges</label>
                <br>                                                   
               <p-inputSwitch formControlName="duiCharges" ></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-2">                                                    
                <label for="takenByPolice">Taken into Custody</label>
                <br>
               <p-inputSwitch formControlName="takenByPolice" ></p-inputSwitch>
           </div> 
        </div>
    </div>                      
</div>
        