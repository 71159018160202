<div class="p-grid">
	<div class="p-col-12">
		
		<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

		<div class="card" style="background-color: powderblue;">
			<h5>Accident Information</h5>
			<div class="p-fluid p-formgrid p-grid">
				<!-- <div class="p-field p-col-12 p-md-2">
                    <label for="caseType">Case Type</label>
                    <span class="p-float-label">
                        <p-dropdown inputId="caseType" [options]="caseType" [(ngModel)]="accident.caseType" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                        <label>Choose</label>
                    </span>
				</div> -->
				<div class="p-field p-col-12 p-md-2">
					<label for="doa">Date when accident happened</label>
					<p-calendar [(ngModel)]="accident.accidentDate" dataType="string"></p-calendar>
				</div>

				<div class="p-field p-col-12 p-md-2">
					<label for="time">Time when accident happened</label>
					<p-inputMask mask="99:99 aa" [(ngModel)]="accident.timeAcc"></p-inputMask>									
				</div>
				

				<div class="p-field p-col-12 p-md-2">
					<label for="address">Street Address</label>
					<input id="address" type="text" pInputText [(ngModel)]="accident.address.streetAddress">
				</div>				
				
				<div class="p-field p-col-12 p-md-2">
					<label for="state">Cities</label>
					<span class="p-float-label">
						<p-dropdown inputId="city" [options]="cities" [(ngModel)]="accident.address.city" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>			

				<div class="p-field p-col-12 p-md-2">
					<label for="state">State</label>
					<span class="p-float-label">
						<p-dropdown inputId="state" [options]="states" [(ngModel)]="accident.address.usState" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="zip">Zipcode</label>
					<input id="zip" type="text" pInputText [(ngModel)]="accident.address.zipcode">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="coordinates">Coordinates</label>
					<input id="coordinates" type="text" pInputText [(ngModel)]="accident.gCoordinates">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="weatherConditions">Weather Conditions</label>
						<span class="p-float-label">
							<p-dropdown inputId="weatherConditions" [options]="weatherConditions" [(ngModel)]="accident.wConditions" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
							<label>Choose</label>
						</span>
				</div>
				
				<div class="p-field p-col-12 p-md-2">
					<label for="speedLimit">Speed Limit</label>
					<span class="p-float-label">
						<p-inputNumber mode="decimal" [min]="0" pInputNumber [(ngModel)]="accident.speedLimit"></p-inputNumber>
						<label></label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="speedTraveled">Speed Traveled</label>
					<span class="p-float-label">
						<p-inputNumber mode="decimal" [min]="0" pInputNumber [(ngModel)]="accident.speedTravel"></p-inputNumber>
						<label></label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="speedTraveled"># of People in the car</label>
					<span class="p-float-label">
						<p-inputNumber mode="decimal" [min]="0" [max]="20" pInputNumber [(ngModel)]="accident.noPeopleInCar"></p-inputNumber>
						<label></label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="comingFrom">Coming From</label>
					<input id="comingFrom" type="text" pInputText [(ngModel)]="accident.cFrom">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="goingTo">Going To</label>
					<input id="goingTo" type="text" pInputText [(ngModel)]="accident.goingTo">
				</div>
				<!-- <div class="p-field p-col-12 p-md-2">
                    <label for="liabilityStatus">Liability Status</label>
                    <span class="p-float-label">
                        <p-dropdown inputId="liabilityStatus" [options]="liabilityStatus" [(ngModel)]="accident.liabilityStatus" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                        <label>Choose</label>
                    </span>
				</div> -->
				<!-- <div class="p-field p-col-12 p-md-2">
					<label for="dateliabilityChange">Date of Last Liability Change</label>
					<p-calendar [(ngModel)]="accident.dateliabilityChange" dataType="string"></p-calendar>
				</div> -->
				<div class="p-field p-col-12 p-md-2">
                    <label for="airbagsDeployed">Did Airbags Deployed?</label>
                    <span class="p-float-label">
                        <p-dropdown inputId="airbagsDeployed" [options]="yesno" [(ngModel)]="accident.airbagsDeployed" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                        <label>Choose</label>
                    </span>
				</div>
			</div>	
		</div>
		
		<div class="card">
			<h5>Description of the accident</h5>
			<div class="p-fluid p-formgrid p-grid">
					<label>Write a clear explanation about how the accident happened</label>
					<textarea rows="7"  id="address" type="text" pInputText [(ngModel)]="accident.descriptionAccident" autocomplete="off" ></textarea>					
			</div>
			<hr>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="noLanes">How many lanes are available where it happened</label>
					<span class="p-float-label">
						<p-inputNumber mode="decimal" [min]="0" pInputNumber [(ngModel)]="accident.noLanes"></p-inputNumber>
						<label></label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="laneLocation">In which lane was your car from left to right?</label>
					<span class="p-float-label">
						<p-inputNumber mode="decimal" [min]="0" pInputNumber [(ngModel)]="accident.laneLocation"></p-inputNumber>
						<label></label>
					</span>
				</div>
			</div>		
		</div>

<div class="card"style="background-color: powderblue;">
		<h5>Police Report Information</h5>	
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-md-2">
			<label for="policeReport">Is there a Police Report?</label>
			<span class="p-float-label">
				<p-dropdown inputId="policeReport" [options]="yesno" [(ngModel)]="accident.policeReport" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
				<label>Choose</label>
			</span>
		</div>
		<div class="p-field p-col-12 p-md-2">
			<label for="policeReportNo">Police Report No.</label>
			<input id="policeReportNo" type="text" pInputText [(ngModel)]="accident.policeReportNo">
		</div>
		<div class="p-field p-col-12 p-md-2">
			<label for="investigator">Investigator</label>
			<input id="investigator" type="text" pInputText [(ngModel)]="accident.investigator">
		</div>
		<div class="p-field p-col-12 p-md-2">
			<label for="investigatorPh">Investigator Phone</label>
			<p-inputMask mask="(999) 999-9999" [(ngModel)]="accident.investigatorPh"></p-inputMask>
		</div>
		<div class="p-field p-col-12 p-md-2">
			<label for="county">County</label>
			<span class="p-float-label">
				<p-dropdown inputId="county" [options]="county" [(ngModel)]="accident.investigatorCounty" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
				<label>Choose</label>
			</span>
		</div>          
	</div>
</div>

<!-- <div class="card">
	<h5>Paralegal and Statute Date</h5>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-md-2">
			<label for="paralegal">Paralegal</label>
			<span class="p-float-label">
				<p-dropdown inputId="paralegal" [options]="paralegal" [(ngModel)]="accident.paralegal" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
				<label>Choose</label>
			</span>
		</div> 	
			<div class="p-field p-col-12 p-md-2">
				<label for="statuteDate">Statute of Limitation Date</label>
				<p-calendar [(ngModel)]="accident.statuteDate" dataType="string"></p-calendar>
			</div>
		
	</div>
</div> -->

<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>