import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';

@Component({
    selector: 'vnote',
    templateUrl: './vnote.component.html',
    styleUrls: ['./vnote.component.scss']
})
export class VehicleNoteComponent implements OnInit {
    vnote: any;
    mainForm: FormGroup;
    // statuses: any[] =  [{value:'Pending'},{value:'Investigating'}, {value:'Accepted'},{value:'Denied'}];

    notificationType: any[] = [
        {name: 'Mail', value:1},
        {name: 'Email', value: 2},
        {name: 'Phone call', value: 3},
        {name: 'Whatsapp/Text Message', value: 5},
        {name: 'In Person', value: 4}
    ];
   
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            username:[],
            noteCreatedOn:[],
            comments:[],
            notifiedBy:[null, Validators.required],
            eventDate:[null, Validators.required],
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.vnote = this.config.data;
        }
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendvNote(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closevNote();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

    

}
