import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { CasesService } from '../cases.service';
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';
import Dates from 'src/app/utilities/Dates';



@Component({
    selector: 'caselist',
    templateUrl: './caselist.component.html',
    styleUrls: ['./caselist.component.scss']
})
export class CaseListComponent implements OnInit {
    loading = false;
    catalogType: string;
    results: any;
    searchForm: FormGroup;
    activeIndex = 0;
    maxResults = 10;
    search = {
        from: 0,
        to: this.maxResults,
        term: null
    }

    plaintiffStatuses: any[] = [
        { name: 'Treating', value: 'T' },
        { name: 'Gathering Bills and Records', value: 'GBR' },
        { name: 'Ready for Demand', value: 'RD' },
        { name: 'Pending Demand', value: 'PD' },
        { name: 'Demand Sent', value: 'DS' },
        { name: 'Offer', value: 'O' },
        { name: 'Pending settlement', value: 'PS' },
        { name: 'Pending Litigation', value: 'PL' },
        { name: 'Litigation', value: 'L' },
        { name: 'Settled', value: 'S' },
        { name: 'Changed Attorney', value: 'CHA' },
        { name: 'Dumped', value: 'D' },
    ];


    constructor(private fb: FormBuilder, private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute,
        private router: Router, private casesService: CasesService) {
        this.breadcrumbService.setItems([
            { label: 'Cases' },
        ]);
        this.searchForm = fb.group({
            term: [],
            plaintiffStatus: []
        });
    }


    ngOnInit() {
        this.route.params.subscribe(event => {
            this.catalogType = event.catalogType;
            this.load();
        });
    }

    get total(): number {
        return this.results && this.results.total || 0;
    }

    get objects(): any[] {
        return this.results && this.results.data || [];
    }

    newSearch() {
        this.search.from = 0;
        this.search.to = this.maxResults;
        this.load();
    }

    load() {
        this.search.term = this.searchForm.value.term || "";

        this.loading = true;
        if (this.activeIndex == 0) {
            this.casesService.findAll(this.search).then(results => this.results = results).then(() => this.loading = false);
        }
        if (this.activeIndex == 1) {
            this.casesService.findMyCases(this.search).then(results => this.results = results).then(() => this.loading = false);
        }
        // else {
        //     this.casesService.findClosing(params).then(objects => this.objects = objects);
        // }
    }

    changeTab(event) {
        this.search.from = 0;
        this.search.to = this.maxResults;
        this.activeIndex = event;
        this.load();
    }

    loadPage(event) {
        this.search.from = event.first;
        this.search.to = event.first + event.rows;
        this.load();
    }

    addNew() {
        this.router.navigate([this.router.url, 'new']);
    }

    edit(object) {
        this.router.navigate([this.router.url, object.caseId]);
    }

    editNotes(object) {
        this.router.navigate([this.router.url, object.caseId]);
    }

    delete(object) {
        //this.catalogsService.deleteObjectByReference({type:this.catalogType, id: object.id}).then(() => this.load());
    }

    getAge(dob: string) {
        return Dates.getAge(dob);
    }

    done() {
    }

    cancel() {

    }

    onStatusChange() {
        this.load();
    }

    calculateTimeFrameSinceStatusChange(statusChange: Date): string {
        if (!statusChange) return '';

        const statusChangeDate = new Date(statusChange);
        const today = new Date();

        // Calculate the difference in years, months, and days
        const years = differenceInYears(today, statusChangeDate);
        const months = differenceInMonths(today, statusChangeDate) % 12;
        const days = differenceInDays(today, statusChangeDate) % 30; // Approximation, can vary based on months

        // Constructing the output string conditionally
        let timeFrame = '';

        if (years > 0) {
            timeFrame += `${years} year${years > 1 ? 's' : ''}`;
        }
        if (months > 0) {
            if (timeFrame.length > 0) timeFrame += ' ';
            timeFrame += `${months} month${months > 1 ? 's' : ''}`;
        }
        if (days > 0) {
            if (timeFrame.length > 0) timeFrame += ' ';
            timeFrame += `${days} day${days > 1 ? 's' : ''}`;
        }

        return timeFrame;
    }


    calculateDaysSinceStatusChange(statusChange: Date): number {
        if (statusChange) {
            const statusChangeDate = new Date(statusChange);
            const today = Date.now();
            const difference = differenceInDays(today, statusChangeDate);
            return difference;
        }
    }

    calculateDaysStatute(Statute: Date): number {
        const statute = new Date(Statute);
        const today = Date.now();
        const difference = differenceInDays(statute, today);
        return difference;
    }

    getStatuteColor(days: number): string {
        if (days <= 60) { return 'red'; }
        else if (days <= 90) { return 'blue'; }
        else { return 'black'; }
    }

    getStatusWord(status: string): string {
        switch (status) {
            case 'T': return 'Treating';
            case 'GBR': return 'Gathering Bills and Records';
            case 'RD': return 'Ready for Demand';
            case 'PD': return 'Pending Demand';
            case 'DS': return 'Demand Sent';
            case 'O': return 'Offer';
            case 'PS': return 'Pending settlement';
            case 'S': return 'Settled';
            case 'L': return 'Litigation';
            case 'PL': return 'Pending Litigation';
            case 'D': return 'Dumped';
            case 'CHA': return 'Changed Attorney';
            default:
                return '';
        }
    }

    getStatusColor(days: number, status: string): string {
        if (status === 'T') {
            if (days >= 90) {
                return 'red';
            } else if (days >= 60) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'GBR') {
            if (days >= 30) {
                return 'red';
            } else if (days >= 15) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'RD') {
            if (days >= 14) {
                return 'red';
            } else if (days >= 7) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'DS') {
            if (days >= 60) {
                return 'red';
            } else if (days >= 45) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'O') {
            if (days >= 30) {
                return 'red';
            } else if (days >= 20) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'PL') {
            if (days >= 30) {
                return 'red';
            } else if (days >= 25) {
                return 'blue';
            } else {
                return 'black';
            }
        }
        if (status === 'L') {
            if (days >= 180) {
                return 'red';
            } else if (days >= 150) {
                return 'blue';
            } else {
                return 'black';
            }
        }
    }

    getAccidentString(accident: any): string {
        if (accident && accident.date) {
            let date = moment(accident.date);
            return date.format('L') + ', ' + accident.address.county + ', ' + accident.address.city + ', ' + accident.address.state;
        } else {
            return '';
        }
    }

    getAccidentAddressString(accident: any): string {
        if (accident && accident.address) {
            return accident.address.county + ', ' + accident.address.city + ', ' + accident.address.state;
        } else {
            return '';
        }
    }

}
