<div *ngIf="case != null" class="p-grid" [formGroup]="mainForm">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"
        (click)="cancel()">
    </button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" 
        (click)="store()">
    </button>

    <div class="p-grid p-fluid p-col-12">
        <!-- <div class="p-field p-col-12 p-md-1"> -->
        <!-- <div class="p-field p-s-12 p-md-4 p-lg-3"> -->
            <!-- <label for="description">Case</label>
            <input type="text" pInputText formControlName="id" readonly/>
        </div> -->
        <div class="p-field p-col-12 p-md-1">
            <!-- <label for="createdBy">Created By</label> -->
            <label for="createdBy"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Created By</label>
            <input id="createdBy" type="text" pInputText formControlName="createdBy" readonly autocomplete="doNotAutoComplete">
        </div>
        <div class="p-field p-col-12 p-md-2">
            <!-- <label for="openedDate">Case Opened On</label> -->
            <label for="openedDate">
                <label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>
                Case Opened On
            </label>
            <p-calendar [maxDate]="today" id="openedDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="openedDate" disabled="true"></p-calendar>
        </div>
        <!-- <div class="p-field p-sm-12 p-md-4 p-lg-3">
            <label for="openedDate">Date of Interview</label>
            <input type="text" pInputText readonly [value]="mainForm.get('openedDate').value  | date"/>
        </div> -->

        <div class="p-field p-col-12 p-md-2">
            <label for="interviewDate"><label style="color:red; font-size: 20px;">* </label> Date of Interview</label>
            <p-calendar id="interviewDate" formControlName="interviewDate"></p-calendar>
        </div>
        <div *ngIf="case.status == 'CLOSED'">
            <div class="p-field p-sm-12 p-md-4 p-lg-3">
                <label for="closedDate">Closed date</label>
                <p-calendar id="closedDate" formControlName="closedDate"></p-calendar>
            </div>
            <div class="p-field p-sm-12 p-lg-3">
                <label for="status">Status</label>
                <input type="text" pInputText formControlName="status" readonly/>
            </div>
        </div>
        <div class="p-field p-sm-12 p-lg-2">
            <label for="paralegal"><label style="color:red; font-size: 20px;">* </label>Paralegal</label>
            <p-dropdown id="paralegal" formControlName="paralegal" [options]="paralegals" optionLabel="name" optionValue="id" filter="true"
            autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="caseType"><label style="color:red; font-size: 20px;">* </label> Case type</label>
            <span class="p-float-label">
                <p-dropdown id="caseType" formControlName="caseType" [options]="casesType" optionLabel="name" optionValue="value"                 
                autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
            </span>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="statuteDate"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Statute Date</label>
            <!-- <label for="statuteDate" style="color:rgba(255, 0, 0, 0); font-size: 20px;"> Statute Date</label> -->
            <p-calendar id="statuteDate" dateFormat="D, MM dd, yy" formControlName="statuteDate" readonly="true" [disabled]="true"></p-calendar>
        </div>
        <div class="p-sm-12">
            <label for="openedDate">Accident</label>
            <div class="p-inputgroup">
                <input type="text" pInputText readonly="true" value="{{getAccidentString(case.accident)}}">
                <button type="button" pButton pRipple icon="pi pi-pencil" styleClass="p-button-warn"
                [disabled]="!valid" (click)="store(1); editAccident()"></button>
            </div>
        </div>
        <div class="p-sm-12">
        </div>        
    </div>

    <div class="p-sm-12">
        
    </div>        
    
    <div class="p-col-12">
        <p-tabView>
            <p-tabPanel  [header]="getPlaintiffsHeader()">
                <p-table #dtc [value]="case.plaintiffs" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 80px;">File No.</th>
                            <th style="width: 250px;">Name</th>                            
                            <th>Status</th>
                            <th>Status Changed</th>
                            <th>Comments</th>
                            <th>Last Comment On</th>
                            <th>DOB</th>
                            <th>Age</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Loss of Earnings</th>
                            <th>Health Insurance Info</th>                            
                            <th class="actions wider-column">
                                <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text" (click)="addPlaintiff()"></p-button> -->
                                <!-- <p-button type="button" label="" icon="pi pi-search" styleClass="p-button-rounded p-button-raised p-button-info p-button-text"></p-button>-->
                                <p-button type="button" label="Add New Plaintiff" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addPlaintiff()"></p-button>
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td  style="width: 80px;">{{object.referenceNo}} {{object.orderLetter}}</td>
                            <td  style="width: 250px;">{{object.person.firstName}} {{object.person.middleName}} {{object.person.lastName}}</td>                            
                            <td>
                                <ng-container *ngIf="object.plaintiffStatus">
                                  {{ getStatusWord(object.plaintiffStatus) }}
                                </ng-container>
                            </td>
                            <td>{{object.statusChangeDate | date}}</td>
                            <td>{{object.statusPlaintiffNotes ? object.statusPlaintiffNotes.length : 0}}</td>
                            <td>{{ getMaxNoteDate(object.statusPlaintiffNotes) ? (getMaxNoteDate(object.statusPlaintiffNotes) | date) : '' }}</td>
                            <td>{{object.person.dob | date}}</td>
                            <td>                                                            
                                <div *ngIf="getAge(object.person.dob)<18; 
                                    then thenBlock 
                                    else elseBlock">
                                </div>                            
                                <ng-template #thenBlock>
                                    <strong>
                                        <div style="color:red">{{getAge(object.person.dob)}}</div>
                                    </strong>
                                </ng-template>
                                <ng-template #elseBlock>
                                    <div>{{getAge(object.person.dob)}}</div>
                                </ng-template>
                            </td>
                            <td>                                
                                {{object.person.phoneNumber}}
                            </td>
                            <td>
                                <div *ngIf="object.person?.address">
                                    <div>{{object.person.address.street}},
                                    <strong *ngIf="object.person.address.apartment">Apt No: {{object.person.address.apartment}}.</strong>
                                    {{object.person.address.city}}. {{object.person?.address?.state}}. {{object.person?.address?.zipcode}}</div>
                                </div>
                            </td>
                            <td>{{ calculateTotalLOE(object) | currency: 'USD' }}</td>
                            <td>
                                <ng-container *ngIf="object.medicareNumber; else healthInsuranceSegment">
                                  Medicare Holder No.: {{object.medicareNumber}}
                                </ng-container>
                                <ng-template #healthInsuranceSegment>
                                  <ng-container *ngIf="object.healthInsurance !== undefined && object.healthInsurance !== null && object.healthInsurance !== ''; else nohealthInsurance">
                                    {{object.healthInsurance}} 
                                    <span *ngIf="object.medicaid">(Has Medicaid)</span>
                                  </ng-container>
                                  <ng-template #nohealthInsurance>
                                    No Health Insurance
                                  </ng-template>
                                </ng-template>
                            </td>                            
                            <td class="wider-column">
                                <p-button type="button" label="Edit Plaintiff Info." icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button" (click)="editPlaintiff(object)"></p-button>
                                <!--<p-button type="button" label="Print Retainer" icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button" (click)="printRetainer(object)"></p-button>-->
                                <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editPlaintiff(object)"></button> -->
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="deletePlaintiff(object)"></button> -->
                            </td>
                            <td>
                                <p-button type="button" icon="pi pi-list" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="autoNote(object)"></p-button>
                                <p-button type="button" icon="pi pi-print" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="printRetainer(object)"></p-button>
                            </td>                            
                            
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                             Total of {{case.plaintiffs ? case.plaintiffs.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No plaintiff found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel [header]="getInsuranceHeader()">
                <p-table #dtc [value]="case.insuranceClaims" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Recipient</th>
                            <th pSortableColumn="name">Insurer</th>
                            
                            <th pSortableColumn="claimNumber">Status</th>
                            <th pSortableColumn="claimNumber">Claim #</th>

                            <th>Statute of Limitations</th>
                            <th>Timeframe to Statute</th>                            
                            <th pSortableColumn="name">Policy Number</th>
                            
                            
                            <th>Comments</th>
                            <th>Last Comment On</th>
                            <!-- <th pSortableColumn="claimNumber">PIP Status</th> -->
                            <th pSortableColumn="claimNumber">Coverage</th>
                            <!--<th pSortableColumn="adjuster">Adjuster Info.</th> -->
                            <th class="actions">
                                <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"(click)="addInsurance()"></p-button> -->
                                <p-button type="button" label="Add New Insurance" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addInsurance()"></p-button>                                
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td>
                                <div style="color:red"> <strong *ngIf="object.insurancesource === 'D'">Defendant's Insurance</strong></div>
                                <div style="color:green"> <strong *ngIf="object.insurancesource === 'P'">Plaintiff's Insurance</strong></div>
                            </td>        
                            <td>{{object.insurer.name}}</td>

                            <td>
                                {{  object.claimstatus === 'NOT_REPORTED' ? 'Not Reported Yet' :
                                    object.claimstatus === 'UM_Accepted' ? 'UM Accepted' :
                                    object.claimstatus === 'No_Coverage' ? 'No Coverage' :
                                    object.claimstatus 
                                }}
                                {{ object.insuranceCoverage.pipStatus }}
                            </td>
                            <td>{{object.claimNumber}}</td>   

                            <td>{{object.filingDeadline | date}}</td>
                            <!-- <td>{{getDDiff(object.filingDeadline)}}</td>        -->
                            <td [class.text-bold-red]="isWithin30Days(object.filingDeadline)">{{getDDiff(object.filingDeadline)}}</td>                                           
                            <td>{{object.policyNumber}}</td>
                            
                            
                            <td>{{object.statusClaimNotes ? object.statusClaimNotes.length : 0}}</td>
                            <td>{{ getMaxInsuranceNoteDate(object.statusClaimNotes) ? (getMaxInsuranceNoteDate(object.statusClaimNotes) | date) : '' }}</td>
                            <!-- <td><span *ngIf="object.claimstatus === 'NOT_REPORTED'">Not Reported Yet</span>
                                <span *ngIf="object.claimstatus === 'UM_Accepted'">UM Accepted</span>
                                <span *ngIf="object.claimstatus === 'No_Coverage'">No Coverage</span>
                                <span *ngIf="object.claimstatus !== 'NOT_REPORTED'">{{object.claimstatus}}</span></td> 
                            <td>{{object.insuranceCoverage.pipStatus}}</td> -->
                            <td>
                                <ng-container *ngIf="object.insuranceCoverage.pipCoverage !== 0">
                                    PIP {{object.insuranceCoverage.pipCoverage | currency: 'USD'}} (UM {{object.insuranceCoverage.umBIEachPerson/1000}}/{{object.insuranceCoverage.umBIEachOccurrence/1000}})                                    
                                  </ng-container>
                                  <ng-container *ngIf="object.insuranceCoverage.biLEachPerson !== 0">
                                    {{object.insuranceCoverage.biLEachPerson/1000}}k/{{object.insuranceCoverage.biLEachOccurrence/1000}}k
                                  </ng-container>
                            </td>
                            <!--<td>{{object.adjusters.name}}</td>-->
                            <td>   
                                <p-button type="button" label="Edit Insurance Info." icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="editInsurance(object)"></p-button>
                            </td>
                            <td>
                                <p-button type="button" icon="pi pi-print" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="printDefendant(object)"></p-button>
                                <!-- <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editInsurance(object)"></button> -->
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="deleteDefendant(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{case.insuranceClaims ? case.insuranceClaims.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No insurance found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <!-- <p-tabPanel header="Defendant(s)">
                <p-table #dtc [value]="case.defendants" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Insured</th>
                            <th pSortableColumn="name">Policy Number</th>
                            <th pSortableColumn="claimNumber">Claim #</th>
                            <th class="actions">
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addDefendant()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td>
                                <p>{{object.person?.firstName}} {{object.person?.middleName}} {{object.person?.lastName}}  <strong D></strong></p>
                            </td>
                            <td>
                                {{object.claim.policyNumber}}
                            </td>
                            <td>
                                
                                {{object.claim.claimNumber}}
                            </td>
                            
                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="editDefendant(object)"></button>
                                
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{case.defendants ? case.defendants.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No defendant found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>  -->

            <!-- <p-tabPanel header="Plaintiff Vehicle">
                <div class="p-sm-12"> 
                <div class="p-field p-col-12 p-md-6">                    
                    <label for="openedDate"></label>
                    <div class="p-inputgroup">
                        <input type="text" pInputText readonly="true" value="{{getCarString(case.plaintiffCar)}}">
                        <button type="button" pButton pRipple icon="pi pi-pencil" styleClass="p-button-warn"
                            (click)="editPlaintiffcar()"></button>
                    </div>
                </div>
            </p-tabPanel> -->
            <p-tabPanel [header]="getVehicleHeader()">
                <p-table #dtc [value]="case.vehicles" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:10%" pSortableColumn="name">Type</th>
                            <!-- <th style="width:5%" pSortableColumn="name">Plaintiff's Car</th> -->
                            <th pSortableColumn="name">Vehicle Info</th>
                            <th pSortableColumn="name">Status</th>
                            <th>Comments</th>
                            <th pSortableColumn="name">Property Damage</th>
                            <th pSortableColumn="name">Total Diminished Value</th>
                            <th pSortableColumn="name">Rebuilt Title</th>
                            <th pSortableColumn="name">Total Loss Valuation</th>
                            <th pSortableColumn="name">Blue Book Valuation</th>
                            <th class="actions">
                                <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"(click)="addVehicle()"></p-button> -->
                                <p-button type="button" label="Add New Vehicle" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addVehicle()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">                            
                            <td style="width:10%">
                                <div style="color:red"> <strong *ngIf="object.vPlaintiff && object.vDefendant">Plaintiff's Car (at fault)</strong></div>
                                <div style="color: red" *ngIf="!object.vPlaintiff || !object.vDefendant"> <strong *ngIf="object.vDefendant">Defendant's Car</strong></div>
                                <div style="color:green" *ngIf="!object.vPlaintiff || !object.vDefendant"> <strong *ngIf="object.vPlaintiff">Plaintiff's Car</strong></div>                               
                                <!-- <strong *ngIf="object.vDefendant"> <div style="color:red"> Yes </div></strong>                         -->
                            </td>
                            <!-- <td style="width:5%">
                                <strong *ngIf="object.vPlaintiff"> <div style="color:red"> Yes </div></strong>
                            </td> -->
                            <td>
                                {{object.make.name}} {{object.model}} - {{object.vcolor}} ({{object.year}}), <strong>Tag:</strong> {{object.licensePlate}}
                            </td>     
                            <td>{{object.vehicleStatus}}</td>
                            <td>{{object.vehicleStatusNotes ? object.vehicleStatusNotes.length : 0}}</td>
                            <td>
                                {{object.pdAmount ? object.pdAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}}
                            </td>    
                            <td>
                                {{object.totaldov ? object.totaldov.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}}
                                <!-- {{object.totaldov  ? object.totaldov.toLocaleString() : 'Not Available'}} -->
                            </td> 
                            <td>
                                {{ object.rebuiltTitle ? 'Yes' : 'No' }}
                            </td>     
                            <td>
                                {{object.totalLoss ? object.totalLoss.toLocaleString() : ''}}
                            </td>     
                            <td>
                                {{object.blueBookValue ? object.blueBookValue.toLocaleString() : ''}}
                            </td>                                 
                            <td>
                                <p-button type="button" label="Edit Vehicle Info." icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="editVehicle(object)"></p-button>
                                <!-- <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="editVehicle(object)"></button> -->
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="deleteVehicle(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{case.vehicles ? case.vehicles.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No vehicles found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel [header]="getWitnessHeader()">
                <p-table #dtc [value]="case.witnesses" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Name
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Phone
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Optional Ph
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Email
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>                            
                            <th class="actions">
                                <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text" (click)="addWitness()"></p-button> -->
                                <p-button type="button" label="Add New Witness" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addWitness()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td>
                                {{object.person.firstName}} {{object.person.lastName}}
                            </td>
                            <td>
                                {{object.person.phoneNumber}}
                            </td>
                            <td>
                                {{object.person.optionalPhoneNumber}}
                            </td>
                            <td>
                                {{object.person.emailAddress}}
                            </td>
                            <td>
                                <p-button type="button" label="Edit Witness Info." icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text" (click)="editWitness(object)"></p-button>
                                
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="deleteWitness(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{case.witnesses ? case.witnesses.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No witness found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>            
            <!-- <p-tabPanel header="Pictures & Documents"> -->
            <p-tabPanel [header]="getAttachedHeader()">
                <p-table #dtc [value]="case.documents" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true"  sortField="createdOn" sortOrder="-1"   scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 2rem;"> <!-- Adjust width as needed -->
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox> <!-- This adds a checkbox to select/deselect all rows -->
                            </th>
                            <th >Type</th>
                            <th >Name</th>
                            <th>Created On
                               
                            </th>
                            <th>Preview</th>
                            <th>
                                <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addDocuments()"></p-button> -->
                                <p-button type="button" label="Add New Documents" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addDocuments()"></p-button>
                                <p-button type="button" label="Download Selected" icon="pi pi-download" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addDocuments()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td  style="width: 28px;">
                                <p-tableCheckbox [value]="object"></p-tableCheckbox> <!-- This adds a checkbox for each row -->
                            </td>
                            <td>
                                {{object.type?.name}}
                            </td>
                            <td>
                                {{object.fileInfo.name}} <!--{{object.fileInfo.uri}} -->
                            </td>
                            <td>
                                {{ object.createdOn | date}} {{ object.createdOn | date: 'h:mm a' }}
                            </td>
                            <td>
                                <a *ngIf="object.fileInfo.mimeType.startsWith('image')" href="{{getBlobUrl(object)}}" target="_blank">
                                     <img src="{{getBlobUrl(object)}}" alt="{{object.fileInfo.name}}" width="75" [preview]="true"/>
                                </a>                                
                                <a *ngIf="!object.fileInfo.mimeType.startsWith('image')" href="{{getBlobUrl(object)}}" target="_blank">
                                    {{object.fileInfo.name}}
                                </a>
                            </td>

                            <td>
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="deleteDocument(object)">
                                </button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{case.documents ? case.documents.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No documents found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
            <p-tabPanel header="Tasks & Reminders">
                <p-table #dtc [value]="Reminders" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" [scrollable]="true" sortField="noteCreatedOn" sortOrder="-1"  
                    scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Created By</th>
                            <th>Created On
                    
                            </th>
                            <th>Description</th>
                            <th>
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addNote()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="note">
                            <td>{{object.username}}</td>    
                            <td>{{object.noteCreatedOn | date:'short'}}</td>                                            
                            <!-- <td>{{(object.comments | slice:0:100)+'...'}}</td> -->
                            <td>{{(object.comments.length>100)? (object.comments | slice:0:100)+'...':(object.comments)}}</td>

                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="editNote(object)"></button>
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{statusPlaintiffNotes ? statusPlaintiffNotes.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No tasks found.</td>
                        </tr>
                    </ng-template>
            </p-table>  
            </p-tabPanel>
        </p-tabView>
    </div>

    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary" (click)="cancel()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>
</div>