<div [style]="{'height':'630px'}" [formGroup]="mainForm">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"(click)="cancel()"></button>  
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>
    
    <div class="p-col-12"></div> <!-- Forces a new line -->
    
    <div class="card" style="background-color: powderblue;">
        <div class="p-grid p-fluid">
            <div class="p-field p-col-12 p-md-4">
                <label for="company"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Outsourcing Co.</label>
                <span class="p-float-label">
                    <p-dropdown id="company" formControlName="company" [options]="companyList" optionLabel="name" optionValue="value"
                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'220px'"></p-dropdown>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="department"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Processing Department</label>
                <span class="p-float-label">
                    <p-dropdown id="department" formControlName="department" [options]="departmentList" optionLabel="deptName" optionValue="id"
                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'220px'"></p-dropdown>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="status"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Status</label>
                <span class="p-float-label">
                    <p-dropdown id="status" formControlName="status" [options]="statuses" optionLabel="name" optionValue="value"
                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'220px'"></p-dropdown>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <label for="service"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Service</label>
                <span class="p-float-label">
                    <p-dropdown id="service" formControlName="service" [options]="serviceList" optionValue="id" optionLabel="description"
                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose">
                        <ng-template let-option pTemplate="item">
                            <div>
                                {{option.description}}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </span>
            </div>
        </div>
    </div>

    <p-table #dtc [value]="requestMethodList" dataKey="id" selectionMode="single"
             styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
             reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1" [scrollable]="true" scrollHeight="600px"
             [filterDelay]="0" [rows]="4" [paginator]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Contact</th>
                <th>Priority</th>
                <th>Status</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-object>
            <tr [pSelectableRow]="note">
                <td>
                    <span>
                        <i *ngIf="object.reqmethodBy == 2" class="pi pi-inbox"></i>
                        <i *ngIf="object.reqmethodBy == 4" class="pi pi-send"></i>
                        <i *ngIf="object.reqmethodBy == 1" class="pi pi-phone"></i>
                        <i *ngIf="object.reqmethodBy == 3" class="pi pi-globe"></i>
                    </span>
                    <span> {{object.fax || object.emailAddress || getAddressString(object.address) || object.portal.name}}</span>
                </td>
                <td>{{object.priorityLevel}}</td>
                <td>{{object.status}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Total of {{results ? results.total : 0 }}.
            </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No records available.</td>
            </tr>
        </ng-template>
    </p-table>
</div>