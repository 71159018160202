import Dates from 'src/app/utilities/Dates';
import { FgEventRenderer } from '@fullcalendar/core';
import { TabView } from 'primeng/tabview';
import { Location } from '@angular/common'
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';
import { Addresses } from 'src/app/utilities/Addresses';


@Component({
    selector: 'outsourcing',
    templateUrl: './outsourcing.component.html',
    styleUrls: ['./outsourcing.component.scss']
})
export class OutsourcingComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    mainForm: FormGroup;

    outsourcing: any;

    companyList: any[] = [];
    departmentList: any[] = [];
    serviceList: any[] = [];
    requestMethodList: any[] = []

    statuses: any[] = [
        { name: 'Active', value: 1 },
        { name: 'Inactive', value: 0 },
    ];


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private providersService: ProvidersService, private healthcareUseCase: HealthCareUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id: [],
            _id: [],
            status: [],
            company: [],
            department: [],
            service: [],
        });
    }

    get valid(): boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        this.mainForm.controls.company.valueChanges.subscribe(value => this.providersService.getDepartments(value)
            .then(response => this.departmentList = response));

        this.mainForm.controls.department.valueChanges.subscribe(value => {
            this.providersService.getServices(this.mainForm.controls.company.value, value)
                .then(response => this.serviceList = response.map(s => this.mapService(s)))
                .then(() => this.loadRequestMethods(this.mainForm.controls.service.value));
        });

        this.mainForm.controls.service.valueChanges.subscribe(value => this.loadRequestMethods(value));

        this.loadOutsourcing();

        if (this.config.data) {
            this.updateModel(this.config.data);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    updateModel(outsourcing: any) {
        this.outsourcing = outsourcing;
        this.mainForm.patchValue(outsourcing);
    }

    loadOutsourcing() {
        return this.providersService.getOutsourcing().then(companies => {
            this.companyList = companies.map(company => ({
                name: company.providerName,
                value: company.id
            }));
        });
    }

    store() {
        if (this.mainForm.valid) {
            this.healthcareUseCase.appendOutsourcing(Object.assign(this.outsourcing, this.mainForm.value));
        }
        this.clean();
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.healthcareUseCase.closeOutsourcing();
        this.clean();
    }

    mapService(service) {
        let cloned = {...service};
        cloned.description = service.doctype.name + (service.comments ? ' ' + service.comments : '');
        return cloned;
    }

    getAddressString(address: any) : string {
        return Addresses.toText(address);
    }

    loadRequestMethods(serviceId) {
        let filtered = this.serviceList.filter(service => service.id == serviceId);
        if(filtered.length == 1) {
            this.requestMethodList = filtered[0].requestMethods;
        }
    }
}