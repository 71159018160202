<div [formGroup]="formGroup">       
    <!-- <div class="card"> 
        <h5>Identification Documents</h5> -->
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"(click)="cancel()"></button>  
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'800px'}" [formGroup]="formGroup">
    <!-- <div class="p-grid p-formgrid p-fluid"> -->
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="accidentDate"><label style="color:red">* </label> Date</label>
                <p-calendar id="accidentDate" formControlName="date"></p-calendar>
            </div>
            
            <div class="p-field p-col-12 p-md-2">
                <label for="accidentTime"><label style="color:red">* </label> Time</label>
                <p-inputMask id="accidentTime" mask="99:99 **"  formControlName="accidentTime" autocomplete="doNotAutoComplete"></p-inputMask>
                <!-- unmask="true" -->
            </div>

            <div class="p-field p-col-12 p-md-2">
                <label for="cFrom"><label style="color:red">* </label> Coming From</label>
                <input id="cFrom" type="text" pInputText formControlName="cFrom">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="goingTo"><label style="color:red">* </label> Going To</label>
                <input id="goingTo" type="text" pInputText formControlName="goingTo">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="wconditions">Weather</label>
                <p-dropdown inputId="wconditions" formControlName="weatherConditions" [options]="weatherConditions" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>            
        </div>
        <address formGroupName="address"></address>            
    </div>
   
        <p-tabView [style]="{'height':'250px'}">
            <p-tabPanel header="Details">
                <div class="p-grid p-formgrid p-fluid">
                    <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                        <label for="descriptionAccident"><label style="color:red">* </label>  Narrative</label>
                        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                            formControlName="descriptionAccident" maxlength="1000"></textarea>          
                            <label *ngIf="formGroup.get('descriptionAccident')?.value">{{ 1000 - formGroup.get('descriptionAccident').value.length}}/1000</label>                                
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                        <label for="speedLimit">Speed Limit</label>
                        <p-inputNumber id="speedLimit" formControlName="speedLimit" mode="decimal"></p-inputNumber>
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                        <label for="speedTravel">Speed Travel</label>
                        <p-inputNumber id="speedTravel" formControlName="speedTravel" mode="decimal"></p-inputNumber>
                    </div>                
                    <div class="p-field p-col-12 p-md-2">
                        <label for="noLanes"><label style="color:red">* </label> Lanes Available</label>
                        <p-inputNumber id="noLanes" formControlName="noLanes"></p-inputNumber>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="laneLocation"><label style="color:red">* </label> Travel Lane (Right to Left)</label>
                        <input id="laneLocation" type="text" pInputText formControlName="laneLocation">
                        <!-- <p-inputNumber id="laneLocation" formControlName="laneLocation"></p-inputNumber> -->
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="gCoordinates">Coordinates</label>
                        <input id="gCoordinates" type="text" pInputText formControlName="gCoordinates" [readonly]="formGroup.get('gCoordinates').value !== null">
                        <!-- <button (click)="openGoogleMaps()">Click to see Accident location</button> -->                        
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-2">                        
                        <p-button type="button" icon="pi pi-map-marker" styleClass="p-button-rounded p-button-raised p-button-danger p-button-text large-button" (click)="openGoogleMaps()" pTooltip="See location bird view" tooltipPosition="top"></p-button>
                        <p-button type="button" icon="pi pi-map" styleClass="p-button-rounded p-button-raised p-button-danger p-button-text large-button" (click)="openStreetView()" pTooltip="See location street view" tooltipPosition="top"></p-button>
                    </div> -->
                    <div class="p-field p-col-12 p-md-2 align-center">                                                
                        <p-button type="button" icon="pi pi-map-marker" styleClass="p-button-rounded p-button-raised p-button-danger p-button-text large-button" (click)="openGoogleMaps()" pTooltip="Accident Location (Bird View)" tooltipPosition="top"></p-button>
                        <p-button type="button" icon="pi pi-map" styleClass="p-button-rounded p-button-raised p-button-danger p-button-text large-button" (click)="openStreetView()" pTooltip="Accident location (Street View)" tooltipPosition="top"></p-button>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="policeArrivedYN">Police arrived to the scene?</label>
                        <p-dropdown formControlName="policeArrived" [options]="policeArrivedYN" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div>   
                    <div class="p-field p-col-12 p-md-2"></div>   
                    <div class="p-field p-col-12 p-md-12">
                        <p-accordion [activeIndex]="0">
                            <p-accordionTab header="Police Report Information" [disabled]="formGroup.get('policeArrived').value === false">
                                <div class="p-fluid p-formgrid p-grid"  formGroupName="policeReport">
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="exchInfo">Exchange of Information</label>
                                        <p-dropdown formControlName="exchInfo" [options]="exchInfoOpt" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                                    </div> 
                                    <!-- <div class="p-field p-col-12 p-md-2">                                                    
                                        <h6>Exchange of Information on File</h6>
                                            <p-inputSwitch formControlName="exchInfoOnFile"></p-inputSwitch>
                                    </div>  -->
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="reportNo">Report No.</label>
                                        <input id="reportNo" type="text" pInputText formControlName="reportNo">
                                    </div> 
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="dateRequested">Requested to Police on</label>
                                        <p-calendar id="dateRequested" formControlName="dateRequested" [disabled]="true"></p-calendar>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="dateReceived">Date Received</label>
                                        <p-calendar id="dateReceived" formControlName="dateReceived" [disabled]="true"></p-calendar>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="dateRejected">Request Rejected by Police on</label>
                                        <p-calendar id="dateRejected" formControlName="dateRejected" [disabled]="true"></p-calendar>
                                    </div>
                                    <div class="p-field p-col-12 p-md-2">
                                        <label for="status">Police Report Status</label>
                                        <input id="status" type="text" pInputText formControlName="status" readonly autocomplete="doNotAutoComplete">
                                    </div>                                                          
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-field p-col-12 p-md-6"> 
                                            <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Police Report will not be completed" icon="pi pi-times" class="p-button-text p-button-rounded"
                                                (click)="policeReportWillNotBeCompleted()">
                                            </button>                            
                                        </div>
                                        <div class="p-field p-col-12 p-md-6">
                                            <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Police Report Received" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
                                                (click)="policeReportReceived()">
                                            </button>    
                                        </div>
                                    </div>
                                    <!-- <div class="p-field p-col-12 p-md-2">   
                                        <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Will not be completed" icon="pi pi-times" class="p-button-text p-button-secondary"
                                            (click)="policeReportWillNotBeCompleted()"></button>
                                        <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Received" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
                                            (click)="policeReportReceived()"></button>    
                                    </div> -->
                                </div> 
                            </p-accordionTab>                        
                        </p-accordion>                         
                    </div>                                 
                </div>                
            </p-tabPanel>        
            <p-tabPanel header="Police Report Notes" [disabled]="formGroup.get('policeArrived').value === false">
                <!-- <div class="p-grid p-formgrid p-fluid">
                    <div class="p-fluid p-formgrid p-grid">                        
                        <div class="p-fluid p-formgrid p-grid"  formGroupName="policeReport">
                            <div class="p-field p-col-12 p-md-2">
                                <label for="exchInfo">Exchange of Information</label>
                                <p-dropdown formControlName="exchInfo" [options]="exchInfoOpt" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                            </div>  -->
                            <!-- <div class="p-field p-col-12 p-md-2">                                                    
                                <h6>Exchange of Information on File</h6>
                                    <p-inputSwitch formControlName="exchInfoOnFile"></p-inputSwitch>
                            </div>  -->
                            <!-- <div class="p-field p-col-12 p-md-2">
                                <label for="reportNo">Report No.</label>
                                <input id="reportNo" type="text" pInputText formControlName="reportNo">
                            </div> 
                            <div class="p-field p-col-12 p-md-2">
                                <label for="dateRequested">Requested to Police on</label>
                                <p-calendar id="dateRequested" formControlName="dateRequested" [disabled]="true"></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label for="dateReceived">Date Received</label>
                                <p-calendar id="dateReceived" formControlName="dateReceived" [disabled]="true"></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label for="dateRejected">Request Rejected by Police on</label>
                                <p-calendar id="dateRejected" formControlName="dateRejected" [disabled]="true"></p-calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label for="status">Status</label>
                                <input id="status" type="text" pInputText formControlName="status" readonly autocomplete="doNotAutoComplete">
                            </div>                                                          
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6"> 
                                    <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Police Report will not be completed" icon="pi pi-times" class="p-button-text p-button-rounded"
                                        (click)="policeReportWillNotBeCompleted()">
                                    </button>                            
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Police Report Received" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
                                        (click)="policeReportReceived()">
                                    </button>    
                                </div>
                            </div> -->
                            <!-- <div class="p-field p-col-12 p-md-2">   
                                <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Will not be completed" icon="pi pi-times" class="p-button-text p-button-secondary"
                                    (click)="policeReportWillNotBeCompleted()"></button>
                                <button *ngIf="formGroup.value.policeReport.status == 'PENDING'" pButton pRipple label="Received" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
                                    (click)="policeReportReceived()"></button>    
                            </div> -->
                        <!-- </div>                    
                    </div>                     -->
                    <!-- <label for="comments">Aditional Comments</label>
                    <textarea inputId="textarea" rows="3" style="resize: none;" cols="30" formControlName="comments" pInputTextarea></textarea>                       -->
                <!-- </div> -->
                <div class="p-grid p-formgrid p-fluid">
                    <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                        <!-- <div class="p-field p-col-12 p-md-12" formGroupName="policeReport">                                                    
                            <label for="comments">Aditional Comments</label>
                            <textarea inputId="textarea" rows="3" style="resize: none;" cols="30" formControlName="comments" pInputTextarea></textarea>
                        </div>  -->
                        <p-table #dtc [value]="" dataKey="id" selectionMode="single"
                        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                        reorderableColumns="true" sortField="statusLastModified" sortOrder="-1"
                        [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                
                                    <th>Created by</th>                                
                                    <th>Created on</th>                                                                
                                    <th>Notes</th>
                                    <th class="actions">
                                        <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                            (click)="addLiability()"></p-button>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-object>
                                <tr [pSelectableRow]="liability">
                                
                                    <td>{{object.statusLastModified | date:'short'}}</td>
                                    <td>{{object.datebyInsurance}}</td>                                                                
                                    <td>{{object.comments}}</td>                                
                                    <td>
                                        <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-success p-mr-2"
                                        (click)="editLiability(object)"></button>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="summary">
                                <div class="p-d-flex p-ai-center p-jc-between">
                                    <!-- Total of {{case.defendants ? case.defendants.length : 0 }}. -->
                                </div>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">No notes found.</td>
                                </tr>
                            </ng-template>
                    </p-table>
                    </div>                                    
                </div>
            </p-tabPanel>
            <p-tabPanel header="Investigator Information" [disabled]="formGroup.get('policeArrived').value === false">    
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <label for="investigator">Investigator</label>
                        <input id="investigator" type="text" pInputText formControlName="investigator">
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <label for="investigatorPh">Investigator Phone</label>
                        <p-inputMask id="investigatorPh" mask="(999) 999-9999" formControlName="investigatorPh" autocomplete="doNotAutoComplete"></p-inputMask>
                    </div>                

                    <div class="p-field p-col-12 p-md-4">
                        <label for="investigatorCounty">Investigator County</label>
                        <input id="investigatorCounty" type="text" pInputText formControlName="investigatorCounty">
                    </div>
                </div>            
            </p-tabPanel>
        </p-tabView>
    </div>
</div>