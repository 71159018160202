import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService } from "angularx-social-login";
import { Subject, Subscription, timer } from "rxjs";
import { filter } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import * as moment from "moment";
 
@Injectable({
    providedIn: 'root'
})
export class UserSessionService {
    user: User;
    token: String;
    jwt: any;
    refreshTimer: Subscription;

    public onReady = new Subject();

    constructor(private router: Router, private socialAuthService: SocialAuthService) {
        var raw = localStorage.getItem("id-token");
        if(raw) {
            let jwt = jwt_decode(raw);
            if(this.validate(jwt)) {
                this.setToken(raw);
            }
        }

        this.socialAuthService.authState.subscribe(user => {
            localStorage.setItem("id-token", user.idToken);
            this.setToken(user.idToken);
            console.log("on authentication", user);
        });
        this.socialAuthService.initState.subscribe(() => this.onReady.next());
        
    }

    getUser() : User {
        return this.user;
    }

    getToken() : String {
        return this.token;
    }

    isAuthenticated() : boolean {
        return this.user != null;
    }

    login() {
        return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    logout() {
        return this.socialAuthService.signOut();
    }


    validate(jwt) : boolean {
        let exp = moment.unix(jwt.exp);
        let now = moment();
        return exp.isAfter(now);
    }

    decodeUser(jwt) : User {
        return {username: jwt.email, name: jwt.name, email: jwt.email, picture: jwt.picture};
    }

    setToken(raw) {
        this.token = raw;
        this.jwt = jwt_decode(raw);
        this.user = this.decodeUser(this.jwt);
        let timeout = moment.unix(this.jwt.exp).diff(moment(), 'seconds');
        console.log("token will expire in " + timeout + "seconds");
        this.refreshTimer = timer(timeout * 1000 * .95).subscribe(() => {
            this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(() => console.log("token refreshed"));
        });
    }
}

export interface User {
    username: string;
    name: string;
    email: string;
    picture: string;
}