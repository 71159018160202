<div class="p-grid">
    <div class="p-col-12">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

        <div class="card" style="background-color: powderblue;">
            <h5>{{catalogType}}</h5>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="address">Name</label>
                    <input id="address" type="text" pInputText [(ngModel)]="object.name" autocomplete="doNotAutoComplete">
                </div>

            </div>
        </div>
    </div>
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>
</div>