import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ProvidersService } from '../providers.service';
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';
import Dates from 'src/app/utilities/Dates';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'providerlist',
    templateUrl: './providerlist.component.html',
    styleUrls: ['./providerlist.component.scss']
})
export class ProviderListComponent implements OnInit {

    loading = false;
    catalogType: string;
    results: any;
    nodes: TreeNode[];
    searchForm: FormGroup;
    activeIndex = 0;
    maxResults = 10;
    search = {
        from: 0,
        to: this.maxResults,
        term: null
    }

    constructor(private fb: FormBuilder, private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute,
        private router: Router, private providersService: ProvidersService) {
        this.breadcrumbService.setItems([
            { label: 'Providers' },
        ]);
        this.searchForm = fb.group({
            term: []
        });
    }

    ngOnInit() {
        this.route.params.subscribe(event => {
            this.catalogType = event.catalogType;
            this.load();
        });
    }

    get total(): number {
        return this.results && this.results.total || 0;
    }

    get objects(): any[] {
        return this.results && this.results.data || [];
    }

    newSearch() {
        this.search.from = 0;
        this.search.to = this.maxResults;
        this.load();
    }

    load() {
        this.search.term = this.searchForm.value.term || "";

        this.loading = true;
        if (this.activeIndex == 0) {
            this.providersService.findAll(this.search)
                .then(results => this.results = results)
                .then(() => this.nodes = this.objects.map(provider => this.transformProviderToNode(provider)))
                .then(() => this.loading = false);
        }
    }

    changeTab(event) {
        this.search.from = 0;
        this.search.to = this.maxResults;
        this.activeIndex = event;
        this.load();
    }


    loadPage(event) {
        this.search.from = event.first;
        this.search.to = event.first + event.rows;
        this.load();
    }

    addNew() {
        this.router.navigate([this.router.url, 'new']);
    }

    edit(object) {
        this.router.navigate([this.router.url, object.id]);
    }

    done() { }

    cancel() { }

    getFacilityTypeWord(type: string): string {
        switch (type) {
            case 'H': return 'Hospital';
            case 'PG': return 'Physician Group';
            case 'I': return 'Imaging';
            case 'CA': return 'Collection Agency';
            case 'CH': return 'Chiropractic';
            case 'PT': return 'Physical Therapy';
            case 'OR': return 'Orthopedic';
            case 'UC': return 'Urgent Care';
            case 'CG': return 'Hospital Network';
            case 'FP': return 'Family Practice';
            case 'CS': return 'Copy Services';
            case 'EM': return 'EMS';
            default:
                return '';
        }
    }

    onStatusChange() {
        this.load();
    }

    transformProviderToNode(provider): TreeNode {
        return {
            data: { id: provider.id, name: provider.providerName, type: provider.type, address: provider.address, creationDate: provider.creationDate, nodeType: 'Provider' },
            children: (provider.departments || []).map(department => this.transformDepartmentToNode(department))
        };
    }

    transformDepartmentToNode(department): TreeNode {
        return {
            data: { name: department.name, outsourcingName: department.outsourcingName, nodeType: 'Department' },
            children: (department.services || []).map(service => this.transformServiceToNode(service))
        };
    }

    transformServiceToNode(service): TreeNode {
        return {
            data: { name: service.name, address: service.address, contacts: service.contacts, nodeType: 'Service' },
        };
    }
}