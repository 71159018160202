import { Routes } from '@angular/router';

import { ProviderListComponent } from './list/providerlist.component';
import { ProviderFormComponent } from './form/providerform.component';

export const ProvidersRoutes: Routes = [
    {
        path: '',
        component: ProviderListComponent,
    },
    {
        path: 'new',
        component: ProviderFormComponent
    },
    {
        path: ':providerId',
        component: ProviderFormComponent
    },
];