import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '../primeng/primeng.module';
import { CatalogsRoutes } from './catalogs.routing';
import { CatalogListComponent } from './list/cataloglist.component';
import { CatalogFormComponent } from './form/catalogform.component';
import { CatalogsService } from './catalogs.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNGModule,
        RouterModule.forChild(CatalogsRoutes)
    ],
    declarations: [
        CatalogListComponent,
        CatalogFormComponent
    ],
    providers: [
        CatalogsService
    ]
})
export class CatalogsModule {

}