<div class="p-grid table-demo">
	<div class="p-col-12">
		<div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="New Client" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="addNew()"></button>                    
                </ng-template>
                
                <ng-template pTemplate="right">
                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                        <label class="global-filter-label">Search</label>

                    </span>
                </ng-template>
            </p-toolbar>

			<p>Customers contact information</p>

			<p-table #dtc [value]="persons" [globalFilterFields]="['person.name','person.dob','person.mname','person.lname','mobilePh','person.address.street_address']"
                    [(selection)]="selectedCustomer" dataKey="id" selectionMode="single"
					 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
					 [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true"
                     >
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						List of Clients
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="name">Name
							<p-sortIcon field="name"></p-sortIcon>
						</th>
						<th pSortableColumn="name">Date of Birth
							<p-sortIcon field="name"></p-sortIcon>
						</th>

                        <th pSortableColumn="phone">Phone
							<p-sortIcon field="phone"></p-sortIcon>
						</th>
                        <th pSortableColumn="street">Street Address
							<p-sortIcon field="street"></p-sortIcon>
						</th>
                        <th pSortableColumn="apartment">Apartment No.
							<p-sortIcon field="apartment"></p-sortIcon>
						</th>
                        <th pSortableColumn="city">City<p-sortIcon field="city"></p-sortIcon>
						</th>
                        <th pSortableColumn="zipcode">Zipcode
							<p-sortIcon field="zipcode"></p-sortIcon>
						</th>

						<!-- <th pSortableColumn="country.name">Country
							<p-sortIcon field="country.name"></p-sortIcon>
						</th> -->
						<!-- <th pSortableColumn="representative.name">Representative
							<p-sortIcon field="representative.name"></p-sortIcon>
						</th>
						<th pSortableColumn="date">Date of the Accident
							<p-sortIcon field="date"></p-sortIcon>
						</th> -->
						<!-- <th pSortableColumn="status">Status
							<p-sortIcon field="status"></p-sortIcon>
						</th>
						<th pSortableColumn="activity">Activity
							<p-sortIcon field="activity"></p-sortIcon>
						</th> -->
                        <th></th> 						
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-client>
					<tr [pSelectableRow]="client">
						<td>
							<span class="p-column-title">Name</span>
							{{client.person.name}} {{client.person.mname}} {{client.person.lname}}
						</td>
						<td>
							<span class="p-column-title">Date of Birth</span>
							{{client.dob | date:'mediumDate'}}
						</td>
                        <td>
							<span class="p-column-title">Phone</span>
							{{client.mobilePh}}
						</td>
                        <td>
							<span class="p-column-title">Street Address</span>
							{{client.person.address.streetAddress}}
						</td>
                        <td>
							<span class="p-column-title">Apartment No.</span>
							{{client.person.apartment}}
						</td>
                        <td>
							<span class="p-column-title">City</span>
							{{client.person.address.city}}
						</td>
                        <td>
							<span class="p-column-title">Zipcode</span>
							{{client.person.address.zipcode}}
						</td>

						<!-- <td>
							<span class="p-column-title">Country</span>
							<img src="assets/demo/flags/flag_placeholder.png"
								 [class]="'flag flag-' + customer.country.code" width="30">
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.country.name}}</span>
						</td> -->
						<!-- <td>
							<span class="p-column-title">Representative</span>
							<img [alt]="customer.representative.name"
								 src="assets/demo/images/avatar/{{customer.representative.image}}" width="32"
								 style="vertical-align: middle"/>
							<span class="image-text" style="margin-left: .5em;vertical-align: middle">{{customer.representative.name}}</span>
						</td>
						<td>
							<span class="p-column-title">Date</span>
							{{customer.date}}
						</td> -->
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="edit(client)"></button>
                        </td>
						<!-- <td>
							<span class="p-column-title">Status</span>
							<span [class]="'customer-badge status-' + customer.status">{{customer.status}}</span>
						</td>
						<td>
							<span class="p-column-title">Activity</span>
							<p-progressBar [value]="customer.activity" [showValue]="false"></p-progressBar>
						</td> -->
					</tr>
				</ng-template>
                
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total of {{persons ? persons.length : 0 }} clients.
                    </div>
                </ng-template>

				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">No customers found.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

</div>
