<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

<div [style]="{'height':'630px'}" [formGroup]="mainForm">
    <div class="card p-grid p-formgrid p-fluid" style="background-color: powderblue;">         
        <div class="p-fluid p-grid">
            <!-- <div class="p-field p-col-12 p-md-1">
                <label title="Priority" for="priorityLevel">Priority</label>
                <p-inputNumber id="priorityLevel" formControlName="priorityLevel" min="0" useGrouping="false" title="Provider Priority Level"></p-inputNumber>
            </div>  -->
            <div class="p-field p-col-12 p-md-1">
                <label title="Priority" for="priorityLevel">Priority</label>
                <span class="p-float-label">
                    <p-dropdown id="priorityLevel" formControlName="priorityLevel" [options]="priorities" optionValue="value" optionLabel="name"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                </span>
            </div> 
            <div class="p-field p-col-12 p-md-2">
                <label for="status">Status</label>
                <p-dropdown formControlName="status" [options]="statuses" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="daysToRespond" title="Enter the number of business days for turnaround">Turnaround time (biz days)</label>
                <p-inputNumber id="daysToRespond" formControlName="daysToRespond" min="0" useGrouping="false" title="Enter the number of business days for turnaround"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-1-3">
                <label for="reqmethodBy">Send Request By</label>
                <p-dropdown id="reqmethodBy" formControlName="reqmethodBy" [options]="requestBy" optionLabel="name" optionValue="value" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>
            <p></p>
            <div class="p-field p-col-12 p-md-1-3">
                <label for="fax">Fax Number</label>
                <p-inputMask id="fax" mask="(999) 999-9999"  formControlName="fax" autocomplete="doNotAutoComplete"></p-inputMask>
                <!-- unmask="true" -->
            </div>     
            <div class="p-field p-col-12 p-md-2">
                <label for="emailAddress">Email</label>
                <input id="emailAddress" type="text" pInputText formControlName="emailAddress" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-1-3">
                <label for="portal">Request Portal</label>
                <p-dropdown id="portal" formControlName="portal" [options]="portals" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose" [tooltip]="portalName" tooltipPosition="top" (onChange)="onPortalChange($event)"></p-dropdown>                
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="resmethodBy">Delivery Method</label>
                <p-dropdown id="resmethodBy" formControlName="resmethodBy" [options]="resultBy" optionLabel="name" optionValue="value" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>           
            <div class="p-field p-col-12 p-md-2">
                <label for="portal"  title="Results Retrieval Portal">Response Portal</label>
                <p-dropdown id="portal" formControlName="responsePortal" [options]="portals" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose" title="Results Retrieval Portal"></p-dropdown>           
            </div> 
            <div class="p-col-12"></div> <!-- Forces a new line -->            

            <address *ngIf="addressEnabled" formGroupName="address"></address> 
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                <label for="comments">Comments</label>                
                <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="false" id="the-textarea"
                    formControlName="comments" appCharacterCounter [maxLength]="1000">
                </textarea>
                <label *ngIf="mainForm.get('comments')?.value">{{ 1000 - mainForm.get('comments').value.length}}/1000</label>                                
            </div>  
            <div class="p-field p-col-12" style="display: flex; justify-content: flex-end;">        
                <div class="p-field p-col-12 p-md-2">        
                    <label for="createdOn">Creation Date</label>
                    <p-calendar id="createdOn" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="createdOn" disabled="true"></p-calendar>
                </div>            
                <div class="p-field p-col-12 p-md-2">        
                    <label for="createdBy">Created By</label>
                    <input id="createdBy" type="text" pInputText formControlName="createdBy" autocomplete="doNotAutoComplete">                    
                </div>  
            </div>                              
        </div>        
    </div>       
</div>