<div [style]="{'height':'730px'}" [formGroup]="mainForm">
    <!-- <label><b><label style="color:red">&nbsp; &nbsp;&nbsp;Pending Liability will be recorded automatically when saving the Defendant's info</label> </b></label> -->
    
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>
    <div class="p-col-12">
        <p-tabView [style]="{'height':'150px'}">
            <p-tabPanel header="1. Claim">                      
                <div class="card" style="background-color: powderblue;">
                    <div formGroupName="claim" class="p-fluid p-formgrid p-grid">
                         <div class="p-field p-col-12 p-md-2">
                            <label for="insurance">Insurance</label>
                            <p-dropdown id="insurance" formControlName="insurance" [options]="insurances" optionLabel="name" optionValue="id" filter="true"
                                autoDisplayFirst="false" placeholder="Choose">
                            </p-dropdown>                            
                        </div>                                      
                        <div class="p-field p-col-12 p-md-2">
                            <label for="policyNumber">Policy Number</label>
                            <p-inputMask id="policyNumber"  mask="*?**-***-***-***-***-***-***-***" formControlName="policyNumber"></p-inputMask>
                        </div>                          
                        <div class="p-field p-col-12 p-md-2">
                            <label for="claimNumber">Claim No</label>
                            <p-inputMask id="claimNumber"  mask="*?**-***-***-***-***-***-***-***" formControlName="claimNumber"></p-inputMask>
                        </div>                          
                        <div class="p-field p-col-12 p-md-2">
                            <label for="biEachPerson">BI each person</label>
                            <p-inputNumber mode="currency" currency="USD" id="biEachPerson" type="text" formControlName="biEachPerson"></p-inputNumber>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <label for="biEachOccurrence">BI each occurrence</label>
                            <p-inputNumber mode="currency" currency="USD" id="biEachOccurrence" type="text" formControlName="biEachOccurrence"></p-inputNumber>
                        </div>
                    </div>
                </div>
                
                <div formGroupName="claim" class="card">
                    <h5>BI Adjuster</h5>                        
                    <div formGroupName="adjuster" class="p-fluid p-formgrid p-grid">                                            
                        <div class="p-field p-col-12 p-md-3">
                            <label for="name">Name</label>
                            <input id="name" type="text" pInputText formControlName="name" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="phone">Phone Ext</label>
                            <p-inputMask id="phone" mask="(999) 999-9999? x9999999999" formControlName="phone" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>                                                                                   
                        <div class="p-field p-col-12 p-md-2">
                            <label for="fax">Fax</label>
                            <p-inputMask id="fax" mask="(999) 999-9999" formControlName="fax" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="email">Email</label>
                            <input id="email" type="text" pInputText formControlName="email" autocomplete="doNotAutoComplete">
                        </div>
                        <br>
                        <div class="p-field p-col-12 p-md-20">
                            <address formGroupName="address"></address>    
                        </div>                         
                    </div>                      
                </div>

                <div formGroupName="claim" class="card" style="background-color: powderblue;">
                    <h5>PD Adjuster</h5>                        
                    <div formGroupName="pdAdjuster" class="p-fluid p-formgrid p-grid">                                            
                        <div class="p-field p-col-12 p-md-3">
                            <label for="name">Name</label>
                            <input id="name" type="text" pInputText formControlName="name" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="phone">Phone Ext</label>
                            <p-inputMask id="phone" mask="(999) 999-9999? x99999" formControlName="phone" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>
                                                           
                        <div class="p-field p-col-12 p-md-3">
                            <label for="email">Email</label>
                            <input id="email" type="text" pInputText formControlName="email" autocomplete="doNotAutoComplete">
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <label for="fax">Fax</label>
                            <p-inputMask id="fax" mask="(999) 999-9999" formControlName="fax" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>
                        <br>
                          <div class="p-field p-col-12 p-md-20">
                            <address formGroupName="address"></address>   
                        </div>                           
                    </div>                      
                </div>
            </p-tabPanel>
            <p-tabPanel header="2. Driver">
                <div formGroupName="ddriver" class="p-fluid p-formgrid p-grid">
                        <person formGroupName="person"></person>                         
                </div>
                <br>
                <div class="card">
                    <h5>Additional Information</h5>
                    <div formGroupName="ddriver" class="p-fluid p-formgrid p-grid">                                                 
                        <div class="p-field p-col-12 p-md-2">                                                    
                            <h6>Driver is also the Insured</h6>
                           <p-inputSwitch formControlName="driverAlsoInsured" ></p-inputSwitch>
                       </div>
                        <div class="p-field p-col-12 p-md-2">                                                    
                            <h6>Driver doesn't have a License</h6>
                           <p-inputSwitch formControlName="noDriverLicense" ></p-inputSwitch>
                       </div>
                        <div class="p-field p-col-12 p-md-2">                                                    
                             <h6>Is not an authorized Driver</h6>
                            <p-inputSwitch formControlName="authorizedDriver" ></p-inputSwitch>
                        </div>
                        <div class="p-field p-col-12 p-md-2">                                                    
                            <h6>Was given a ticket</h6>
                           <p-inputSwitch formControlName="wasGivenTicket" ></p-inputSwitch>
                       </div>
                        <div class="p-field p-col-12 p-md-2">                                                    
                            <h6>DUI Charges</h6>
                           <p-inputSwitch formControlName="duiCharges" ></p-inputSwitch>
                       </div>
                        <div class="p-field p-col-12 p-md-2">                                                    
                            <h6>Taken into Custody</h6>
                           <p-inputSwitch formControlName="takenByPolice" ></p-inputSwitch>
                       </div>
                       <div><br></div>
                       <div formGroupName="person" class="p-field p-col-12 p-md-2">
                            <label for="driverLicenseNo">Driver License Number</label>
                            <p-inputMask id="driverLicenseNo"  mask="*?**-***-***-***-***-***" formControlName="driverLicenseNo"></p-inputMask>                
                        </div>
                        <div formGroupName="person" class="p-field p-col-12 p-md-1">
                            <label for="licenseState">State</label>
                            <input id="licenseState" type="text" pInputText formControlName="licenseState" 
                            pTooltip="Specify the State where DL was issued" 
                            tooltipPosition="top"
                            autocomplete="doNotAutoComplete">
                        </div>                                   
                        <div formGroupName="person" class="p-field p-col-12 p-md-2">
                            <label for="personalIdType">Identification</label>
                            <p-dropdown id="personalIdType" formControlName="personalIdType" [options]="personalIdTypes" optionValue="value" optionLabel="name"                  
                            autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
                        </div>                                                
                        <div formGroupName="person" class="p-field p-col-12 p-md-3">
                            <label for="personalIdNo">Personal Id Number</label>
                            <p-inputMask id="personalIdNo"  mask="*?**-***-***-***-***-***" formControlName="personalIdNo"></p-inputMask>                
                        </div>        
                    </div>                                      
                </div>                
            </p-tabPanel>              
            <p-tabPanel header="3. Insured">                
                <person formGroupName="person"></person>
                <br><br>
                <div class="card">
                    <h5>Identification Documents</h5>
                    <div formGroupName="person" class="p-grid p-fluid">    
                        <div class="p-field p-col-12 p-md-2">
                            <label for="driverLicenseNo">Driver License Number</label>
                            <p-inputMask id="driverLicenseNo"  mask="*?**-***-***-***-***-***" formControlName="driverLicenseNo"></p-inputMask>                
                        </div>
                        <div class="p-field p-col-12 p-md-1">
                            <label for="licenseState">State</label>
                            <input id="licenseState" type="text" pInputText formControlName="licenseState" 
                            pTooltip="Specify the State where DL was issued" 
                            tooltipPosition="top"
                            autocomplete="doNotAutoComplete">
                        </div> 
                        <div class="p-field p-col-12 p-md-2">    
                            <label for="personalIdType">Identification</label>
                            <span class="p-float-label">
                                <p-dropdown id="personalIdType" formControlName="personalIdType" [options]="personalIdTypes" optionValue="value" optionLabel="name"                  
                                autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                            </span>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="personalIdNo">Personal Id Number</label>
                            <p-inputMask id="personalIdNo"  mask="*?**-***-***-***-***-***" formControlName="personalIdNo"></p-inputMask>                
                        </div>
                    </div> 
                </div>
                <div class="card" style="background-color: powderblue;"> 
                    <h5>Contact Information</h5>
                    <div formGroupName="person" class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-2">
                            <label for="phoneNumber">Mobile phone</label>
                            <p-inputMask id="phoneNumber" mask="(999) 999-9999" formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>
                                    
                        <div class="p-field p-col-12 p-md-2">
                            <label for="emailAddress">Email</label>
                            <input id="emailAddress" type="text" pInputText formControlName="emailAddress" autocomplete="doNotAutoComplete">
                        </div>                                            
                        <div class="p-field p-col-12 p-md-2">
                            <label for="optionalPhoneNumber">Optional phone</label>
                            <p-inputMask id="optionalPhoneNumber" mask="(999) 999-9999" formControlName="optionalPhoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                        </div>
                    </div>
                </div>                
            </p-tabPanel>  

            <p-tabPanel header="4. Liability Status">  
                <label><b><label style="color:red">&nbsp; &nbsp;&nbsp;Pending Liability will be recorded automatically when saving the Defendant's info</label> </b></label>
                <br><br>
                <div class="p-grid p-formgrid p-fluid">
                    <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                     <div class="p-field p-col-12 p-md-2">
                                <label for="status">Status</label>
                                <input id="status" type="text" pInputText formControlName="status" readonly autocomplete="doNotAutoComplete">
                            </div>   
                    </div>
                </div>
                    <p-table #dtc [value]="liabilities" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" sortField="statusLastModified" sortOrder="-1"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                        <ng-template pTemplate="header">
                            <tr>
                               
                                <th>Value Date</th>                                
                                <th>Insurance Notification</th>                                
                                <th>Liability Status</th>
                                <th>Notes</th>
                                <th class="actions">
                                    <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                        (click)="addLiability()"></p-button>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="liability">
                               
                                <td>
                                    {{object.statusLastModified | date:'short'}}
                                </td>
                                <td>
                                    {{object.datebyInsurance}}       
                                </td>                                
                                <td>
                                    {{object.liabilityStatus}}
                                </td>
                                <td>
                                    {{object.comments}}
                                </td>                                
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2"
                                    (click)="editLiability(object)"></button>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                <!-- Total of {{case.defendants ? case.defendants.length : 0 }}. -->
                            </div>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No liability history found.</td>
                            </tr>
                        </ng-template>
                    </p-table>                                
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
