<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'800px'}" [formGroup]="mainForm">    
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">                    
            <div class="p-field p-col-12 p-md-2">
                <label for="username">Created By</label>
                <input id="username" type="text" pInputText formControlName="username" readonly autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="noteCreatedOn">Created On</label>
                <p-calendar id="noteCreatedOn" showTime="true" showSeconds="false" formControlName="noteCreatedOn" disabled="true"></p-calendar>
            </div>        
            <div class="p-field p-col-12 p-md-3">
                <label for="notifiedBy"><label style="color:red">*</label>Event By</label>
                <p-dropdown formControlName="notifiedBy" [options]="notificationType" optionLabel="name" optionValue="value" [autoDisplayFirst]="false" [readonly]="note.id != null"></p-dropdown>
            </div>  
            <div class="p-field p-col-12 p-md-2">
                <label for="eventDate"><label style="color:red">*</label>Event Date</label>
                <p-calendar id="eventDate" formControlName="eventDate" [disabled]="note.id != null"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="noteType"><label style="color:red">*</label>Note Type</label>
                <p-dropdown formControlName="noteType" [options]="noteTypes" optionLabel="name" optionValue="value" [autoDisplayFirst]="false" [readonly]="note.id != null"></p-dropdown>
                <div *ngIf="mainForm.get('noteType').invalid && mainForm.get('noteType').touched">
                    <p *ngIf="mainForm.get('noteType').errors.required">Required</p>
                </div>
            </div>                                       
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                <label for="comments">Comments</label>                
                <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="false" id="the-textarea"
                    formControlName="comments" appCharacterCounter [maxLength]="2000" [readonly]="note.id != null">                    
                </textarea>
                <label *ngIf="mainForm.get('comments')?.value">{{ 2000 - mainForm.get('comments').value.length}}/2000</label>                                
            </div>                         
        </div>
    </div>

    <div class="p-field p-col-12 p-md-12">
        <!-- <p-accordion [activeIndex]="0"> -->
        <p-accordion>
            <p-accordionTab header="Follow up">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-2">
                        <label for="reminderDate">Reminder Date</label>
                        <p-calendar id="reminderDate" formControlName="reminderDate"></p-calendar>
                    </div>  
                    <div class="p-field p-col-12 p-md-3">
                        <label for="notifiedBy">Task Progress</label>
                        <p-dropdown formControlName="taskProgress" [options]="taskProgress" optionLabel="name" optionValue="value" [autoDisplayFirst]="false" [readonly]="note.id != null"></p-dropdown>
                    </div>       
                    <div class="p-field p-col-12 p-md-2">
                        <label for="completedOn">Completed On</label>
                        <p-calendar id="completedOn" showTime="true" showSeconds="false" formControlName="completedOn" disabled="true"></p-calendar>
                    </div>             
                    <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                        <label for="comments">Task Comments</label>                
                        <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="false" id="the-textarea"
                            formControlName="taskcomments" appCharacterCounter [maxLength]="2000">
                        </textarea>
                        <label *ngIf="mainForm.get('taskcomments')?.value">{{ 2000 - mainForm.get('taskcomments').value.length}}/2000</label>                                
                    </div>  
                </div> 
            </p-accordionTab>                        
        </p-accordion>                         
    </div>  



</div>