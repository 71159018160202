// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  clientsEndpointUrl: 'https://casesback.slatkinlaw.com',
//  clientsEndpointUrl: 'http://localhost:8081',
  location: {
    defaults: {
      country: 'US',
      state: 'MD'
    },
    countries: ['US']
  },
  googleClientId: '890208428437-71pjsip28f8eih0hbl0jn4ttm4qg8bo2.apps.googleusercontent.com',
};
