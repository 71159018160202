import Dates from 'src/app/utilities/Dates';
import { FgEventRenderer } from '@fullcalendar/core';
import { TabView } from 'primeng/tabview';
import { Location } from '@angular/common'
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'providerlocation',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {
    providerlocation: any;
    subscriptions: Subscription[] = [];  
    mainForm: FormGroup;

    locationStatuses:any[]=[
        {name: 'Active', value: 'A'},
        {name: 'Temporary Closed', value: 'TC'},
        {name: 'Closed', value: 'C'},        
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private providersService: ProvidersService, private healthcareUseCase : HealthCareUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {
            
            this.mainForm = fb.group({
                id:[],
                _id:[],
                address: fb.group({
                    country: [],
                    street:[],
                    apartment:[],
                    city:[],
                    state:[],
                    zipcode:[],
                }),
                phoneNumber:[],
                faxNumber:[],
                email:[],
                locationStatus:[],
                statusChangeDate:[],
                officeContact:[],
                createdBy:[],
                creationDate:[],
            })        
        }

        get valid() : boolean {
            return this.mainForm.valid;
        }
    
    
    
        ngOnInit() {
            this.subscriptions.push(this.healthcareUseCase.providerlocationChange.subscribe(location => this.updateModel(location)));
            if(this.config.data) {
                this.updateModel(this.config.data);    
            }
                                                        
        }
    
        ngOnDestroy(): void {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }

        updateModel(providerlocation) {
            this.providerlocation = providerlocation;
            this.mainForm.patchValue(providerlocation);
            
        }

        store() {        
            if(this.mainForm.valid) {
                this.healthcareUseCase.appendLocation(Object.assign(this.providerlocation, this.mainForm.value));
            }
            this.clean();
        }

        get addressEnabled() : boolean {
            return this.mainForm.get('address') != null;
        }

        clean() {
            this.mainForm.reset();
        }
        
        cancel() {
            this.healthcareUseCase.closeLocation();
            this.clean();
        }
}