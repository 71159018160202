import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Location  } from '@angular/common'
import { NgClass  } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { CasesService } from '../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';
import Dates from 'src/app/utilities/Dates';
import { FgEventRenderer } from '@fullcalendar/core';


@Component({
    selector: 'insurance',
    templateUrl: './insurance.component.html',
    styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit, OnDestroy {
    @ViewChild('pipStatusDropdown') pipStatusDropdown!: ElementRef;
    insurance: any;
    insurances = [];
    adjusters= [];
    defendants= [];
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];  
    selectedInsuranceSource: string;
    statusClaimNotes = [];
    formSubmitted = false;
    
    insuranceSource: any[] = [
        {name: 'Plaintiff Insurance', value: 'P'},
        {name: 'Defendant Insurance', value: 'D'}        
    ];

    cstatus: any[] = [
        {name: 'Not Reported Yet', value: 'NOT_REPORTED'},
        {name: 'Liability Pending', value: 'Pending'},
        {name: 'Liability Investigating', value: 'Investigating'},
        {name: 'Liability Accepted', value: 'Accepted'},
        {name: 'UM Liability Coverage Accepted', value: 'UM_Accepted'},
        {name: 'Liability Denied', value: 'Denied'},
        {name: 'Liability Denied (No Coverage)', value: 'No_Coverage'},
        {name: '', value: null}
    ];

    pipstatuses: any[] = [
        {name: 'PIP Waived', value: 'Waived'},
        {name: '1. Not Reported Yet', value: 'Not Reported'},
        {name: '2. PIP Reported', value: 'Reported'},
        {name: '3. PIP Forms Sent', value: 'Forms Sent'},
        {name: '4. PIP Making Payments', value: 'Payments'},
        {name: '5. PIP Exhausted', value: 'Exhausted'}
    ];


    yesnouk: any[] = [
        {name: 'Yes', value: 'Y'},
        {name: 'No', value: 'N'},
        {name: 'Unknown', value: 'U'}
    ];
    
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            insurer:[null, Validators.required],
            policyNumber:[],
            claimNumber:[],
            insurancesource:[null, Validators.required],
            claimstatus:[],
            filingDeadline:[],
            policyState:[null, Validators.required],      
            liabilityStatusChangeDate:[],
            insuranceCoverage: fb.group({
                id:[],
                pipStatus:[], 
                pipStatusChangeDate:[],
                biLEachPerson:[],
                biLEachOccurrence:[],
                pdLCoverage:[],
                deductibleCollision:[],
                pipCoverage:[],
                medPay:[],
                umBIEachPerson:[],
                umBIEachOccurrence:[],
                umPDDeductible:[],
                umPDCoverage:[],
                umCollision :[],
                gapInsurance:[],
                rentalCoverage :[],
                limitCarRental:[],
                rentalEachDay :[],
                rentalEachLoss:[],
            })
        });
        
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        this.selectedInsuranceSource = this.mainForm.get('insurancesource').value;
        this.subscriptions.push(this.intakeUseCase.insuranceChange.subscribe(insurance => this.updateModel(insurance)));
        if(this.config.data) {
            this.updateModel(this.config.data);
        }
        this.catalogsService.findInsurancesAll().then(insurances => this.insurances= insurances);
        if (this.mainForm.get('policyState')) {
            this.mainForm.get('policyState').valueChanges.subscribe(value => {
                if(value){
                    this.mainForm.get('policyState').setValue(value.toUpperCase(), { emitEvent: false });
                }
            });
        }
    }

    onInsuranceSourceChange(event) {
        
        // Actualizar la propiedad selectedInsuranceSource
        this.selectedInsuranceSource = event.value;

       
      }

    ngOnDestroy(): void {
        
    }

    

    updateModel(insurance) {
        this.insurance= insurance;
        this.mainForm.patchValue(insurance);
        this.statusClaimNotes = insurance.statusClaimNotes
        this.defendants=insurance.defendants
        this.adjusters = insurance.adjusters;
    }


    store() { 
        if(this.mainForm.valid) {
            this.intakeUseCase.appendInsurance(Object.assign(this.insurance, this.mainForm.value));
            this.clean();
        }
        
    }

    clean() {
        this.mainForm.reset();
    }

    // investigating() {
    //     this.casesService.investigating(this.intakeUseCase.case.id)
    //     .then(() => this.casesService.findById(this.intakeUseCase.case.id))
    //     .then(c => {
    //         this.intakeUseCase.case = c; 
    //         this.mainForm.patchValue(this.intakeUseCase.processInsuranceModel(c.insurance));
    //     });
    // }

    cancel() {
        this.intakeUseCase.closeInsurance();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

    /*Note*/
    addNote() {
        this.intakeUseCase.addInsuranceNote();
    }

    editNote(note) {
        this.intakeUseCase.editInsuranceNote(note);
    }
    
     /*Adjuster*/
     addAdjuster() {
        this.intakeUseCase.addAdjuster();
    }

    editAdjuster(adjuster) {
        this.intakeUseCase.editAdjuster(adjuster);
    }

     /*Defendant*/
     addNDefendant() {
        this.intakeUseCase.addNDefendant();
    }

    editNDefendant(defendant) {
        this.intakeUseCase.editNDefendant(defendant);
    }

    getInsuranceName(insurance:any): string {
        if(insurance) {            
            return insurance.insuranceCompanyName;
        } else {
            return '';
        }
    }

    investigating() {
        this.casesService.investigating(this.intakeUseCase.insurance.id)        
        .then(() => this.casesService.findById(this.intakeUseCase.insurance.id))
        .then(c => {
            this.intakeUseCase.case = c; 
            this.mainForm.patchValue(this.intakeUseCase.processInsuranceModel(c.insuranceClaim));
        });
    }
    
}