import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import Dates from 'src/app/utilities/Dates';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';

@Component({
    selector: 'vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
    // case: any;
    vehicle: any;
    carexpenses = [];  
    vehicleStatusNotes = []; 
    positioning = [];
    makes = [];    
    subscriptions: Subscription[] = [];  
    mainForm: FormGroup;

    bodyStyles= [
        {name: 'Bus', value: 1},
        {name: 'Convertible', value: 2},
        {name: 'Hatchback', value: 3},
        {name: 'Minivan', value: 4},
        {name: 'Motorcycle', value: 5},
        {name: 'Work Truck', value: 6},
        {name: 'Sedan', value: 7},
        {name: 'Sports Car', value: 8},
        {name: 'SUV', value: 9},
        {name: 'Station Wagon', value: 10},
        {name: 'Trailer', value: 11},
        {name: 'Cargo Truck (Box, Moving)', value: 12}
    ];

    insuranceSource: any[] = [
        {name: 'Plaintiff Insurance', value: 'Plaintiff Insurance'},
        {name: 'Defendant Insurance', value: 'Defendant Insurance'},
        {name: 'Out of Pocket', value: 'Out of pocket'},
        {name: 'Not Paid Yet', value: 'Not Paid Yet'},
        {name: 'Never Paid', value: 'Never Paid'},
    ];

    yesno: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];

    xAirbag= [        
        {name: 'None', value: 7},
        {name: 'Driver only', value: 1},
        {name: 'Passenger only', value: 2},
        {name: 'Side only', value: 3},
        {name: 'Driver and Passenger', value: 4},        
        {name: 'Driver, Passenger and Side', value: 5},
        {name: 'Unknown', value: 6}
    ];

    vConditions= [
        {name: 'Drivable', value: 1},                        
        {name: "Towed to a company's towing yard", value: 2},
        {name: 'Towed Home', value: 3},
        {name: 'Towed to the county impound lot', value: 4}
    ];

    vehileStatuses:any[]=[
        {name: 'Pending', value: 'P'},
        {name: 'Still Damaged', value: 'SD'},
        {name: 'Being Repaired', value: 'BR'},
        {name: 'Repaired', value: 'R'},
        {name: 'Total Loss Pending', value: 'TL'},
        {name: 'Total Loss Paid', value: 'TL'},
        {name: 'Owner Not Represented', value: 'ONR'},
        {name: 'Check Sent to Owner', value: 'CSO'},
    ];


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private catalogsService: CatalogsService, 
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            make:[null, Validators.required],
            model:[],
            year:[],
            vehicleStatus:[],
            statusChangeDate:[],
            vcolor:[],
            vinNo:[],            
            bodyStyle:[],
            licensePlate:[],
            licenseState:[],
            vDefendant:[null, Validators.required],
            vPlaintiff:[null, Validators.required],
            pInCar:[null, Validators.required],
            airbagsDeployed:[],
            insurancesource:[],
            locationName:[],
            phoneNumber:[],
            faxNumber:[],
            website:[],   
            conditions:[],       
            blueBookValue:[],  
            pdAmount:[],
            rowsOfSeats:[],
            carAccidentB:[],
            rebuiltTitle:[],
            isTotal:[false], 
            totalLoss:[], 
            isDov:[false], 
            wasTowed:[], 
            preloss:[], 
            inherentDV:[], 
            repairRelated:[],
            postRepairValue:[],
            totaldov:[],
            address: fb.group({
                country: [],
                street:[],             
                city:[],
                state:[],
                zipcode:[],
            })            
        });
        this.mainForm.get("preloss").valueChanges.subscribe(v => this.updatePostRepairValue());
        this.mainForm.get("inherentDV").valueChanges.subscribe(v => this.updatePostRepairValue());
        this.mainForm.get("repairRelated").valueChanges.subscribe(v => this.updatePostRepairValue());
        
        this.mainForm.get("inherentDV").valueChanges.subscribe(v => this.updatetotaldov());
        this.mainForm.get("repairRelated").valueChanges.subscribe(v => this.updatetotaldov());
    }

    get valid() : boolean {
        return this.mainForm.valid;        
    }

    get plaintiffFault() : boolean {
        return this.vehicle.vdefendant && this.vehicle.vPlaintiff;        
    }

    ngOnInit() {
        this.subscriptions.push(this.intakeUseCase.vehicleChange.subscribe(vehicle => this.updateModel(vehicle)));
        
         if(this.config.data) {
             this.updateModel(this.config.data);
         }
        //  if(this.config.data) {
        //      this.mainForm.patchValue(this.config.data);
        //  }
        if (this.mainForm.get('model')) {
            this.mainForm.get('model').valueChanges.subscribe(value => {
                if(value){
                    this.mainForm.get('model').setValue(this.capitalize(value), { emitEvent: false });
                }
            });
        }

        if (this.mainForm.get('vcolor')) {
            this.mainForm.get('vcolor').valueChanges.subscribe(value => {
                if(value){
                    this.mainForm.get('vcolor').setValue(this.capitalize(value), { emitEvent: false });
                }
            });
        }
        this.catalogsService.findVehicleMakesAll().then(makes => this.makes = makes);
        //this.catalogsService.findVehicleMakesAll().then(response => this.makes = response);
        
        this.mainForm.get('isTotal').valueChanges.subscribe((value: boolean) => {
            if (!value) {
              this.mainForm.get('totalLoss').setValue(0);
            }
          });

          this.mainForm.get('isDov').valueChanges.subscribe((value: boolean) => {
            if (!value) {
              this.mainForm.get('preloss').setValue(0);
              this.mainForm.get('inherentDV').setValue(0);
              this.mainForm.get('repairRelated').setValue(0);
            }
          });

          this.mainForm.get('isDov').valueChanges.subscribe((value: boolean) => {
            if (value) {
              this.mainForm.get('isTotal').patchValue(false);
            }
          });

          this.mainForm.get('isTotal').valueChanges.subscribe((value: boolean) => {
            if (value) {
              this.mainForm.get('isDov').patchValue(false);
            }
          });

    }

    // Helper method to capitalize the first letter and make the rest lowercase
    capitalize(str: string) {
        return str.split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ');
    }
    
    updateModel(vehicle) {
        this.vehicle = vehicle;
        this.carexpenses = vehicle.carexpenses;        
        this.vehicleStatusNotes = vehicle.vehicleStatusNotes;
        this.mainForm.patchValue(vehicle);
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendVehicle(Object.assign(this.vehicle, this.mainForm.value));
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeVehicle();        
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

    addCarExpense() {
        this.intakeUseCase.addCarExpense();
    }

    editCarExpense(carexpense) {
        this.intakeUseCase.editCarExpense(carexpense);
    }
    deleteCarExpense(carexpense) {
        this.intakeUseCase.deleteCarExpense(carexpense);
    }


    updatePostRepairValue() {
        this.mainForm.patchValue({postRepairValue: this.toNumber(this.mainForm.get("preloss").value) 
            - this.toNumber(this.mainForm.get("inherentDV").value)
            - this.toNumber(this.mainForm.get("repairRelated").value)});
    }

    updatetotaldov() {
        this.mainForm.patchValue({totaldov: this.toNumber(this.mainForm.get("inherentDV").value)
            + this.toNumber(this.mainForm.get("repairRelated").value)});
    }

    toNumber(value) {
        return Number(value || 0);
    }

    /*Note*/
    addvNote() {
        this.intakeUseCase.addvNote();
    }

    editvNote(vnote) {
        this.intakeUseCase.editvNote(vnote);
    }

    getPlaintiffs() {
        return this.intakeUseCase.case.plaintiffs;
    }

      /*Positions*/
      addPosition() {
        this.intakeUseCase.addPosition();
    }

}
