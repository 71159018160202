import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { CasesService } from "../cases.service";
import { AccidentComponent } from "../form/accident/accident.component";
import { PlaintiffcarComponent } from "../form/plaintiffcar/plaintiffcar.component";         
import { DefendantComponent } from "../form/defendant/defendant.component";
import { PlaintiffComponent } from "../form/plaintiff/plaintiff.component";
import { WitnessComponent } from "../form/witness/witness.component";
import { VehicleComponent } from "../form/vehicle/vehicle.component";
import * as uuid from 'uuid';
import Dates from "src/app/utilities/Dates";
import { LiabilityComponent } from "../form/defendant/liability/liability.component";
import * as moment from "moment";
import { ComplaintComponent } from "../form/plaintiff/complaint/complaint.component";
import { CarExpenseComponent } from "../form/vehicle/expense/carexpense.component";
import { NoteComponent } from "../form/plaintiff/note/note.component";
import { InsuranceComponent } from "../form/insurance/insurance.component";
import { InsuranceNoteComponent } from "../form/insurance/note/inote.component";
import { VehicleNoteComponent } from '../form/vehicle/vnote/vnote.component';
import { AdjusterComponent } from '../form/insurance/adjuster/adjuster.component';
import { NDefendantComponent } from '../form/insurance/defendant/defendant.component';
import { LoeComponent } from "../form/plaintiff/LOE/loe.component";
import { DisabilityComponent } from '../form/plaintiff/Disability/disability.component';
import { DocumentComponent } from "../form/document/document.component";
// import { PositionComponent } from '../form/vehicle/position/position.component';


@Injectable()
export class IntakeUseCase {
    case:               any;
    defendant:          any;
    document:any;
    plaintiff:          any;
    vehicle:            any;
    insurance:          any;
    plaintiffinsurance: any;
    position:           any;
    

    ref: DynamicDialogRef;
    ref1: DynamicDialogRef;

    public defendantChange = new Subject();
    public plaintiffChange = new Subject();
    public vehicleChange = new Subject();
    public caseChange = new Subject();    
    public insuranceChange = new Subject();    

    constructor(private route: ActivatedRoute, private router: Router, private casesService: CasesService, private dialogService: DialogService) 
    {

    }

    start(caseId?) {
        if(caseId) {
            return this.casesService.findById(caseId)
                .then(_case => {
                    if(_case.interviewDate) {
                        _case.interviewDate = Dates.fromISO(_case.interviewDate);
                    }
                    this.case = _case;
                    this.caseChange.next();
                });
        } else {
            this.case = this.createNewCase();
            return Promise.resolve();
        }
    }

    private createNewCase() : any {
        return {accident:{address:{}}, 
                plaintiffs:[], 
                plaintiffCar:{address:{},car:{}}, 
                defendants:[], 
                witnesses:[],
                vehicles:[],
                insurances:[],
            };
    }

    stop() {

    }

    editAccident(accident) {
        let cloned = {...accident};
        this.processAccidentModel(cloned);
        this.ref = this.dialogService.open(AccidentComponent, {header: 'Accident', width:'70%', data: cloned});
    }    

    processAccidentModel(accident) {
        let cloned = {...accident};
        if(accident.date) {
            accident.date = Dates.fromISO(accident.date); 
        }
        // if(cloned.accidentDateTime) {
        //     cloned.accidentDateTime = Dates.fromISODateTime(cloned.accidentDateTime);
        // }
        if(accident.policeReport && accident.policeReport.dateReceived) {
            accident.policeReport.dateReceived = Dates.fromISO(accident.policeReport.dateReceived); 
        }
        if(accident.policeReport && accident.policeReport.dateRejected) {
            accident.policeReport.dateRejected = Dates.fromISO(accident.policeReport.dateRejected); 
        }
        if(accident.policeReport && accident.policeReport.dateRequested) {
            accident.policeReport.dateRequested = Dates.fromISO(accident.policeReport.dateRequested); 
        }
        return accident;
    }

    accidentEdited(accident) {
        this.case.accident = accident;
        if(accident.date) {
            accident.date = Dates.toISO(accident.date); 
        }
        if(accident.policeReport.dateReceived){
            accident.policeReport.dateReceived = Dates.toISO(accident.policeReport.dateReceived); 
        }
        if(accident.policeReport.dateRejected){
            accident.policeReport.dateRejected = Dates.toISO(accident.policeReport.dateRejected); 
        }
        if(accident.policeReport.dateRequested){
            accident.policeReport.dateRequested = Dates.toISO(accident.policeReport.dateRequested); 
        }
    
        this.storeCase();
        this.ref.close();        
    }

    closeAccident() {
        this.ref.close();
    }



    editPlaintiffcar(plaintiffCar) {
        let cloned = {...plaintiffCar};
        if(cloned.date) {
            cloned.date = Dates.fromISO(cloned.date); 
        }


        this.ref = this.dialogService.open(PlaintiffcarComponent, {header: 'Plaintiff Vehicle', width:'70%', data: cloned});

    }



    plaintiffcarEdited(plaintiffCar) {
        this.case.plaintiffCar = plaintiffCar;
        // if(accident.date) {
        //     accident.date = Dates.toISO(accident.date); 
        // }
        this.storeCase();
        this.ref.close();        
    }

    addPlaintiff() {
        this.plaintiff = {_id: uuid.v4()};
        this.ref = this.dialogService.open(PlaintiffComponent, {header: 'New Plaintiff Information', width:'70%', data: this.plaintiff});
    }

    editPlaintiff(plaintiff) {
        let cloned = JSON.parse(JSON.stringify(plaintiff));
        this.proccessPlaintiffModel(cloned);
        this.plaintiff = cloned;
        this.ref = this.dialogService.open(PlaintiffComponent, {header: 'Plaintiff Information', width:'70%', data: this.plaintiff});
    }

    proccessPlaintiffModel(plaintiff) {
        if(plaintiff.person.dob) {
            plaintiff.person.dob = Dates.fromISO(plaintiff.person.dob);
        }
        if(plaintiff.person.language) {
            plaintiff.person.language = plaintiff.person.language.id;
        }
        if(plaintiff.statusChangeDate) {
            plaintiff.statusChangeDate = Dates.fromISODateTime(plaintiff.statusChangeDate); 
        }
        return plaintiff;
    }

    appendPlaintiff(plaintiff) {
        this.append(this.case.plaintiffs, plaintiff);
        if(plaintiff.person.dob) {
            plaintiff.person.dob = Dates.toISO(plaintiff.person.dob); 
        }
        this.storeCase()
        this.ref.close();
    }

    closePlaintiff() {
        this.ref.close();
    }

    addDefendant() {
        this.defendant = {_id: uuid.v4()};
        this.ref = this.dialogService.open(DefendantComponent, {header: 'New Defendant Information', width:'70%', data: this.defendant});
    }

    editDefendant(defendant) {
        let cloned = {...defendant};
        this.processDefendantModel(cloned);

        this.defendant = cloned;
        this.ref = this.dialogService.open(DefendantComponent, {header: 'Defendant Information', width:'70%', data: cloned});
    }

    processDefendantModel(defendant) {
        if(defendant.person.dob) {
            defendant.person.dob = Dates.fromISO(defendant.person.dob);
        }
        if(defendant.ddriver?.person?.dob) {
            defendant.ddriver.person.dob = Dates.fromISO(defendant.ddriver.person.dob);
        }
        return defendant;
    }

    appendDefendant(defendant) {
        this.append(this.case.defendants, defendant);
        if(defendant.person.dob) {
            defendant.person.dob = Dates.toISO(defendant.person.dob); 
        }
        this.storeCase()
        this.ref.close();
    }

    closeDefendant() {
        this.ref.close();
    }

    addWitness() {
        this.ref = this.dialogService.open(WitnessComponent, {header: 'New Witness Information', width:'70%', data: {_id: uuid.v4()}});
    }

    editWitness(witness) {
        let cloned = {...witness};
        if(cloned.person.dob) {
            cloned.person.dob = Dates.fromISO(cloned.person.dob);
        }
        this.ref = this.dialogService.open(WitnessComponent, {header: 'Witness Information', width:'70%', data: cloned});
    }


    appendWitness(witness) {
        this.append(this.case.witnesses, witness);
        if(witness.person.dob) {
            witness.person.dob = Dates.toISO(witness.person.dob); 
        }
        this.storeCase()
        this.ref.close();
    }

    addVehicle() {
        this.vehicle = {_id: uuid.v4()};
        this.ref = this.dialogService.open(VehicleComponent, {header: 'New Vehicle', width:'70%', data: this.vehicle});
    }

    editVehicle(vehicle) {
        let cloned = {...vehicle};
         this.proccessVehicleModel(cloned);
         this.vehicle = cloned;


        //  let cloned = JSON.parse(JSON.stringify(vehicle));
        //  this.proccessVehicleModel(cloned);
        //  this.vehicle = cloned;





         this.ref = this.dialogService.open(VehicleComponent, {header: 'Vehicle', width:'70%', data: cloned});
    }

    proccessVehicleModel(vehicle) {
        if(vehicle.make) {
            vehicle.make = vehicle.make.id;
        }
        return vehicle;        
    }

    appendVehicle(vehicle) {
        this.append(this.case.vehicles, vehicle);
        this.storeCase()
        this.ref.close();
    }

    closeVehicle() {
        this.ref.close();
    }


    append(arr: any[], element) {
        for(let i = 0; i < arr.length; i++) {
            let other = arr[i];
            if((element.id != null && element.id == other.id) || (element._id != null && element._id == other._id)) {
                arr[i] = element;
                return;
            }
        }
        arr.push(element);
    }

    indexOf(arr: any[], element) : number {
        for(let i = 0; i < arr.length; i++) {
            let other = arr[i];
            if((element.id != null && element.id == other.id) || (element._id != null && element._id == other._id)) {
                arr[i] = element;
                return i;
            }
        }
        return -1;
    }

    storeCase() {
        if(this.case.interviewDate) {
            this.case.interviewDate = Dates.toISO(this.case.interviewDate);
        }
        return this.casesService.store(this.case)
            .then(caseId => this.casesService.findById(caseId))
            .then(_case => this.case = _case)
            .then(() => this.caseChange.next())
            
        
    }

    refreshCase() {
        if(this.case.id) {
            this.casesService
                .store(this.case)
                .then(() => this.casesService.findById(this.case.id))
                .then(_case => this.case = _case);
        }
    }

    remove(arr: any[], element) {
        arr.splice(arr.indexOf(element), 1);
    }

    /*Liability Status*/
    addLiability() {
        this.ref1 = this.dialogService.open(LiabilityComponent, {header: 'New Liability', width:'50%', data: {_id: uuid.v4()}});
    }

    editLiability(liability) {
        let cloned = {...liability};
        if(cloned.statusLastModified) {
            cloned.statusLastModified = Dates.fromISODateTime(cloned.statusLastModified);
        }
        if(cloned.datebyInsurance) {
            cloned.datebyInsurance = Dates.fromISO(cloned.datebyInsurance); 
        }
        this.ref1 = this.dialogService.open(LiabilityComponent, {header: 'Liability', width:'50%', data: cloned});
    }

    appendLiability(liability) {
        let defendantIx = this.indexOf(this.case.defendants, this.defendant);
        this.append(this.defendant.liabilities, liability);
        if(liability.statusLastModified) {
            liability.statusLastModified = Dates.toISODateTime(liability.statusLastModified); 
        } else {
            liability.statusLastModified = Dates.toISODateTime(new Date());
        }
        if(liability.datebyInsurance) {
            liability.datebyInsurance = Dates.toISO(liability.datebyInsurance); 
        }
        this.storeCase()
            .then(() => this.defendant = this.case.defendants[defendantIx])
            .then(() => this.defendantChange.next(this.processDefendantModel(this.defendant)));
        this.ref1.close();
    }

    closeLiability() {
        this.ref1.close();
    }

    /*Plaintiff Complaint*/
    addComplaint() {
        this.ref1 = this.dialogService.open(ComplaintComponent, {header: 'New Complaint', width:'50%', data: {_id: uuid.v4()}});
    }

    editComplaint(complaint) {
        let cloned = this.processComplaintModel({...complaint});
        this.ref1 = this.dialogService.open(ComplaintComponent, {header: 'Complaint', width:'50%', data: cloned});
    }

    appendComplaint(complaint) {
        let plaintiffIx = this.indexOf(this.case.plaintiffs, this.plaintiff);
        this.append(this.plaintiff.complaints, complaint);

        this.storeCase()
            .then(() => this.plaintiff = this.case.plaintiffs[plaintiffIx])
            .then(() => this.plaintiffChange.next(this.proccessPlaintiffModel(this.plaintiff)));
        this.ref1.close();
    }

    deleteComplaint(complaint) {
        let plaintiffIx = this.indexOf(this.case.plaintiffs, this.plaintiff);
        let complaintIx = this.indexOf(this.plaintiff.complaints, complaint);
        this.plaintiff.complaints.splice(complaintIx, 1);
        this.storeCase()
            .then(() => this.plaintiff = this.case.plaintiffs[plaintiffIx])
            .then(() => this.plaintiffChange.next(this.proccessPlaintiffModel(this.plaintiff)));
    }

    processComplaintModel(complaint) {
        if(complaint.symptom) {
            complaint.symptom = complaint.symptom.id;
        }
        if(complaint.complaint) {
            complaint.complaint = complaint.complaint.id;
        }
        return complaint;
    }

    closeComplaint() {
        this.ref1.close();
    }

    /*Car Expenses*/

    addCarExpense() {
        this.ref1 = this.dialogService.open(CarExpenseComponent, {header: 'New Car Expense', width:'50%', data: {_id: uuid.v4()}});
    }

    editCarExpense(carexpense) {
        let cloned = this.processCarExpenseModel({...carexpense});
        this.ref1 = this.dialogService.open(CarExpenseComponent, {header: 'Car Expense', width:'50%', data: cloned});
    }

    appendCarExpense(carexpense) {
        let vehicleIx = this.indexOf(this.case.vehicles, this.vehicle);
        this.append(this.vehicle.carexpenses, carexpense);

        this.storeCase()
            .then(() => this.vehicle = this.case.vehicles[vehicleIx])
            .then(() => this.vehicleChange.next(this.proccessVehicleModel(this.vehicle)));
        this.ref1.close();
    }

    deleteCarExpense(carexpense) {
       
    }

    processCarExpenseModel(carexpense) {
        if(carexpense.type) {
            carexpense.type = carexpense.type.id;
        }
        return carexpense;
    }

     /*Plaintiff Status Notes*/
    addNote() {
        this.ref1 = this.dialogService.open(NoteComponent, {header: 'New Note', width:'50%', data: {_id: uuid.v4()}});                
    }

    editNote(note) {
        let cloned = {...note};
        if(cloned.noteCreatedOn) {
            cloned.noteCreatedOn = Dates.fromISODateTime(cloned.noteCreatedOn);
        }
        if(cloned.eventDate) {
            cloned.eventDate = Dates.fromISO(cloned.eventDate);
        }
        if(cloned.completedOn) {
            cloned.completedOn = Dates.fromISODateTime(cloned.completedOn);
        }
        
        this.ref1 = this.dialogService.open(NoteComponent, {header: 'Notes', width:'50%', data: cloned});
    }

    appendNote(note) {
        let plaintiffIx = this.indexOf(this.case.plaintiffs, this.plaintiff);
        this.append(this.plaintiff.statusPlaintiffNotes, note);
        if(note.noteCreatedOn) {
            note.noteCreatedOn = Dates.toISODateTime(note.noteCreatedOn); 
        } else {
            note.noteCreatedOn = Dates.toISODateTime(new Date());
        }
        if(note.eventDate) {
            note.eventDate = Dates.toISO(note.eventDate); 
        }
        this.storeCase()
            .then(() => this.plaintiff = this.case.plaintiffs[plaintiffIx])
            .then(() => this.plaintiffChange.next(this.proccessPlaintiffModel(this.plaintiff)));
        this.ref1.close();
    }

    closeNote() {
        this.ref1.close();
    }

    /*Vehicle Status Notes*/
    addvNote() {
        this.ref1 = this.dialogService.open(VehicleNoteComponent, {header: 'New Vehicle Note', width:'50%', data: {_id: uuid.v4()}});
    }

    editvNote(vnote) {
        let cloned = {...vnote};
        if(cloned.noteCreatedOn) {
            cloned.noteCreatedOn = Dates.fromISODateTime(cloned.noteCreatedOn);
        }
        if(cloned.eventDate) {
            cloned.eventDate = Dates.fromISO(cloned.eventDate);
        }
        this.ref1 = this.dialogService.open(VehicleNoteComponent, {header: 'Vehicle Notes', width:'50%', data: cloned});
    }

    appendvNote(vnote) {
        let vehicleIx = this.indexOf(this.case.vehicles, this.vehicle);
        this.append(this.vehicle.vehicleStatusNotes, vnote);
        if(vnote.noteCreatedOn) {
            vnote.noteCreatedOn = Dates.toISODateTime(vnote.noteCreatedOn); 
        } else {
            vnote.noteCreatedOn = Dates.toISODateTime(new Date());
        }
        if(vnote.eventDate) {
            vnote.eventDate = Dates.toISO(vnote.eventDate); 
        }
        this.storeCase()
            .then(() => this.vehicle = this.case.vehicles[vehicleIx])
            .then(() => this.vehicleChange.next(this.proccessVehicleModel(this.vehicle)));
        this.ref1.close();
    }

    closevNote() {
        this.ref1.close();
    }

    /*Plaintiff Insurance*/
    AddPlaintiffInsurance() {
        this.plaintiffinsurance = {_id: uuid.v4()};
        this.ref = this.dialogService.open(VehicleComponent, {header: 'Vehicle', width:'70%', data: this.vehicle});
    }

    /*Insurance*/
    addInsurance() {
        this.insurance = {_id: uuid.v4()};
        this.ref = this.dialogService.open(InsuranceComponent, {header: 'New Insurance Information', width:'70%', data: {_id: uuid.v4()}});
    }

    appendInsurance(insurance) {
        this.append(this.case.insuranceClaims, insurance);        
        this.storeCase()
        this.ref.close();
    }

    editInsurance(insurance) {
        let cloned = {...insurance};
        this.processInsuranceModel(cloned);

        this.insurance = cloned;
        this.ref = this.dialogService.open(InsuranceComponent, {header: 'Insurance Information', width:'70%', data: cloned});
    }

    processInsuranceModel(insurance) {
        if(insurance.liabilityStatusChangeDate) {
            insurance.liabilityStatusChangeDate = Dates.fromISODateTime(insurance.liabilityStatusChangeDate); 
        }
        if(insurance.insurer) {
            insurance.insurer = insurance.insurer.id;
        }
        if(insurance.insuranceCoverage.pipStatusChangeDate) {
            insurance.insuranceCoverage.pipStatusChangeDate = Dates.fromISODateTime(insurance.insuranceCoverage.pipStatusChangeDate); 
        }
        if(insurance.filingDeadline) {
            insurance.filingDeadline = Dates.fromISO(insurance.filingDeadline); 
        }
        return insurance;
    }

    
    
    closeInsurance() {
        this.ref.close();
    }


    /*Insurance Status Notes*/
    addInsuranceNote() {
        this.ref1 = this.dialogService.open(InsuranceNoteComponent, {header: 'New Note', width:'50%', data: {_id: uuid.v4()}});
    }

    editInsuranceNote(inote) {
        let cloned = {...inote};
        if(cloned.noteCreatedOn) {
            cloned.noteCreatedOn = Dates.fromISODateTime(cloned.noteCreatedOn);
        }
        if(cloned.eventDate) {
            cloned.eventDate = Dates.fromISO(cloned.eventDate);
        }
        this.ref1 = this.dialogService.open(InsuranceNoteComponent, {header: 'Notes', width:'50%', data: cloned});
    }

    appendInsuranceNote(inote) {
        let insuranceIx = this.indexOf(this.case.insuranceClaims, this.insurance);        
        this.append(this.insurance.statusClaimNotes, inote);
        if(inote.noteCreatedOn) {
            inote.noteCreatedOn = Dates.toISODateTime(inote.noteCreatedOn); 
        } else {
            inote.noteCreatedOn = Dates.toISODateTime(new Date());
        }
        if(inote.eventDate) {
            inote.eventDate = Dates.toISO(inote.eventDate); 
        }
        this.storeCase()
            .then(() => this.insurance = this.case.insuranceClaims[insuranceIx])
            .then(() => this.insuranceChange.next(this.processInsuranceModel(this.insurance)));
        this.ref1.close();
    }

    closeInsuranceNote() {
        this.ref1.close();
    }

    /*Defendant Information*/
    addNDefendant() {
        this.ref1 = this.dialogService.open(NDefendantComponent, {header: 'New Defendant', width:'50%', data: {_id: uuid.v4()}});
    }   

    editNDefendant(ndefendant) {
        let cloned = {...ndefendant};
        this.ref1 = this.dialogService.open(NDefendantComponent, {header: 'Defendant', width:'50%', data: cloned});
    }

    appendNDefendant(defendant) {
        let insuranceIx = this.indexOf(this.case.insuranceClaims, this.insurance);
        this.append(this.insurance.defendants, defendant);
        this.storeCase()
            .then(() => this.insurance = this.case.insuranceClaims[insuranceIx])
            .then(() => this.insuranceChange.next(this.processInsuranceModel(this.insurance)));
        this.ref1.close();
    }

    closeNDefendant() {
        this.ref1.close();
    }

     /*Adjuster Information*/
     addAdjuster() {
        this.ref1 = this.dialogService.open(AdjusterComponent, {header: 'New Adjuster', width:'50%', data: {_id: uuid.v4()}});
    }   

    editAdjuster(adjuster) {
        let cloned = {...adjuster};
        this.ref1 = this.dialogService.open(AdjusterComponent, {header: 'Adjuster', width:'50%', data: cloned});
    }

    appendAdjuster(adjuster) {
        let insuranceIx = this.indexOf(this.case.insuranceClaims, this.insurance);
        this.append(this.insurance.adjusters, adjuster);
        this.storeCase()
            .then(() => this.insurance = this.case.insuranceClaims[insuranceIx])
            .then(() => this.insuranceChange.next(this.processInsuranceModel(this.insurance)));
        this.ref1.close();
    }

    closeAdjuster() {
        this.ref1.close();
    }

    /*Position*/
    addPosition(){
        this.position = {_id: uuid.v4()};
        this.ref = this.dialogService.open(LoeComponent, {header: 'Insurance Information', width:'70%', data: {_id: uuid.v4()}});
    }

    /*LOE*/
    addLoe() {
        this.ref1 = this.dialogService.open(LoeComponent, {header: 'Loss of Earnings', width:'50%', data: {_id: uuid.v4()}});
    }

    editLoe(loe) {
        let cloned = {...loe};
        if(cloned.authorizedOn) {
            cloned.authorizedOn = Dates.fromISO(cloned.authorizedOn);
        }
        if(cloned.startDate) {
            cloned.startDate = Dates.fromISO(cloned.startDate);
        }
        if(cloned.endDate) {
            cloned.endDate = Dates.fromISO(cloned.endDate);
        }
        if(cloned.absentFrom) {
            cloned.absentFrom = Dates.fromISO(cloned.absentFrom);
        }
        if(cloned.absentTo) {
            cloned.absentTo = Dates.fromISO(cloned.absentTo);
        }
        this.ref1 = this.dialogService.open(LoeComponent, {header: 'Loss of Earnings', width:'50%', data: cloned});
    }

    appendLoe(loe) {
        let plaintiffIx = this.indexOf(this.case.plaintiffs, this.plaintiff);
        this.append(this.plaintiff.loes, loe);
        if(loe.authorizedOn) {
            loe.authorizedOn = Dates.toISODateTime(loe.authorizedOn); 
        } 
        if(loe.startDate) {
            loe.startDate = Dates.toISO(loe.startDate); 
        }
        if(loe.endDate) {
            loe.endDate = Dates.toISO(loe.endDate); 
        }
        if(loe.absentFrom) {
            loe.absentFrom = Dates.toISO(loe.absentFrom); 
        }
        if(loe.absentTo) {
            loe.absentTo = Dates.toISO(loe.absentTo); 
        }
        this.storeCase()
            .then(() => this.plaintiff = this.case.plaintiffs[plaintiffIx])
            .then(() => this.plaintiffChange.next(this.proccessPlaintiffModel(this.plaintiff)));
        this.ref1.close();
    }

    closeLoe() {
        this.ref1.close();
    }

    /*Disability*/
    addDisability() {
        this.ref1 = this.dialogService.open(DisabilityComponent, {header: 'Disability Period', width:'50%', data: {_id: uuid.v4()}});
    }

    editDisability(disability) {
        let cloned = {...disability};
        if(cloned.createdOn) {
            cloned.createdOn = Dates.fromISO(cloned.createdOn);
        }
        if(cloned.startDate) {
            cloned.startDate = Dates.fromISO(cloned.startDate);
        }
        if(cloned.endDate) {
            cloned.endDate = Dates.fromISO(cloned.endDate);
        }
        this.ref1 = this.dialogService.open(DisabilityComponent, {header: 'Disability Period', width:'50%', data: cloned});
    }

    appendDisability(disability) {
        let plaintiffIx = this.indexOf(this.case.plaintiffs, this.plaintiff);
        this.append(this.plaintiff.disabilities, disability);
        if(disability.createdOn) {
            disability.createdOn = Dates.toISODateTime(disability.createdOn); 
        } 
        if(disability.startDate) {
            disability.startDate = Dates.toISO(disability.startDate); 
        }
        if(disability.endDate) {
            disability.endDate = Dates.toISO(disability.endDate); 
        }
        this.storeCase()
            .then(() => this.plaintiff = this.case.plaintiffs[plaintiffIx])
            .then(() => this.plaintiffChange.next(this.proccessPlaintiffModel(this.plaintiff)));
        this.ref1.close();
    }

    closeDisability() {
        this.ref1.close();
    }

    addDocument() {
        this.document = {_id: uuid.v4()};
        this.ref = this.dialogService.open(DocumentComponent, {header: 'New Document Information', width:'70%', data: this.document});
    }

    editDocument(document) {
        let cloned = {...document};
        this.proccessDocumentModel(cloned);
        this.document = cloned;
        this.ref = this.dialogService.open(DocumentComponent, {header: 'Document Information', width:'70%', data: this.document});
    }

    proccessDocumentModel(document) {
        if(document.createdOn) {
            document.createdOn = Dates.fromISO(document.createdOn);
        }
        return document;
    }

    appendDocuments(documents) {
        documents.forEach(document => {
            this.append(this.case.documents, document);
            if(document.createdOn) {
                document.createdOn = Dates.toISO(document.createdOn); 
            }
        })
        this.storeCase()
        this.ref.close();
    }

    appendDocument(document) {
        this.append(this.case.documents, document);
        if(document.createdOn) {
            document.createdOn = Dates.toISO(document.createdOn); 
        }
        this.storeCase()
        this.ref.close();
    }

    closeDocument() {
        this.ref.close();
    }

}