import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'department',
    templateUrl: './department.component.html',
    styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit, OnDestroy {
    department: any;
    services = [];
    subscriptions: Subscription[] = [];
    mainForm: FormGroup;

    statuses: any[] = [
        { name: 'Active', value: 'A' },
        { name: 'Inactive', value: 'I' },
    ];


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private providersService: ProvidersService, private healthcareUseCase: HealthCareUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {
        this.mainForm = fb.group({
            id: [],
            _id: [],
            creationDate: [],
            createdBy: [],
            phoneNumber: [],
            deptName: [],
            statusChangeDate: [],
            status: [],
            comments: [],
        })
    }

    get valid(): boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        this.subscriptions.push(this.healthcareUseCase.departmentChange.subscribe(department => this.updateModel(department)));
        if (this.config.data) {
            this.updateModel(this.config.data);
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    updateModel(department) {
        this.department = department;
        this.services = department.services
        this.mainForm.patchValue(department);
    }

    store() {
        if (this.mainForm.valid) {
            this.healthcareUseCase.storeDepartment(Object.assign(this.department, this.mainForm.value));
        }
        this.clean();
    }

    /*Service*/
    addService() {
        if(this.mainForm.valid) {
            if(this.mainForm.dirty) {
                this.healthcareUseCase.appendDepartment(Object.assign(this.department, this.mainForm.value));
            }
            this.healthcareUseCase.addService();
        }
        
    }

    editService(Service) {
        this.healthcareUseCase.editService(Service);
    }

    clean() {
        this.mainForm.reset();
    }


    cancel() {
        this.healthcareUseCase.closeDepartment();
        this.clean();
    }

    getServiceType(type: string): string {
        switch (type) {
            case 'I': return 'Inpatient';
            case 'O': return 'Outpatient';
            case 'E': return 'Emergency';
            case 'G': return 'General Care';
            default:
                return '';
        }
    }


}
