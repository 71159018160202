import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';

@Component({
    selector: 'liability',
    templateUrl: './liability.component.html',
    styleUrls: ['./liability.component.scss']
})
export class LiabilityComponent implements OnInit {
    liability: any;
    mainForm: FormGroup;
    statuses: any[] =  [{value:'Pending'},{value:'Investigating'}, {value:'Accepted'},{value:'Denied'}];

    yesno: any[] = [
        {name: '1. Mail', value:1},
        {name: '2. Email', value: 2},
        {name: '3. Phone call', value: 3}
    ];
   
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            username:[],
            liabilityStatus:[null, Validators.required],
            statusLastModified:[],
            datebyInsurance:[null, Validators.required],
            comments:[],
            notifiedBy:[null, Validators.required],
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.liability = this.config.data;
        }
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendLiability(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeLiability();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}
