<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="addNew()"></button>                    
                </ng-template>
                
                <ng-template pTemplate="right">
                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                        <label class="global-filter-label">Search</label>

                    </span>
                </ng-template>
            </p-toolbar>

            

            <p-table #dtc [value]="objects" [globalFilterFields]="['person.name']" dataKey="id" selectionMode="single"
                styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header"></div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-object>
                    <tr [pSelectableRow]="client">
                        <td>
                            {{object.name}}
                        </td>

                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                (click)="edit(object)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success p-mr-2"
                                (click)="delete(object)"></button>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total of {{objects ? objects.length : 0 }}.
                    </div>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No objects found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>