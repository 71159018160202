<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="New Case" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2"
                        (click)="addNew()"></button>
                </ng-template>
                <ng-template pTemplate="right" [formGroup]="searchForm">
                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" formControlName="term" (keyup.enter)="newSearch()" title="Search cases by Plaintiff's Telephone no., Ref No., Plaintiff Partial Names, Claim Numbers"/>
                        <label class="global-filter-label">Search</label>
                    </span>
                </ng-template>
                <!-- <ng-template [formGroup]="searchForm">
                    <label class="additional-info">Search cases by Plaintiff's Telephone no., Ref No., Plaintiff Partial Names, Claim Numbers</label>
                </ng-template> -->
            </p-toolbar>


            <p><b>Search cases by Date of Birth, Date of Loss and Full or Partial Claim Numbers / Plaintiff's Name / Telephone (Last 4 Digits) / Reference No.</b></p>

            <p-tabView (activeIndexChange)="changeTab($event)">
                <p-tabPanel header="All">
                    <p-table [value]="objects" [globalFilterFields]="['object.paralegalName']" dataKey="id"
                        selectionMode="single"
                        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                        [scrollable]="true" sortField="refNo" sortOrder="-1" scrollHeight="600px" [filterDelay]="0"
                        [rows]="maxResults" [totalRecords]="total" [paginator]="true" [lazy]="true"
                        (onLazyLoad)="loadPage($event)">
                        <ng-template pTemplate="caption">
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;">File No.</th>
                                <th style="width: 100px;">Paralegal</th>
                                <!-- <th style="width: 100px;" pSortableColumn="paralegalName">Paralegal</th> -->
                                <th style="width: 300px;">Plaintiff</th>
                                <th>Accident Date</th>
                                <th>Status</th>
                                <!-- <th pSortableColumn="plaintiff.pstatus">Status</th> -->
                                <th>Phone</th>
                                <th style="width: 100px;">State</th>
                                <!-- <th style="width: 100px;" pSortableColumn="accident.state">State</th> -->
                                <!-- <th pSortableColumn="accident.county">County</th> -->
                                <th>County</th>
                                <th>Statute Date</th>
                                <!-- <th>Status</th> -->
                                <!-- <th>Last Status Change</th> -->
                                <th>Since Status Change</th>
                                <th>Documents</th>
                                <!-- <th><a href="#" (click)="sortTable('paralegalName')">Paralegal</a></th>                                                        -->
                                <!-- <th>Defendant Claim Number</th>
                                <th>Defendant Insurance</th>
                                <th>Defendant Adjuster</th> -->
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="client">
                                <td style="width: 100px;"><p>{{ object.fileNo }}{{ object.orderLetter }}{{ object.countP > 1 ? '.' + object.countP : '' }}</p></td>
                                <td style="width: 100px;">
                                    <p>
                                        <span *ngIf="object.paralegalName === 'Celia'" style="color: #008000; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Jeanette'" style="color: #FF0000; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Martha'" style="color: #0000FF; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Elvia'" style="color: #ADD8E6; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Tina'" style="color: #800020; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        {{ object.paralegalName }}
                                    </p>
                                </td>
                                <td style="width: 300px;">
                                    {{object.clientName}}&nbsp;&nbsp;
                                    <span *ngIf="getAge(object.dob) < 18">
                                        <strong>
                                            <i class="pi pi-user-minus" style="color:red;"></i>
                                            &nbsp;&nbsp;
                                            <span style="color:red;">({{getAge(object.dob)}} y.o.)</span>
                                        </strong>
                                    </span>
                                </td>
                                <td>{{object.accidentDate | date:'E, MMM dd, yyyy'}}</td>
                                <td>
                                    <ng-container *ngIf="object.status">
                                        {{ getStatusWord(object.status) }}
                                    </ng-container>
                                </td>
                                <td>
                                    <p>{{object.phone}}</p>
                                </td>
                                <td style="width: 100px;">{{object.state}}
                                    <span *ngIf="object.state !== 'MD'"
                                        style="color: #FF0000; margin-right: 5px;font-size: 1.5em;">
                                        &#42;
                                    </span>
                                    <!-- <span *ngIf="object.state === 'VA'" style="color: #FF0000; margin-right: 5px;font-size: 1.5em;">
                                        &#42;
                                    </span> -->
                                </td>
                                <td *ngIf="object.state === 'DC'">N/A</td>
                                <td *ngIf="object.state !== 'DC'">{{object.county}}</td>
                                <td>
                                    <p [ngStyle]="{color: getStatuteColor(calculateDaysStatute(object.statuteDate))}">
                                        {{object.statuteDate | date:'E, MMM dd, yyyy'}}
                                    </p>
                                </td>
                                <!-- <td>
                                    <p>{{object.statusChange | date:'E, MMM dd, yyyy'}}</p>
                                </td> -->
                                <td>
                                    <p
                                        [ngStyle]="{color: getStatusColor(calculateDaysSinceStatusChange(object.statusChange),object.status)}">
                                        {{ calculateTimeFrameSinceStatusChange(object.statusChange) }}
                                    </p>
                                </td>
                                <td><p>{{object.countD}}</p></td>
                                <!-- <td><p>{{object.paralegalName}}</p></td> -->
                                <!-- <td><p>{{object.defendant?.claimNumber}} <span *ngIf="object.defendantsCount > 1">(and others)</span></p></td>
                                <td><p>{{object.defendant?.claim?.insuranceName}}</p></td>
                                <td><p>{{object.defendant?.adjusterName}} <span *ngIf="object.defendantsCount > 1">(and others)</span></p></td> -->
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-raised p-button-rounded p-button-success p-mr-2"
                                        (click)="edit(object)"
                                        pTooltip="Edit">
                                    </button>
                                    <!-- <button pButton pRipple icon="pi pi-check-circle"
                                        class="p-button-raised p-button-rounded p-button-info p-mr-2"
                                        (click)="editNotes(object)"
                                        pTooltip="Status">
                                    </button>
                                    <button pButton pRipple icon="pi pi-comments"
                                        class="p-button-raised p-button-rounded p-button-danger p-mr-2"
                                        (click)="editNotes(object)"
                                        pTooltip="Notes">
                                    </button> -->
                                    <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success p-mr-2"
                                        (click)="delete(object)"></button> -->
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                Total of {{results ? results.total : 0 }}.
                            </div>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No objects found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="My cases">
                <!-- <p><b>Here are listed only cases in status Treating, Bills-Records, Ready for Demand and Demand Sent </b></p> -->
                    <p-table [value]="objects" [globalFilterFields]="['object.paralegalName']" dataKey="id" selectionMode="single"
                        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                        [scrollable]="true" sortField="refNo" sortOrder="-1" scrollHeight="600px" [filterDelay]="0"
                        [rows]="maxResults" [totalRecords]="total" [paginator]="true" [lazy]="true"
                        (onLazyLoad)="loadPage($event)">
                        <ng-template pTemplate="caption">
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 100px;">File No.</th>
                                <th style="width: 100px;">Paralegal</th>
                                <th style="width: 300px;">Plaintiff</th>
                                <th>Accident Date</th>
                                <th>Status</th>
                                <th>Phone</th>
                                <th style="width: 100px;">State</th>
                                <th>County</th>
                                <!-- <th>County</th> -->
                                <th>Statute Date</th>
                                <!-- <th>Status</th> -->
                                <th>Last Status Change</th>
                                <th>Since Status Change</th>
                                <th>Documents</th>
                                <!-- <th><a href="#" (click)="sortTable('paralegalName')">Paralegal</a></th>                                                        -->
                                <!-- <th>Defendant Claim Number</th>
                                <th>Defendant Insurance</th>
                                <th>Defendant Adjuster</th> -->
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="client">
                                <td style="width: 100px;"><p>{{ object.fileNo }}{{ object.orderLetter }}{{ object.countP > 1 ? '.' + object.countP : '' }}</p></td>
                                <td style="width: 100px;">
                                    <p>
                                        <span *ngIf="object.paralegalName === 'Celia'" style="color: #008000; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Jeanette'" style="color: #FF0000; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Martha'" style="color: #0000FF; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Elvia'" style="color: #ADD8E6; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        <span *ngIf="object.paralegalName === 'Tina'" style="color: #800020; margin-right: 5px;font-size: 2em;">
                                            &#9679;
                                        </span>
                                        {{ object.paralegalName }}
                                    </p>
                                </td>
                                <td style="width: 300px;">
                                    {{object.clientName}}&nbsp;&nbsp;
                                    <span *ngIf="getAge(object.dob) < 18">
                                        <strong>
                                            <i class="pi pi-user-minus" style="color:red;"></i>
                                            &nbsp;&nbsp;
                                            <span style="color:red;">({{getAge(object.dob)}} y.o.)</span>
                                        </strong>
                                    </span>
                                </td>
                                <td>{{object.accidentDate | date:'E, MMM dd, yyyy'}}</td>
                                <td>
                                    <ng-container *ngIf="object.status">
                                        {{ getStatusWord(object.status) }}
                                    </ng-container>
                                </td>
                                <td>
                                    <p>{{object.phone}}</p>
                                </td>
                                <td style="width: 100px;">{{object.state}}
                                    <span *ngIf="object.state !== 'MD'"
                                        style="color: #FF0000; margin-right: 5px;font-size: 1.5em;">
                                        &#42;
                                    </span>
                                    <!-- <span *ngIf="object.state === 'VA'" style="color: #FF0000; margin-right: 5px;font-size: 1.5em;">
                                        &#42;
                                    </span> -->
                                </td>
                                <td *ngIf="object.state === 'DC'">N/A</td>
                                <td *ngIf="object.state !== 'DC'">{{object.county}}</td>
                                <td>
                                    <p [ngStyle]="{color: getStatuteColor(calculateDaysStatute(object.statuteDate))}">
                                        {{object.statuteDate | date:'E, MMM dd, yyyy'}}
                                    </p>
                                </td>
                                <td>
                                    <p>{{object.statusChange | date:'E, MMM dd, yyyy'}}</p>
                                </td>
                                <td>
                                    <p
                                        [ngStyle]="{color: getStatusColor(calculateDaysSinceStatusChange(object.statusChange),object.status)}">
                                        {{ calculateTimeFrameSinceStatusChange(object.statusChange) }}
                                    </p>
                                </td>
                                <td><p>{{object.countD}}</p></td>
                                <!-- <td><p>{{object.paralegalName}}</p></td> -->
                                <!-- <td><p>{{object.defendant?.claimNumber}} <span *ngIf="object.defendantsCount > 1">(and others)</span></p></td>
                                <td><p>{{object.defendant?.claim?.insuranceName}}</p></td>
                                <td><p>{{object.defendant?.adjusterName}} <span *ngIf="object.defendantsCount > 1">(and others)</span></p></td> -->
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-raised p-button-rounded p-button-success p-mr-2"
                                        (click)="edit(object)"
                                        pTooltip="Edit">
                                    </button>
                                    <button pButton pRipple icon="pi pi-check-circle"
                                        class="p-button-raised p-button-rounded p-button-info p-mr-2"
                                        (click)="editNotes(object)"
                                        pTooltip="Status">
                                    </button>
                                    <button pButton pRipple icon="pi pi-comments"
                                        class="p-button-raised p-button-rounded p-button-danger p-mr-2"
                                        (click)="editNotes(object)"
                                        pTooltip="Notes">
                                    </button>
                                    <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success p-mr-2"
                                        (click)="delete(object)"></button> -->
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                Total of {{results ? results.total : 0}}.
                            </div>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No objects found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
