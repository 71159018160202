<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple label="New Provider" icon="pi pi-plus"
                        class="p-button-success p-mr-2 p-mb-2" (click)="addNew()"></button>
                </ng-template>
                <ng-template pTemplate="right" [formGroup]="searchForm">
                    <span class="p-input-icon-left p-float-label">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" formControlName="term" (keyup.enter)="newSearch()"
                            title="Search Providers by State, County, Name, City, Zipcode" />
                        <label class="global-filter-label">Search</label>
                    </span>
                </ng-template>
            </p-toolbar>

            <p><b>Search Providers by State, County, Name, City, Zipcode </b></p>

            <!--
            <p-table [value]="objects" dataKey="id" selectionMode="single"
                styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                [scrollable]="true" sortField="refNo" sortOrder="-1" scrollHeight="600px" [filterDelay]="0"
                [rows]="maxResults" [totalRecords]="total" [paginator]="true" [lazy]="true"
                (onLazyLoad)="loadPage($event)">
                <ng-template pTemplate="caption">
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 500px;">Provider</th>
                        <th>Address</th>
                        <th>Creation Date</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-object>
                    <tr [pSelectableRow]="provider">
                        <td style="width: 500px;">
                            {{object.providerName}}<strong> ({{ getFacilityTypeWord(object.type) }})</strong> in
                            {{object.address.city}}.{{object.address?.state}}
                        </td>
                        <td>
                            <div *ngIf="object.address">
                                <div>{{object.address.street}},
                                    <strong *ngIf="object.address.apartment">Suite No:
                                        {{object.address.apartment}}.</strong>
                                    {{object.address.city}}.{{object.address?.state}}. {{object.address?.zipcode}}
                                </div>
                            </div>
                        </td>
                        <td>{{object.creationDate | date:'E, MMM dd, yyyy'}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil"
                                class="p-button-raised p-button-rounded p-button-success p-mr-2" (click)="edit(object)"
                                pTooltip="Edit">
                            </button>
                            <button pButton pRipple icon="pi pi-check-circle"
                                class="p-button-raised p-button-rounded p-button-info p-mr-2" (click)="edit(object)"
                                pTooltip="Status">
                            </button>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total of {{results ? results.total : 0 }}.
                    </div>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No objects found.</td>
                    </tr>
                </ng-template>
            </p-table>
        -->

            <p-treeTable [value]="nodes" [rows]="maxResults" [totalRecords]="total" [paginator]="true" [lazy]="true"
                (onLazyLoad)="loadPage($event)"
                styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 50%;">Name</th>
                        <th style="width: 25%;">Contact</th>
                        <th style="width: 100px;">Creation Date</th>
                        <th style="width: 125px;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                    <tr [ttRow]="rowNode">
                        <td>
                            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                            <span *ngIf="rowData.outsourcingName">
                                {{rowData.outsourcingName}} > 
                            </span>
                            <span>
                                {{rowData.name}} <span *ngIf="rowData.type && rowData.address"><strong>({{
                                        getFacilityTypeWord(rowData.type)}})</strong> in
                                    {{rowData.address.city}}.{{rowData.address?.state}}</span>
                            </span>

                        </td>
                        <td colspan="2">
                            <div *ngFor="let contact of rowData.contacts" style="list-style: none;">
                                <span>[{{contact.priority}}] </span>
                                <span>
                                    <i *ngIf="contact.type == 'MAIL'" class="pi pi-inbox"></i>
                                    <i *ngIf="contact.type == 'EMAIL'" class="pi pi-send"></i>
                                    <i *ngIf="contact.type == 'FAX'" class="pi pi-print"></i>
                                    <i *ngIf="contact.type == 'PORTAL'" class="pi pi-cloud-download"></i>
                                </span>
                                <span> {{contact.value}}</span>
                            </div>
                        </td>
                        <td>{{rowData.creationDate | date:'MM/dd/yyyy'}}</td>
                        <td>
                            <div *ngIf="rowData.nodeType == 'Provider'">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-raised p-button-rounded p-button-success p-mr-2"
                                    (click)="edit(rowData)" pTooltip="Edit">
                                </button>
                                <button pButton pRipple icon="pi pi-check-circle"
                                    class="p-button-raised p-button-rounded p-button-info p-mr-2"
                                    (click)="edit(rowData)" pTooltip="Status">
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        Total of {{results ? results.total : 0 }}.
                    </div>
                </ng-template>
            </p-treeTable>

        </div>
    </div>
</div>