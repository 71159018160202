import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class AccidentService {
    constructor(private http: HttpClient){

    }

    store(accident) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/accidents', accident).toPromise();
    }

    findAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/accidents').toPromise();
    }

    findById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/accidents/' + id).toPromise();
    }

    deleteById(id){
        return this.http.delete<any>(environment.clientsEndpointUrl + '/accidents/' + id).toPromise();
    }
}