import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
    selector: 'personinput',
    templateUrl: './personinput.component.html',
    styleUrls: ['./personinput.component.scss']
})
export class PersonInputComponent implements OnInit {
    ngOnInit(): void {
    }
}