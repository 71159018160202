import { Routes } from '@angular/router';

import { CaseListComponent } from './list/caselist.component';
import { CaseFormComponent } from './form/caseform.component';

export const CasesRoutes: Routes = [
    {
        path: '',
        component: CaseListComponent,
    },
    {
        path: 'new',
        component: CaseFormComponent
    },
    {
        path: ':caseId',
        component: CaseFormComponent
    },
];
