import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class PortalService {
    constructor(private http: HttpClient){

    }

    store(person) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/clients', person).toPromise();
    }

    findAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/clients').toPromise();
    }

    findById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/clients/' + id).toPromise();
    }

    deleteById(id){
        return this.http.delete<any>(environment.clientsEndpointUrl + '/clients/' + id).toPromise();
    }
}