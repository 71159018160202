import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CasesService } from '../../cases.service';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';

@Component({
    selector: 'plaintiffcar',
    templateUrl: './plaintiffcar.component.html',
    styleUrls: ['./plaintiffcar.component.scss']
})
export class PlaintiffcarComponent implements OnInit {
    formGroup: FormGroup
    subscriptions: Subscription[] = [];

    bodyStyle: any[] = [
        { name: 'Sedan', value: '1' },
        { name: 'Sport-Utility Vehicle (Suv)', value: '2' },
        { name: 'Pickup Truck', value: '3' },
        { name: 'Convertible', value: '4' },
        { name: 'Hatchback', value: '5' },
        { name: 'Motorcycle - light rain', value: '6' },
        { name: 'Minivan', value: '7' },
        { name: 'Van', value: '8' },
        { name: 'Station Wagon', value: '9' },
        { name: 'Trailer', value: '10' }
    ];


    yesno: any[] = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ];

    

    constructor(private fb: FormBuilder, private intakeUseCase: IntakeUseCase, private config: DynamicDialogConfig) {
        this.formGroup = fb.group({
            id:[],
            bodyStyle: [],
            driver: [],
            owner: [],
            towed: [],
            timestowed: [],
            towing_amount: [],
            storageamount: [],
            drivable: [],
            repairable: [],
            property_damage_amount: [],
            dov: [],
            dov_date_requested: [],
            dov_amount: [],
            dov_study_cost: [],
            total_loss: [],
            total_loss_amount: [],
            car: fb.group({
                id:[],
                tagNo: [],
                carMake: [],
                carModel: [],
                carColor: [],
                carYear: [],
            }),
            address: fb.group({
                country: [],
                street: [],
                city: [],
                state: [],
                zipcode: [],
            })
        })
    }

    ngOnInit() {
        if (this.config.data) {
            this.formGroup.patchValue(this.config.data);
        }
    }

    get valid(): boolean {
        return true;
    }

    store() {
        if (this.formGroup.valid) {
            this.intakeUseCase.plaintiffcarEdited(this.formGroup.value);
        }
        // if(this.formGroup.valid) {
        //     this.intakeUseCase.plaintiffcarEdited(this.formGroup.value);
    }

    clean() {
        this.formGroup.reset();
    }

    cancel() {
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }


}
