import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { CatalogsService } from '../catalogs.service';

@Component({
    selector: 'cataloglist',
    templateUrl: './cataloglist.component.html',
    styleUrls: ['./cataloglist.component.scss']
})
export class CatalogListComponent implements OnInit {
    catalogType: string;

    objects: any[];

    constructor(private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute, private router: Router, private catalogsService: CatalogsService) {
        this.breadcrumbService.setItems([
            { label: 'Parameter' },
            { label: 'Catalog', routerLink: ['/uikit/table'] }
        ]);
    }


    ngOnInit() {
        this.route.params.subscribe(event => {
            this.catalogType = event.catalogType;
            this.load();
        });
    }

    load() {
        this.catalogsService.findObjectsByType(this.catalogType).then(objects => this.objects = objects);
    }

    addNew(){
        this.router.navigate([this.router.url, 'new']);
    }

    edit(object) {
        this.router.navigate([this.router.url, object.id]);
    }

    delete(object) {
        this.catalogsService.deleteObjectByReference({type:this.catalogType, id: object.id}).then(() => this.load());
    }

    done() {
    }

    cancel() {

    }

}
