<div [style]="{'height':'530px'}" [formGroup]="mainForm">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"
        (click)="cancel()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
        (click)="store()"></button>

    <div class="p-col-12"></div> <!-- Forces a new line -->

    <div class="card" style="background-color: powderblue;">
        <div class="p-grid p-fluid">
            <div class="p-field p-col-12 p-md-2">
                <label for="deptName"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Dept.
                    Name</label>
                <input id="deptName" type="text" pInputText formControlName="deptName" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="phoneNumber"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Office
                    phone</label>
                <p-inputMask id="phoneNumber" mask="(999) 999-9999? x9999999999" formControlName="phoneNumber"
                    autocomplete="doNotAutoComplete"></p-inputMask>
                <!-- unmask="true" -->
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="status"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Status</label>
                <p-dropdown formControlName="status" [options]="statuses" optionLabel="name" optionValue="value"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="statusChangeDate"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Last
                    Status Changed On</label>
                <p-calendar id="statusChangeDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy"
                    [hourFormat]="12" formControlName="statusChangeDate" disabled="true"></p-calendar>
            </div>
        <!-- </div> -->
        <!-- <div class="p-grid p-fluid" style="display: flex; justify-content: flex-end;"> -->
            <div class="p-field p-col-12 p-md-2">
                <label for="creationDate"><label></label>Creation Date</label>
                <p-calendar id="creationDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy"
                    [hourFormat]="12" formControlName="creationDate" disabled="true"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="createdBy"><label></label>Created By</label>
                <input id="createdBy" type="text" pInputText formControlName="createdBy" readonly
                    autocomplete="doNotAutoComplete">
            </div>
        </div>
    </div>
    <p-table #dtc [value]="services" dataKey="id" selectionMode="single"
        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1" [scrollable]="true" scrollHeight="600px"
        [filterDelay]="0" [rows]="4" [paginator]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Services Type</th>
                <th>Services</th>
                <th>Fee</th>
                <th>Created By</th>
                <th>Created On</th>
                <th>Status</th>
                <th>
                    <p-button label="Add a Service" icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                        (click)="addService()"></p-button>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-object>
            <tr [pSelectableRow]="note">
                <td>
                    <ng-container *ngIf="object.serviceType">
                        {{ getServiceType(object.serviceType) }}
                    </ng-container>
                </td>
                <!-- <td>{{object.serviceType}}</td> -->
                <td>{{object.doctype?.name}}</td>
                <td>{{object.fee | currency: 'USD' }}</td>
                <td>{{object.createdBy}}</td>
                <td>{{object.creationDate | date:'E, MMM dd, yyyy'}}</td>
                <td></td>
                <td>
                    <p-button type="button" label="Edit Service" icon="pi pi-pencil"
                        styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button"
                        (click)="editService(object)"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Total of {{services ? services.total : 0 }}.
            </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No records available.</td>
            </tr>
        </ng-template>
    </p-table>
</div>