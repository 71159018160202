<div [style]="{'height':'460px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;">         
        <div class="p-fluid p-grid">     
            <div class="p-field p-col-12 p-md-2">
                <label for="type">Expense Type</label>
                <p-dropdown id="type" formControlName="type" [options]="carexpenses" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>                      
            <div class="p-field p-col-12 p-md-2">
                <label for="expenseAmount">Amount Paid</label>
                <p-inputNumber mode="currency" currency="USD" id="expenseAmount" type="text" formControlName="expenseAmount"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="paidBy">Paid by</label>
                <input id="paidBy" type="text" pInputText formControlName="paidBy"                 
                autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="paidOn">Paid On</label>
                <p-calendar id="paidOn" formControlName="paidOn"></p-calendar>
            </div>
        </div>
        <div class="p-fluid p-grid">   
            <div class="p-field p-col-12 p-md-2">
                <label for="reimbursedBy">Reimbursed by</label>
                <input id="reimbursedBy" type="text" pInputText formControlName="reimbursedBy"                 
                autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="reimbursedOn">Reimbursed On</label>
                <p-calendar id="reimbursedOn" formControlName="reimbursedOn"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">                                                    
                <h6>Evidence Attached?</h6>
               <p-inputSwitch formControlName="evidenceAttached" 
                    pTooltip="Upload evidence for future reference" 
                    tooltipPosition="top">
                </p-inputSwitch>
            </div>
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                <label for="specify">Comments</label>
                <textarea inputId="textarea" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                    formControlName="comments"></textarea>                       
                    <label></label>
            </div>                   
        </div>        
    </div>
</div>

<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

