import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import Dates from 'src/app/utilities/Dates';
import { Directive, ElementRef, HostListener} from '@angular/core';

@Component({
    selector: 'disability',
    templateUrl: './disability.component.html',
    styleUrls: ['./disability.component.scss']
})
export class DisabilityComponent implements OnInit {
    disability: any;
    mainForm: FormGroup;    
    
    yesnoOp: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            username:[],
            createdOn:[],
            startDate:[],
            endDate:[],
            daysAbsent:[],
            comments:[],
        });
         this.mainForm.get("startDate").valueChanges.subscribe(v => this.updatePostDaysAbsent());
         this.mainForm.get("endDate").valueChanges.subscribe(v => this.updatePostDaysAbsent());
        //  this.mainForm.get("daysAbsent").valueChanges.subscribe(v => this.updatePostDaysAbsent());                        
      }
    
    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.disability = this.config.data;
        }       
    }
    
    store() {
        if(this.mainForm.invalid) {
            Object.values(this.mainForm.controls).forEach(control => {
                control.markAsTouched();
            });
        }
        if(this.mainForm.valid) {
            this.intakeUseCase.appendDisability(this.mainForm.value);
            this.clean();
        }
    }
    

    updatePostDaysAbsent() 
    {   
        var daysDifference = moment(this.mainForm.get("endDate").value).diff(moment(this.mainForm.get("startDate").value), 'days')+1;
        this.mainForm.patchValue({daysAbsent: daysDifference});        
    }
        



    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeDisability();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}