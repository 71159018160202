import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { BlobStorageService } from '../../blobstorage.service';
import { HealthCareUseCase } from '../../usecase/HealthCareUseCase';


@Component({
    selector: 'document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class ProviderDocumentComponent implements OnInit {
    mainForm: FormGroup;
    documentTypes = [];
    files: any[] = [];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private catalogsService: CatalogsService, 
        private blobStorageService: BlobStorageService, 
        private healthcareUseCase : HealthCareUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            documentType:[null, Validators.required],
        });
    }

    ngOnInit(): void {
        this.catalogsService.findDocumentTypesAll().then(types => this.documentTypes = types);
    }

    uploadHandler(event) {
        console.log("upload", event);
    }

    selectFile(event) {
        this.files = [];
        event.currentFiles.forEach(f => this.files.push(f));
        console.log("upload", event);
    }

    removeFile(event) {
        let ix = this.files.indexOf(event.file);
        if(ix != -1) {
            this.files.splice(ix, 1);
            console.log("remove", event);    
        }
    }

    cancel() {
        this.healthcareUseCase.closeDocument();        
    }
    
    store() {
        if(this.mainForm.valid) {
            Promise
             .all(this.files.map(file => this.blobStorageService.storeBlob(this.healthcareUseCase.provider.id, this.mainForm.value.documentType, file)))
            //.all(this.files)
            .then(responses => this.healthcareUseCase.appendDocuments(responses.map(fileInfo => this.toDocument(fileInfo))));
        }
    }

    toDocument(fileInfo) {
        return {type: this.mainForm.value.documentType, fileInfo: fileInfo};
    }
}