import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';

@Component({
    selector: 'requestmethod',
    templateUrl: './requestmethod.component.html',
    styleUrls: ['./requestmethod.component.scss']
})
export class RequestMethodComponent implements OnInit {
    // service: any;
    // requestM: any;
    requestMethod: any;
    mainForm: FormGroup;
    DocType = [];
    servicesTypes = [];
    portals = [];
    isAddressEnabled = true;

    requestBy: any[] = [
        //{name: '', value:0},
        { name: 'Fax', value: 1 },
        { name: 'Mail', value: 2 },
        { name: 'Portal', value: 3 },
        { name: 'Email', value: 4 }
    ];

    resultBy: any[] = [
        { name: 'N/A', value: 0 },
        { name: 'Fax', value: 1 },
        { name: 'Mail', value: 2 },
        { name: 'Portal', value: 3 },
        { name: 'Email', value: 4 }
    ];

    statuses: any[] = [
        { name: 'Active', value: 0 },
        { name: 'Inactive', value: 1 },
    ];

    priorities: any[] = [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 6, value: 6 },
        { name: 7, value: 7 },
        { name: 8, value: 8 },
        { name: 9, value: 9 },
        { name: 10, value: 10 },
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private providersService: ProvidersService, private healthcareUseCase: HealthCareUseCase,
        private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id: [],
            _id:[],
            createdOn: [],
            createdBy: [],
            reqmethodBy: [],
            resmethodBy: [],
            fax: [],
            emailAddress: [],
            portal: [],
            daysToRespond: [],
            status: [0, Validators.required],
            comments: [],
            priorityLevel: [],
            responsePortal: [],
            address: fb.group({
                country: [],
                street: [],
                apartment: [],
                city: [],
                state: [],
                zipcode: [],
            }),
        });
        // Disable all fields by default
        this.disableAllFields();
    }

    selectedPortalId: number;
    portalName: string;

    onPortalChange(event) {
        const selectedPortal = this.portals.find(portal => portal.id === event.value);
        this.portalName = selectedPortal ? selectedPortal.name : '';
    }

    get valid(): boolean {
        return this.mainForm.valid;
    }

    get addressEnabled(): boolean {
        return this.mainForm.get('address') != null;
    }

    ngOnInit() {
        this.catalogsService.findPortalsAll().then(portals => this.portals = portals);
        this.mainForm.get('reqmethodBy').valueChanges.subscribe(value => {
            this.handleRequestMethodChange(value);
        });
        this.mainForm.get('resmethodBy').valueChanges.subscribe(value => {
            this.handleResultMethodChange(value);
        });

        if (this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.requestMethod = this.config.data;
            this.handleRequestMethodChange(this.requestMethod.reqmethodBy);
            this.handleResultMethodChange(this.requestMethod.resmethodBy);
        }
    }

    handleResultMethodChange(value: number) {
        const responseportalControl = this.mainForm.get('responsePortal');


        // Reset all controls to enabled state
        responseportalControl.disable();

        switch (value) {
            case 3: // Response Portal selected
                responseportalControl.enable();
                break;
        }
    }

    // Method to handle changes in reqmethodBy dropdown
    handleRequestMethodChange(value: number) {
        const faxControl = this.mainForm.get('fax');
        const emailControl = this.mainForm.get('emailAddress');
        const portalControl = this.mainForm.get('portal');

        const addressControl = this.mainForm.get('address');

        // Clear values of non-selected fields
        if (value !== 1) faxControl.setValue('');
        if (value !== 4) emailControl.setValue('');
        if (value !== 3) portalControl.setValue('');
        if (value !== 2) {
            addressControl.get('country').setValue('US');
            addressControl.get('street').setValue('');
            addressControl.get('apartment').setValue('');
            addressControl.get('city').setValue('');
            addressControl.get('state').setValue('MD');
            addressControl.get('zipcode').setValue('');
        }

        // Reset all controls to enabled state
        faxControl.disable();
        emailControl.disable();
        portalControl.disable();
        addressControl.disable();

        this.isAddressEnabled = true;

        switch (value) {
            case 1: // Fax selected
                faxControl.enable();
                break;
            case 2: // Mail selected
                addressControl.enable();
                break;
            case 3: // Portal selected
                portalControl.enable();
                break;
            case 4: // Email selected
                emailControl.enable();
                break;
        }
    }


    // Method to disable all fields
    disableAllFields() {
        this.mainForm.get('fax').disable();
        this.mainForm.get('emailAddress').disable();
        this.mainForm.get('portal').disable();
        this.mainForm.get('address').disable();
        this.mainForm.get('responsePortal').disable();
    }

    store() {
        if (this.mainForm.valid) {
            this.healthcareUseCase.storeRequestMethod(Object.assign(this.requestMethod, this.mainForm.value));
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.healthcareUseCase.closeRequestMethod();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}
