import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { eventTupleToStore } from '@fullcalendar/core/structs/event-store';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { CatalogsService } from '../catalogs.service';

@Component({
    selector: 'catalogform',
    templateUrl: './catalogform.component.html',
    styleUrls: ['./catalogform.component.scss']
})
export class CatalogFormComponent implements OnInit {
    catalogType: string;
    catalogId: number;

    object: any; 

    constructor(private breadcrumbService: AppBreadcrumbService, private location: Location, private route: ActivatedRoute, private router: Router, private catalogsService: CatalogsService) {

    }

    ngOnInit() { 
        this.route.params.subscribe(event => {
            this.catalogType = event.catalogType;
            if(event.objectId) {
                this.catalogsService.findObjectByReference({type:event.catalogType, id:event.objectId})
                    .then(object => this.object = {type:event.catalogType, ...object});
            } else {
                this.object = {type: event.catalogType};
            }
        });
    }

    back() {
        this.object = null;
        this.location.back()
    }

    store() {
        this.catalogsService.storeObject(this.object).then(() => this.back());
    }

    cancel() {
        this.back();
    }

    activate() {

    }

    inactivate() {

    }

}
