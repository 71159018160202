<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'900px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;"> 
        <h6 class="group-title">Company Information</h6>
        <div class="p-fluid p-grid">            
            <div class="p-field p-col-12 p-md-2">
                <label for="companyName">Name</label>
                <input id="companyName" type="text" pInputText formControlName="companyName" style="text-transform: capitalize;" 
                autocomplete="doNotAutoComplete"
                [attr.title]="mainForm.get('companyName').value">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="authorizedBy">Authorized By</label>
                <input id="authorizedBy" type="text" pInputText formControlName="authorizedBy" style="text-transform: capitalize;" 
                pTooltip="Name of the person who signed the form" 
                tooltipPosition="top"
                [attr.title]="mainForm.get('authorizedBy').value"
                autocomplete="doNotAutoComplete">
            </div>            
            <div class="p-field p-col-12 p-md-2">
                <label for="title">Title</label>
                <input id="title" type="text" pInputText formControlName="title" style="text-transform: capitalize;" 
                pTooltip="Title of the person who signed the form" 
                tooltipPosition="top"
                [attr.title]="mainForm.get('title').value"
                autocomplete="doNotAutoComplete">
            </div> 
            <div class="p-field p-col-12 p-md-2">
                <label for="authorizedOn">Authorized On</label>
                <p-calendar id="authorizedOn" formControlName="authorizedOn"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="phone">Phone Ext</label>
                <p-inputMask id="phone" mask="(999) 999-9999? x9999999999" formControlName="phone" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>                                                                                               
            <div class="p-field p-col-12 p-md-2">
                <label for="email">Email</label>
                <input id="email" type="text" pInputText formControlName="email" 
                [attr.title]="mainForm.get('email').value"
                autocomplete="doNotAutoComplete">
            </div>
            <br>
            <div class="p-field p-col-12 p-md-20">
                <address formGroupName="address"></address>    
            </div>   
        </div>
    </div>
    <p-tabView [style]="{'height':'600px'}" >   
        <p-tabPanel header="Parameters">
            <div class="card"> 
                <h6 class="group-title">Employee Information</h6>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-12 p-md-2">
                        <label for="jobTitle">Title</label>
                        <input id="jobTitle" type="text" pInputText formControlName="jobTitle" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                    </div>            
                    <div class="p-field p-col-12 p-md-2">
                        <label for="employmentStatus"><label style="color:red">* </label>Employment Status</label>
                        <p-dropdown inputId="employmentStatus" formControlName="employmentStatus" [options]="eStatus" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div> 
                    <div class="p-field p-col-12 p-md-2">
                        <label for="startDate">Start Date</label>
                        <p-calendar id="startDate" formControlName="startDate"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="endDate">End Date</label>
                        <p-calendar id="endDate" formControlName="endDate"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-1">
                    <h6>To Present</h6>
                       <p-inputSwitch formControlName="present"></p-inputSwitch>
                    </div>                    
                    <div class="p-field p-col-12 p-md-2">
                        <label for="notClaim">LOE Form on File?</label>
                                <span class="p-float-label">
                                    <p-dropdown id="notClaim" formControlName="loeOnFile" [options]="yesnoOp" optionValue="value" optionLabel="name"                  
                                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                                </span>           
                    </div> 
                    <div class="p-field p-col-12 p-md-2">
                        <label for="absentFrom"><label style="color:red">* </label>Absent From</label>
                        <p-calendar id="absentFrom" formControlName="absentFrom"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="absentTo"><label style="color:red">* </label>Absent To</label>
                        <p-calendar id="absentTo" formControlName="absentTo"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="daysAbsent">Total Days Absent</label>
                        <p-inputNumber formControlName="daysAbsent" id="daysAbsent" [disabled]="true"> </p-inputNumber>
                    </div>            
                    <div class="p-field p-col-12 p-md-2">
                        <label for="daysWorkWeek">Days Worked in a Week</label>
                        <p-inputNumber formControlName="daysWorkWeek" id="daysWorkWeek" [min]="0" [max]="7"> </p-inputNumber>
                    </div>            
                    <div class="p-field p-col-12 p-md-2">
                        <label for="hoursDay"><label style="color:red">* </label>Hours Worked in a Day</label>
                        <p-inputNumber formControlName="hoursDay" id="hoursDay" [min]="0" [max]="12"> </p-inputNumber>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="rateOfPay">Rate of Pay</label>
                        <p-inputNumber mode="currency" currency="USD" id="rateOfPay" type="text" formControlName="rateOfPay"></p-inputNumber>
                    </div> 
                    <div class="p-field p-col-12 p-md-2">
                        <label for="totalLOE">Total Loss of Earnings</label>
                        <p-inputNumber mode="currency" currency="USD" id="totalLOE" type="text" formControlName="totalLOE" class="custom-readonly"></p-inputNumber>
                    </div>                                                  
                </div>                       
            </div>                      
            <div class="card"> 
                <h6 class="group-title">Work Schedule: Check the days worked in a Week</h6>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="sun">Sunday</label>
                        <p-checkbox formControlName="sun" inputId="sun"></p-checkbox>                
                    </div>                                                      
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="mon">Monday</label>
                        <p-checkbox formControlName="mon" inputId="mon"></p-checkbox>                
                    </div>                                          
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="tue">Tuesday</label>
                        <p-checkbox formControlName="tue" inputId="tue"></p-checkbox>                
                    </div>                                          
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="wed">Wednesday</label>
                        <p-checkbox formControlName="wed" inputId="wed"></p-checkbox>                
                    </div>                                          
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="thu">Thursday</label>
                        <p-checkbox formControlName="thu" inputId="thu"></p-checkbox>                
                    </div>                                          
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="fri">Friday</label>
                        <p-checkbox formControlName="fri" inputId="fri"></p-checkbox>                
                    </div>                                          
                    <div class="p-field p-col-12 p-md-1" style="display: flex; flex-direction: column;">
                        <label for="sat">Saturday</label>
                        <p-checkbox formControlName="sat" inputId="sat"></p-checkbox>                
                    </div>                                                      
                </div>                       
            </div>  
        </p-tabPanel>
        <p-tabPanel header="Calculations"></p-tabPanel>
    </p-tabView>       
</div>
        