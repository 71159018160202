import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { eventTupleToStore } from '@fullcalendar/core/structs/event-store';
import { AppBreadcrumbService } from '../../../app/app.breadcrumb.service';
import { CasesService } from '../cases.service';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {DialogService} from 'primeng/dynamicdialog';
import { IntakeUseCase } from '../usecase/IntakeUseCase';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ContactsService } from 'src/app/contacts/contacts.service';
import Dates from 'src/app/utilities/Dates';
import { environment } from 'src/environments/environment';
import { BlobStorageService } from 'src/app/cases/blobstorage.service';
import { TabView } from 'primeng/tabview';



@Component({
    selector: 'caseform',
    templateUrl: './caseform.component.html',
    styleUrls: ['./caseform.component.scss'],
})
export class CaseFormComponent implements OnInit {
    mainForm: FormGroup;
    filesToDelete: any[] = [];
    //case: any;
    subscriptions: Subscription[] = [];
    paralegals = [];
    insurancecompanies= [];



    casesType: any[] = [
        {name: 'Auto vs. Auto', value: '1'},
        {name: 'Bike vs. Auto', value: '2'},
        {name: 'Passenger vs. Driver', value: '3'},
        {name: 'Pedestrian vs. Auto', value: '4'},
        {name: 'Uninsured Motorist Coverage', value: '5'},
        {name: 'Under Insured Motorist Coverage', value: '6'}
    ];

    constructor(private location: Location, private route: ActivatedRoute, private router: Router,
        private casesService: CasesService,
        private fb: FormBuilder,
        private intakeUseCase: IntakeUseCase,
        private blobStorageService: BlobStorageService,
        private contactsService: ContactsService) {


        this.mainForm = fb.group({
            id:[],
            status:[],
            openedDate: [],
            statuteDate: [],
            interviewDate:[null, Validators.required],
            closedDate: [],
            paralegal:[null, Validators.required],
            createdBy: [],
            caseType:[null, Validators.required],
        });

        intakeUseCase.caseChange.subscribe(() => {
            this.mainForm.patchValue({
                'id': this.case.id,
                'status': this.case.status,
                'openedDate': this.case.openedDate ? Dates.fromISODateTime(this.case.openedDate) : null,
                'statuteDate': this.case.statuteDate ? Dates.fromISO(this.case.statuteDate) : null,
                'interviewDate': this.case.interviewDate ? Dates.fromISO(this.case.interviewDate) : null,
                'createdBy': this.case.createdBy,
                'paralegal': this.case.paralegal?.id,
                'caseType':this.case.caseType
            }, {emitEvent:false});
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {

        this.route.params.subscribe(event => {
            if(event.caseId) {
                this.intakeUseCase.start(event.caseId);
            } else {
                this.intakeUseCase.start();
            }
        });
        this.mainForm.get("paralegal").valueChanges.subscribe(v => this.case.paralegal = v);
        //this.contactsService.findParalegals(null).then(paralegals => this.paralegals = paralegals);
        this.contactsService.findParalegals(null).then(paralegals => {
            this.paralegals = paralegals.sort((a, b) => {           
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        });
        this.mainForm.get("insurancecompany").valueChanges.subscribe(x => this.case.insuranceClaim.insurer = x);
        this.contactsService.findInsurances(null).then(insurancecompanies => this.insurancecompanies = insurancecompanies);
    }

    get case() : any {
        return this.intakeUseCase.case;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    back() {
        this.location.back()
    }

    store(value?: number) {
        if(this.mainForm.valid) {
            Object.assign(this.intakeUseCase.case, this.mainForm.value);
            if(this.mainForm.value.openedDate) {
                this.intakeUseCase.case.openedDate = Dates.toISODateTime(this.mainForm.value.openedDate);
            } else {
                this.intakeUseCase.case.openedDate = Dates.toISODateTime(new Date());
            }
            if(this.mainForm.value.closedDate) {
                this.intakeUseCase.case.closedDate = Dates.toISODateTime(this.mainForm.value.closedDate);
            }
            if(this.mainForm.value.interviewDate) {
                this.intakeUseCase.case.interviewDate = Dates.toISODateTime(this.mainForm.value.interviewDate);
            }
            // this.intakeUseCase.storeCase().then(() => this.back());
            if(value !== 1) {
                this.intakeUseCase.storeCase().then(() => this.back());
            } else {
                this.intakeUseCase.storeCase();
            }
        }
    }

    cancel() {
        this.back();
    }

    getStatusWord(status: string): string {
        switch (status) {
          case 'T': return 'Treating';
          case 'GBR': return 'Gathering Bills and Records';
          case 'RD': return 'Ready for Demand';
          case 'PD': return 'Pending Demand';
          case 'DS': return 'Demand Sent';
          case 'O': return 'Offer';
          case 'PS': return 'Pending settlement';
          case 'S': return 'Settled';
          case 'L': return 'Litigation';
          case 'PL': return 'Pending Litigation';
          case 'D': return 'Dumped';
          case 'CHA': return 'Changed Attorney';
          default:
            return '';
        }
      }

      getStatuPIP(status: string): string {
        switch (status) {
          case 'Waived': return 'PIP Waived';
          case 'Not Reported': return 'Not Reported Yet';
          case 'Reported': return 'PIP Reported';
          case 'Forms Sent': return 'PIP Forms Sent';
          case 'Payments': return 'PIP Making Payments';
          case 'Exhausted': return 'PIP Exhausted';
          default: return '';
        }
      }

      getStatusLiability(status: string): string {
        switch (status) {
          case 'Pending': return 'Liability Pending';
          case 'Investigating': return 'Liability Investigating';
          case 'Accepted': return 'Liability Accepted';
          case 'Denied': return 'Liability Denied';
          default:
            return '';
        }
      }

      getPlaintiffsHeader(): string {
        const plaintiffCount = this.case.plaintiffs ? this.case.plaintiffs.length : 0;
        return `Plaintiffs (${plaintiffCount})`;
      }

      getAttachedHeader(): string {
        const documentCount = this.case.documents ? this.case.documents.length : 0;
        return `Pictures & Documents (${documentCount})`;
      }

      getInsuranceHeader(): string {
        const InsuranceCount = this.case.insuranceClaims ? this.case.insuranceClaims.length : 0;
        return `Insurances (${InsuranceCount})`;
      }

      getVehicleHeader(): string {
        const VehicleCount = this.case.vehicles ? this.case.vehicles.length : 0 ;
        return `Vehicles (${VehicleCount})`;
      }

      getWitnessHeader(): string {
        const WitnessCount = this.case.witnesses ? this.case.witnesses.length : 0;
        return `Witnesses (${WitnessCount})`;
      }

      getMaxNoteDate(notes: any[]): Date | null {
        if (!notes || notes.length === 0) {
          return null;
        }

        let maxDate = new Date(0);
        notes.forEach(note => {
          if (!note || !note.noteCreatedOn) {
            return; // Skip this iteration if the note is null/undefined or noteCreatedOn is not present
          }
          const noteDate = new Date(note.noteCreatedOn);
          if (noteDate > maxDate) {
            maxDate = noteDate;
          }
        });        

        return maxDate;
      }

      getMaxInsuranceNoteDate(notes: any[]): Date | null {
        if (!notes || notes.length === 0) {
          return null;
        }

        let maxDate = new Date(0);

        notes.forEach(note => {
          const noteDate = new Date(note.noteCreatedOn);
          if (noteDate > maxDate) {
            maxDate = noteDate;
          }
        });

        return maxDate;
      }

      isWithin30Days(date: Date): boolean {
        const now = new Date();
        const deadline = new Date(date);
        const differenceInDays = (deadline.getTime() - now.getTime()) / (1000 * 3600 * 24);
        return differenceInDays <= 30;
    }


    editAccident() {
        this.intakeUseCase.editAccident(this.case.accident);
    }

    editPlaintiffcar() {
        this.intakeUseCase.editPlaintiffcar(this.case.plaintiffCar);
    }

    addPlaintiff() {
        this.intakeUseCase.addPlaintiff();
    }

    editPlaintiff(plaintiff) {
        this.intakeUseCase.editPlaintiff(plaintiff);
    }

    deletePlaintiff(plaintiff) {
        this.remove(this.case.plaintiffs, plaintiff);
    }

    addDefendant() {
        this.intakeUseCase.addDefendant();
    }

    editDefendant(defendant) {
        this.intakeUseCase.editDefendant(defendant);
    }

    deleteDefendant(defendant) {
        this.remove(this.case.defendants, defendant);
    }

    addWitness() {
        this.intakeUseCase.addWitness();
    }

    editWitness(witness) {
        this.intakeUseCase.editWitness(witness);
    }

    deleteWitness(witness) {
        this.remove(this.case.witnesses, witness);
    }

    addVehicle() {
        this.intakeUseCase.addVehicle();
    }

    editVehicle(vehicle) {
        this.intakeUseCase.editVehicle(vehicle);
    }

    deleteVehicle(vehicle) {
        this.remove(this.case.Vehicles, vehicle);
    }

    addDocuments() {
        this.intakeUseCase.addDocument();
    }

    editDocument(document) {
        this.intakeUseCase.editDocument(document);
    }

    autoNote(plaintiff) {
        this.intakeUseCase.editPlaintiff(plaintiff);
        this.intakeUseCase.addNote();
    }



    // deleteDocument(document) {
    //     return environment.clientsEndpointUrl + "/blobs/public/gs/" + document.fileInfo.uri.replace("gs://", "");
    //     // if (this.mainForm.valid) {
    //     //   Promise
    //     //     .all(this.filesToDelete.map(file => this.blobStorageService.deleteBlob(document.uri)))
    //     //     .then(() => {
    //     //       // Realizar acciones adicionales después de eliminar los archivos
    //     //       this.remove(this.case.documents, document);
    //     //     })
    //     //     .catch(error => {
    //     //       // Manejar el error en caso de fallo en la eliminación de los archivos
    //     //     });
    //     // }
    //   }

    addInsurance() {
        this.intakeUseCase.addInsurance();
    }

    editInsurance(Insurance) {
        this.intakeUseCase.editInsurance(Insurance);
    }

    remove(arr: any[], element) {
        arr.splice(arr.indexOf(element), 1);
    }

    getAccidentString(accident: any) : string {
        if(accident && accident.date) {
            let date = moment(accident.date);
            return date.format('dddd DD MMM YYYY') + ', ' + accident.address.street+ ', ' + accident.address.city + ', ' + accident.address.county + ', ' + accident.address.state;
        } else {
            return '';
        }
    }

    getInsuranceName(insurance:any): string {
        if(insurance) {
            return insurance.insuranceCompanyName;
        } else {
            return '';
        }
    }

    getAge(dob: string) {
        return Dates.getAge(dob);
    }

    getDDiff(filingDeadline: Date) {
        return Dates.getDDiff(filingDeadline);

    }

    getCarString(plaintiffCar: any) : string {
         if(plaintiffCar.car.tagNo && plaintiffCar.car.carColor) {
            return  'Vehicle Information: ' + plaintiffCar.car.tagNo+ ', ' + plaintiffCar.car.carColor;
         } else {
             return '';
         }
    }

    calculateTotalLOE(plaintiff: any): number {
        let totalLOE = 0;
        if (plaintiff.loes && plaintiff.loes.length > 0) {
          for (const loe of plaintiff.loes) {
            totalLOE += loe.totalLOE;
          }
        }
        return totalLOE;
      }

    getBlobUrl(document) {
        return environment.clientsEndpointUrl + "/blobs/public/gs/" + document.fileInfo.uri.replace("gs://", "");
    }

    printRetainer(plaintiff) {
        window.open(environment.clientsEndpointUrl + "/reports/Retainer.jrxml?plaintiffId=" + plaintiff.id, "_blank");
        window.open(environment.clientsEndpointUrl + "/reports/ecFLetter.jrxml?plaintiffId=" + plaintiff.id, "_blank");
    }

    printDefendant(plaintiff) {
        window.open(environment.clientsEndpointUrl + "/reports/Retainer.jrxml?plaintiffId=" + plaintiff.id, "_blank");
        window.open(environment.clientsEndpointUrl + "/reports/ecFLetter.jrxml?plaintiffId=" + plaintiff.id, "_blank");
    }

}
