import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { UserSessionService } from './UserSessionService';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userSessionService: UserSessionService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = this.userSessionService.getToken();
        if (token && !this.isDomainExcluded(request) && !this.isCorsPreflightRequest(request)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }

    isCorsPreflightRequest(request: HttpRequest<any>) : boolean {
        return request.method.toUpperCase() == 'OPTIONS';
    }

    isDomainExcluded(request: HttpRequest<any>): boolean {
        return false;
    }
}
