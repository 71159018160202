<div [formGroup]="mainForm">   
    <!-- <div class="card"> 
        <h5>Identification Documents</h5> -->
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"(click)="cancel()"></button>  
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

    <person formGroupName="person"></person>
    <br>
    <!-- <p-fieldset legend="Click for more personal information..." [toggleable]="true" collapsed="true">
        
     
    </p-fieldset> -->
    <!-- </div>   -->    

    <br><br>
    <p-tabView #tabViewRef [style]="{'height':'600px'}">   
        <p-tabPanel header="1.Personal Information">
            <div formGroupName="person" class="p-fluid p-formgrid p-grid">                
                <div class="p-field p-col-12 p-md-2">
                    <label for="phoneNumber">Mobile phone</label>
                    <p-inputMask id="phoneNumber" mask="(999) 999-9999"  formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                    <!-- unmask="true" -->
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="messagesTo">Messages to</label>
                    <span class="p-float-label">
                        <p-dropdown id="messagesTo" formControlName="messagesTo" [options]="lmessagesTo" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="emailAddress">Email</label>
                    <input id="emailAddress" type="text" pInputText formControlName="emailAddress" autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="erContactRelation">Contact Relationship</label>
                    <span class="p-float-label">
                        <p-dropdown id="erContactRelation" formControlName="erContactRelation" [options]="econtactRel" optionValue="value" optionLabel="name"                  
                        pTooltip="Emergency Contact Relationship" 
                        tooltipPosition="top"
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="erContactName">Contact</label>
                    <input id="erContactName" type="text" pInputText formControlName="erContactName" 
                    pTooltip="Emergency Contact (Other than Spouse)" 
                    tooltipPosition="top"
                    autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="optionalPhoneNumber">ER phone</label>
                    <p-inputMask id="optionalPhoneNumber" mask="(999) 999-9999" formControlName="optionalPhoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                </div>                
            </div>
            
            <div formGroupName="person" class="p-grid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="taxDocType">SSN/Tax Id</label>
                    <p-dropdown inputId="taxDocType" [options]="taxDocTypes" formControlName="taxDocType" optionLabel="name" optionValue="value"  autoDisplayFirst="false"
                    filter="true" placeholder="Choose"></p-dropdown>
                </div>
    
                <div class="p-field p-col-12 p-md-2">
                    <label for="idNumber">Id No.</label>                        
                    <p-inputMask id="idNumber" mask="999-99-9999" formControlName="idNumber" placeholder="999-99-9999">
                    </p-inputMask>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="driverLicenseNo">Driver License Number</label>
                    <p-inputMask id="driverLicenseNo"  mask="*?**-***-***-***-***-***" formControlName="driverLicenseNo"></p-inputMask>                
                </div>
                <div class="p-field p-col-12 p-md-1">
                    <label for="licenseState">State</label>
                    <input id="licenseState" type="text" pInputText formControlName="licenseState" 
                    pTooltip="Specify the State where DL was issued" 
                    tooltipPosition="top"
                    autocomplete="doNotAutoComplete">
                </div>
    
                <div class="p-field p-col-12 p-md-2">
                    <label for="personalIdType">Other Id Types</label>
                    <span class="p-float-label">
                        <p-dropdown id="personalIdType" formControlName="personalIdType" [options]="personalIdTypes" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>
                
                <div class="p-field p-col-12 p-md-3">
                    <label for="personalIdNo">Personal Id Number</label>
                    <p-inputMask id="personalIdNo"  mask="*?**-***-***-***-***-***" formControlName="personalIdNo"></p-inputMask>                
                </div>
            </div> 
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="maritalStatus">Marital Status</label>
                    <span class="p-float-label">
                        <p-dropdown id="maritalStatus" formControlName="maritalStatus" [options]="maritalStatus" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>                
                <div class="p-field p-col-12 p-md-2">
                    <label for="spouseName">Full Name</label>
                    <input id="spouseName" type="text" pInputText formControlName="spouseName" autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="spousePhoneNumber">Phone</label>
                    <p-inputMask id="spousePhoneNumber" mask="(999) 999-9999" formControlName="spousePhoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="spouseEmail">Email</label>
                    <input id="spouseEmail" type="text" pInputText formControlName="spouseEmail" autocomplete="doNotAutoComplete">
                </div> 
                <div class="p-field p-col-12 p-md-2">
                    <label style="color:red" for="referenceNo"><b>Reference No.</b></label>
                    <p-inputMask id="referenceNo" mask="9?9999"  formControlName="referenceNo" autocomplete="doNotAutoComplete" [readonly]="plaintiff.referenceNo != null"></p-inputMask>
                    <!-- unmask="true" -->
                </div> 
                <div class="p-field p-col-12 p-md-2">
                    <label for="orderLetter">Reference Letter</label>
                    <span class="p-float-label">
                        <p-dropdown id="orderLetter" formControlName="orderLetter" [options]="orderLetter" optionValue="value" optionLabel="name" [readonly]="plaintiff.referenceNo != null"
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>   
                
                    <div class="p-field p-col-12 p-md-2" formGroupName="person">
                        <label for="language"><label style="color:red">*</label> Native speaker</label>
                        <span class="p-float-label">
                            <p-dropdown id="language" formControlName="language" [options]="languages" 
                                optionLabel="name" optionValue="id" [autoDisplayFirst]="false" placeholder="Choose">
                            </p-dropdown>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-2" formGroupName="person">
                        <label for="languageLevel"><label style="color:red">*</label> English Level</label>
                        <span class="p-float-label">
                            <p-dropdown id="languageLevel" formControlName="languageLevel" [options]="languageLevels" 
                            optionValue="name" optionLabel="label"  [autoDisplayFirst]="false" placeholder="Choose">
                            </p-dropdown>
                        </span> 
                    </div>
                </div>                 
        </p-tabPanel>    
        <p-tabPanel header="2. Status Notes">    
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="plaintiffStatus">Status</label>
                        <p-dropdown formControlName="plaintiffStatus" [options]="plaintiffStatuses" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div> 
                    <div class="p-field p-col-12 p-md-3">
                        <label for="statusChangeDate">Last Status Changed On</label>
                        <p-calendar id="statusChangeDate"showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="statusChangeDate" disabled="true"></p-calendar>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-2">
                        <label for="status">Status</label>
                        <input id="status" type="text" pInputText formControlName="status" readonly autocomplete="doNotAutoComplete">
                    </div>    -->
                    <!-- <label>To change from  Treating to Gathering Bills and Records, each medical provider has to be changed from "Treating" to "Release from Treatement"</label> -->
                    <!-- <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1">
                        <button pButton pRipple label="Treating" icon="pi pi-calendar-plus" class="p-button-text p-button-rounded"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1" style="width: 18%;">
                        <button pButton pRipple label="Gathering Bills and Records" icon="pi pi-search" class="p-button-text p-button-rounded"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1" style="width: 12%;">
                        <button pButton pRipple label="Demand Sent" icon="pi pi-thumbs-up" class="p-button-success p-button-text"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1">
                        <button pButton pRipple label="Dumped" icon="pi pi-thumbs-down" class="p-button-danger p-button-text"></button>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-col-2 p-md-1" style="width: 15%;">
                        <button pButton pRipple label="Changed Attorney" icon="pi pi-times" iconPos="center" class="p-button-danger p-button-text"></button>
                    </div> -->
                </div>                                
            </div>
            <p-table #dtc [value]="statusPlaintiffNotes" dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Created By</th>
                            <th pSortableColumn="eventDate">Event Date
                                <p-sortIcon field="eventDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="noteCreatedOn">Created On
                                <p-sortIcon field="noteCreatedOn"></p-sortIcon>
                            </th>
                            <th>Notes</th>
                            <th>
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addNote()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="note">
                            <td>{{object.username}}</td>    
                            <td>{{object.eventDate | date:'short'}}</td>
                            <td>{{object.noteCreatedOn | date:'short'}}</td> 
                            <!-- <td>{{(object.comments | slice:0:100)+'...'}}</td> -->
                            <td [pTooltip]="object.comments" tooltipPosition="top">{{(object.comments.length>100)? (object.comments | slice:0:100)+'...':(object.comments)}}</td>

                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="editNote(object)"></button>
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{statusPlaintiffNotes ? statusPlaintiffNotes.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No notes found.</td>
                        </tr>
                    </ng-template>
            </p-table>            
        </p-tabPanel>
        <p-tabPanel header="3. Medical Providers">
            <p-table #dtc dataKey="id" selectionMode="single"
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>                            
                            <th pSortableColumn="name">Provider Name
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Total Spent
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Paid
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Balance
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Lien
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th>
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addDocuments()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="client">
                            <td>
                                
                            </td>
                            <td>
                                
                            </td>

                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="edit(object)"></button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{objects ? objects.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No providers found.</td>
                        </tr>
                    </ng-template>
                </p-table>
        </p-tabPanel>
        <p-tabPanel header="4. LOE"> 
            <!-- <div class="p-field p-col-12 p-md-2">                                                    
                <h6>Will not claim for Lost Wages</h6>
               <p-inputSwitch formControlName="notClaim"></p-inputSwitch>
            </div>      -->
            <div class="p-grid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="notClaim">Any Loss of Earnings?</label>
                            <span class="p-float-label">
                                <p-dropdown id="notClaim" formControlName="notClaim" [options]="hasInsurancev" optionValue="value" optionLabel="name"                  
                                autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                            </span>           
                </div>
            </div> 
            <p-table #dtc [value]="loes" dataKey="id" selectionMode="single"
            styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
            [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
            <ng-template pTemplate="header">
                <tr>                            
                    <th pSortableColumn="name">Employer
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name">Job Title
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name">Rate of Pay
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name">Days Absent
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name">Total LOE
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>                    
                    <th>
                        <p-button type="button" label="Add Loss of Earnings" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text"
                            (click)="addLoe()"
                            [disabled]="!mainForm.get('notClaim').value"></p-button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-object>
                <tr [pSelectableRow]="client">
                    <td>{{object.companyName}} <strong style="color: red;">* Authorized By: {{object.authorizedBy}}</strong>, {{object.title}}</td>
                    <td>{{object.jobTitle}}</td>
                    <td>{{object.rateOfPay | currency: 'USD' }}</td>
                    <td>{{object.daysAbsent}}</td>
                    <td>{{ object.totalLOE | currency: 'USD' }}</td>
                    <td>
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-success p-mr-2" (click)="editLoe(object)"></button>
                        <!-- <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    Total of {{objects ? objects.length : 0 }}.
                </div>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No LOE found.</td>
                </tr>
            </ng-template>
        </p-table>
        </p-tabPanel>
        <p-tabPanel header="5. Disability Slips">                                    
            <p-table #dtc [value]="disabilities" dataKey="id" selectionMode="single"
            styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
            [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
            <ng-template pTemplate="header">
                <tr>                            
                    <th pSortableColumn="name">Medical Provider
                       <!-- <p-sortIcon field="name"></p-sortIcon> -->
                    </th>
                    <th pSortableColumn="name">From
                       <!-- <p-sortIcon field="name"></p-sortIcon> -->
                    </th>
                    <th pSortableColumn="name">To
                        <!--<p-sortIcon field="name"></p-sortIcon> -->
                    </th>
                    <th pSortableColumn="name">Days Excused
                        <!--<p-sortIcon field="name"></p-sortIcon> -->
                    </th>                    
                    <th>
                        <p-button type="button" label="Add Time Frame" icon="pi pi-plus" styleClass="p-button-rounded p-button-raised p-button-info p-button-text"
                            (click)="addDisability()"
                            [disabled]="!mainForm.get('notClaim').value"></p-button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-object>
                <tr [pSelectableRow]="client">
                    <td></td>
                    <td>{{object.startDate| date:'MMM EEE dd, yyyy'}}</td>
                    <td>{{object.endDate| date:'MMM EEE dd, yyyy'}}</td>
                    <td>{{object.daysAbsent}}</td>                    
                    <td>
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-success p-mr-2" (click)="editDisability(object)"></button>
                        <!-- <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    Total of {{objects ? objects.length : 0 }}.
                </div>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No disability period found yet.</td>
                </tr>
            </ng-template>
        </p-table>
        </p-tabPanel>
        <p-tabPanel header="6. PIP Criteria">                                    
        </p-tabPanel>
        
        <p-tabPanel header="7. Complaints">    
            <div class="p-grid p-formgrid p-fluid">               
                <div class="p-field p-col-12 p-md-3">
                    <label for="pregnant">Pregnant at the time of the accident</label>
                    <span class="p-float-label">
                        <p-dropdown id="pregnant" formControlName="pregnant" [options]="yesno" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>        
                <div class="p-field p-col-12 p-md-2">
                    <label for="weeksPregnant">Weeks Pregnant (max 40)</label>
                    <p-inputNumber mode="decimal" [min]="0" [max]="40" currency="USD" id="weeksPregnant" type="text" formControlName="weeksPregnant"></p-inputNumber>
                </div>               
            </div>

            <p-table #dtc [value]="complaints" dataKey="id" selectionMode="single" 
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
            [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Position</th>
                    <th>Part of the Body</th>                    
                    <th>Symptom</th>
                    <th>Hit Car?</th>
                    <th>
                        <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                            (click)="addComplaint()"></p-button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-object>
                <tr [pSelectableRow]="complaints">
                    <td>{{object.position}}</td>    
                    <td>{{object.complaint?.name}}</td>                                            
                    <td>{{object.symptom?.name}}</td>
                    <td>{{object.hitCar}}</td>                
                    <td>
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-success p-mr-2" (click)="editComplaint(object)"></button>
                        <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-success p-mr-2" (click)="deleteComplaint(object)"></button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    Total of {{complaints ? complaints.length : 0 }}.
                </div>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No complaints found yet.</td>
                </tr>
            </ng-template>
        </p-table>   
        </p-tabPanel>

        <!-- <p-tabPanel header="8. Policy Coverages" formGroupName="policy">            
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="policyNumber">Policy Number</label>
                    <p-inputMask mask="*?**-***-***-***-*" formControlName="policyNumber"></p-inputMask>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="biEachPerson">BI each person</label>
                    <p-inputNumber mode="currency" currency="USD" id="biEachPerson" type="text" formControlName="biEachPerson"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="biEachOccurrence">BI each occurrence</label>
                    <p-inputNumber mode="currency" currency="USD" id="biEachOccurrence" type="text" formControlName="biEachOccurrence"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="propertyDamageLiability">PD Liability</label>
                    <p-inputNumber mode="currency" currency="USD" id="propertyDamageLiability" type="text" formControlName="propertyDamageLiability"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umBIEachPerson">UM BI each person</label>
                    <p-inputNumber mode="currency" currency="USD" id="umBIEachPerson" type="text" formControlName="umBIEachPerson"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umBIEachOccurrence">UM BI each occurrence</label>
                    <p-inputNumber mode="currency" currency="USD" id="umBIEachOccurrence" type="text" formControlName="umBIEachOccurrence"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umPDDeductible">UM PD Deductible</label>
                    <p-inputNumber mode="currency" currency="USD" id="umPDDeductible" type="text" formControlName="umPDDeductible"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umPD">UM PD</label>
                    <p-inputNumber mode="currency" currency="USD" id="umPD" type="text" formControlName="umPD"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="umCollision">UM Collision</label>
                    <p-inputNumber mode="currency" currency="USD" id="umCollision" type="text" formControlName="umCollision"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="gapInsurance">Gap Insurance</label>
                    <p-inputNumber mode="currency" currency="USD" id="gapInsurance" type="text" formControlName="gapInsurance"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="rentalInsurance">Rental Coverage</label>
                    <p-inputNumber mode="currency" currency="USD" id="rentalInsurance" type="text" formControlName="rentalInsurance"></p-inputNumber>
                </div>
            </div>
        </p-tabPanel>                -->
        <p-tabPanel header="8. Prior accidents">
            <div class="p-grid p-formgrid p-fluid">
               
                        <label for="priorAccidents">Specify prior accidents</label>
                        <textarea [rows]="5" [cols]="30" pInputTextarea
                            formControlName="priorAccidents" maxlength="1000"></textarea>          
                            <label *ngIf="mainForm.get('priorAccidents')?.value">{{ 1000 - mainForm.get('priorAccidents').value.length}}/1000</label>                                
                   
            </div>
        </p-tabPanel>
        <p-tabPanel header="9. Health Insurance">
            <div class="p-grid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="hasInsurance">Has Health Insurance</label>
                            <span class="p-float-label">
                                <p-dropdown id="hasInsurance" formControlName="hasInsurance" [options]="hasInsurancev" optionValue="value" optionLabel="name"                  
                                autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                            </span>           
                </div>
            </div> 
            <div class="p-grid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="medicareHolder">Medicare Holder?</label>
                    <span class="p-float-label">
                        <p-dropdown id="medicareHolder" formControlName="medicareHolder" [options]="yesno" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose" [disabled]="!mainForm.get('hasInsurance').value"></p-dropdown>   
                        <!-- [readonly]="liability.id != null"                  -->
                    </span>
                </div>

                <div class="p-field p-col-12 p-md-2">
                    <label>Medicare Number</label>
                    <p-inputMask id="medicareNumber" mask="9a*9-a*9-aa99" formControlName="medicareNumber" [disabled]="!mainForm.get('hasInsurance').value" autocomplete="doNotAutoComplete"></p-inputMask>
                </div>

                <div class="p-field p-col-12 p-md-1">
                    <!-- <label for="entitleTo">Entitle To</label>
                    <span class="p-float-label">
                        <p-dropdown id="entitleTo" formControlName="entitleTo" [options]="entitleTo" optionValue="value" optionLabel="name"                  
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span> -->
                </div>                 
                <p-divider layout="vertical"></p-divider>
                <div class="p-field p-col-12 p-md-2">
                    <label for="iCompany">Health Insurance Company</label>
                    <input id="iCompany" type="text" pInputText formControlName="healthInsurance" 
                    [readonly]="!mainForm.get('hasInsurance').value"  
                    [value]="!mainForm.get('hasInsurance').value ? '' : mainForm.get('healthInsurance').value" 
                    autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label>Policy Number</label>
                    <p-inputMask id="healthInsurancePolicy" mask="****-***-****" formControlName="healthInsurancePolicy" autocomplete="doNotAutoComplete" ></p-inputMask>
                </div>
                <div class="p-field p-col-12 p-md-2">                                                    
                    <h6>Medicaid?</h6>
                   <p-inputSwitch formControlName="medicaid" [disabled]="!mainForm.get('hasInsurance').value"></p-inputSwitch>
               </div>
               <label for="specify"><span class="label success"><strong>Make copy of Health Insurance/Medicaid Card</strong></span></label>
            </div> 
        </p-tabPanel>

        <!-- Litigation Process -->
        <!-- <p-tabPanel header="10. Language">        
            <div class="p-grid p-formgrid p-fluid" formGroupName="person">
                <div class="p-field p-col-12 p-md-2">
                    <label for="language">Native speaker</label>
                    <span class="p-float-label">
                        <p-dropdown id="language" formControlName="language" [options]="languages" 
                            optionLabel="name" optionValue="id" [autoDisplayFirst]="false" placeholder="Choose">
                        </p-dropdown>
                    </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="languageLevel">English Level</label>
                    <span class="p-float-label">
                        <p-dropdown id="languageLevel" formControlName="languageLevel" [options]="languageLevels" 
                        optionValue="name" optionLabel="label"  [autoDisplayFirst]="false" placeholder="Choose">
                        </p-dropdown>
                    </span> 
                </div>
            </div>    
        </p-tabPanel> -->

         <p-tabPanel header="10. Litigation Process">
            
            
        </p-tabPanel> 

    </p-tabView>    
</div>

<!-- <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid"(click)="store()"></button> -->