import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class CasesService {
    constructor(private http: HttpClient){    }

    store(caseGI) : Promise<any> 
    {
        return this.http.post<any>(environment.clientsEndpointUrl + '/cases', caseGI).toPromise();
    }

    findAll(search: any): Promise<any[]>  
    {
        return this.http.get<any>(environment.clientsEndpointUrl + '/cases', {params: search}).toPromise();
    }

    findMyCases(search: any): Promise<any[]>  
    {
        return this.http.get<any>(environment.clientsEndpointUrl + '/cases/mine', {params: search}).toPromise();
    }

    findById(id) : Promise<any> 
    {
        return this.http.get<any>(environment.clientsEndpointUrl + '/cases/' + id).toPromise().then(_case  =>
            {
            _case.plaintiffs = _case.plaintiffs || [];
            _case.plaintiffs.forEach(plaintiff => {
                plaintiff.complaints = plaintiff.complaints || [];
                plaintiff.statusPlaintiffNotes = plaintiff.statusPlaintiffNotes || [];
                plaintiff.loes=plaintiff.loes|| [];
                plaintiff.disabilities=plaintiff.disabilities|| [];
            });
            _case.defendants = _case.defendants || [];
            _case.defendants.forEach(defendant => {
                defendant.liabilities = defendant.liabilities || [];
            });
            _case.insuranceClaims = _case.insuranceClaims || [];
            _case.insuranceClaims.forEach(insuranceClaim => {
                insuranceClaim.statusClaimNotes = insuranceClaim.statusClaimNotes || [];
                insuranceClaim.adjusters = insuranceClaim.adjusters || [];
                insuranceClaim.defendants = insuranceClaim.defendants || [];
            });
            _case.witnesses = _case.witnesses || [];
            _case.vehicles = _case.vehicles || [];
            _case.vehicles.forEach(vehicle => {
                vehicle.carexpenses = vehicle.carexpenses || [];
                vehicle.vehicleStatusNotes=vehicle.vehicleStatusNotes|| [];
            });
            _case.documents = _case.documents || [];
            return _case;
        });
    }

    deleteById(id) : Promise<any> 
    {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/cases/' + id).toPromise();
    }


    policeReportWillNotBeCompleted(caseId) 
    {
        return this.http.post<any>(environment.clientsEndpointUrl + '/cases/' + caseId + '/policeReportWillNotBeCompleted', null).toPromise();
    }

    policeReportReceived(caseId) 
    {
        return this.http.post<any>(environment.clientsEndpointUrl + '/cases/' + caseId + '/policeReportReceived', null).toPromise();
    }

    investigating(claimId) 
    {
        return this.http.post<any>(environment.clientsEndpointUrl + '/cases/' + claimId + '/investigating', null).toPromise();
    }
}
