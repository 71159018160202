import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { eventTupleToStore } from '@fullcalendar/core/structs/event-store';
import { AppBreadcrumbService } from '../../../app/app.breadcrumb.service';
import { ProvidersService } from '../providers.service';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {DialogService} from 'primeng/dynamicdialog';
import { HealthCareUseCase } from '../usecase/HealthCareUseCase';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ContactsService } from 'src/app/contacts/contacts.service';
import Dates from 'src/app/utilities/Dates';
import { environment } from 'src/environments/environment';
import { BlobStorageService } from 'src/app/medicalproviders/blobstorage.service';
import { TabView } from 'primeng/tabview';



@Component({
    selector: 'providerform',
    templateUrl: './providerform.component.html',
    styleUrls: ['./providerform.component.scss'],
})
export class ProviderFormComponent implements OnInit {
    mainForm: FormGroup;    
    formGroup: FormGroup;
    searchForm: FormGroup;
    loading = false;
    subscriptions: Subscription[] = [];
    careGroupList: any[] = [];
    results: any;
    activeIndex = 0;
    maxResults = 5;
    search = {
        from: 0,
        to: this.maxResults,
        term: null
    }

    types: any[] = [
        {name: 'Hospital', value: 'H'},
        {name: 'Physician Group', value: 'PG'},
        {name: 'Imaging', value: 'I'},
        {name: 'Collection Agency', value: 'CA'},
        {name: 'Chiropractic', value: 'CH'},
        {name: 'Physical Therapy', value: 'PT'},
        {name: 'Orthopedic', value: 'OR'},
        {name: 'Urgent Care', value: 'UC'},
        {name: 'Hospital Network', value: 'CG'},
        {name: 'Copy Services', value: 'CS'},
        {name: 'EMS', value: 'EM'}
    ];

    statuses: any[] = [
        {name: 'Active', value: 1},
        {name: 'Temporarily Closed', value: 2},
        {name: 'Closed', value: 3}        
    ];


    constructor(private location: Location, private route: ActivatedRoute, private router: Router,
        private providersService: ProvidersService,
        private fb: FormBuilder,
        private healthCareUseCase: HealthCareUseCase,
        private blobStorageService: BlobStorageService,
        private contactsService: ContactsService) {


        this.mainForm = fb.group({
            id:[],
            address: fb.group({
                country: [],
                street:[],                
                city:[],
                state:[],
                zipcode:[],
            }),
            creationDate:[],
            createdBy: [],
            careGroup: [null],
            acceptLiens: [],
            email: [],
            status:[],
            taxId:[],
            providerName:[null, Validators.required],
            acronym:[],
            aka:[],
            phoneNumber:[],
            faxNumber:[],
            type:[],
            website:[],
        });

        healthCareUseCase.providerChange.subscribe(() => {
            this.mainForm.patchValue({
                'id': this.provider.id,
                'creationDate': this.provider.creationDate ? Dates.fromISODateTime(this.provider.creationDate) : null,
                'createdBy': this.provider.createdBy,
                'careGroup': this.provider.careGroup,
                'acceptLiens': this.provider.acceptLiens,
                'email': this.provider.email,
                'status': this.provider.status,
                'taxId': this.provider.taxId,
                'providerName': this.provider.providerName,
                'acronym': this.provider.acronym,
                'aka': this.provider.aka,
                'address': this.provider.address,
                /*Missing the address, will have to add it later */
                'phoneNumber': this.provider.phoneNumber,
                'faxNumber': this.provider.faxNumber,                                            
                'type':this.provider.type,
                'website':this.provider.website
            }, {emitEvent:false});
        });
    }

    
    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() 
    {
        this.route.params.subscribe(event => 
            {
                if(event.providerId) {
                    this.healthCareUseCase.start(event.providerId);
                } else {
                    this.healthCareUseCase.start();
                }
            });                    
            this.loadCareGroups(); 
            this.sortTypes();
    }

    get total(): number {
        return this.results && this.results.total || 0;
    }

    get objects(): any[] {
        return this.results && this.results.data || [];
    }

    load() 
    {
        this.search.term = this.searchForm.value.term || "";

        this.loading = true;
        if (this.activeIndex == 0) {
            this.providersService.findAll(this.search).then(results => this.results = results).then(() => this.loading = false);
        }                        
    }

    loadCareGroups() {
        this.providersService.getCareGroups().then(careGroups => {
            this.careGroupList = careGroups.map(group => ({
                name: group.providerName,
                value: group.id
            }));
        });
    }

    sortTypes() {
        this.types.sort((a, b) => a.name.localeCompare(b.name));
      }

    get provider() : any {
        return this.healthCareUseCase.provider;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    back() {
        this.location.back()
    }

    store(value?: number) {
        if(this.mainForm.valid) {
            Object.assign(this.healthCareUseCase.provider, this.mainForm.value);
            if(this.mainForm.value.creationDate) {
                const formattedDate = Dates.toISODateTime(new Date(this.mainForm.value.creationDate));
                this.healthCareUseCase.provider.creationDate = formattedDate;
                //console.log('Formatted Date:', formattedDate); // Debugging line
                
                //this.healthCareUseCase.provider.creationDate = Dates.toISODateTime(this.mainForm.value.creationDate);
            } 
            else 
            {
                const currentDate = Dates.toISODateTime(new Date());
                this.healthCareUseCase.provider.creationDate = currentDate;
                //console.log('Current Date:', currentDate); // Debugging line
                //this.healthCareUseCase.provider.creationDate = Dates.toISODateTime(new Date());
            }                                    

            if(value !== 1) {
                this.healthCareUseCase.storeProvider().then(() => this.back());
                //console.log('value !== 1'); // Debugging line
                const currentDate = Dates.toISODateTime(new Date());
                this.healthCareUseCase.provider.creationDate = currentDate;
                //console.log('Current Date value !== 1:', currentDate); // Debugging line
            } else {
                //console.log('else value !== 1'); // Debugging line
                this.healthCareUseCase.storeProvider();
            }
        }
    }

    /*Locations*/
    addLocation() {
        this.healthCareUseCase.addLocation();
    }

    editLocation(Location) {
        this.healthCareUseCase.editLocation(Location);
    }

    /*Records*/
    addDepartment() {
        this.healthCareUseCase.addDepartment();
    }

    editDepartment(Department) {
        this.healthCareUseCase.editDepartment(Department);
    }

    get addressEnabled() : boolean {
        return this.mainForm.get('address') != null;
    }

     /*Note*/
     addNote() {
        this.healthCareUseCase.addNote();
    }

    editNote(note) {
        this.healthCareUseCase.editNote(note);
    }

    /*Provider Documents*/
    addDocuments() {
        this.healthCareUseCase.addDocument();
    }

    editDocument(document) {
        this.healthCareUseCase.editDocument(document);
    }

    cancel() {
        this.back();
    }

    getBlobUrl(document) {
        return environment.clientsEndpointUrl + "/healthcareproviderblobs/public/gs/" + document.fileInfo.uri.replace("gs://", "");
    }
    getProviderTypeName(typeValue: string): string {
        const type = this.types.find(t => t.value === typeValue);
        return type ? type.name : typeValue;
    }

    /*Outsourcing*/
    addOutsourcing() {
        this.healthCareUseCase.addOutsourcing();
    }

    editOutsourcing(outsourcing) {
        this.healthCareUseCase.editOutsourcing(outsourcing);
    }

    getDepartmentsHeader(): string {
        const departmentCount = this.provider.departments ? this.provider.departments.length : 0;
        return `1. Facility HIM Departments (${departmentCount})`;
      }

    getOutsourcingsHeader(): string {
        const outsourcingCount = this.provider.outsourcings ? this.provider.outsourcings.length : 0;
        return `2. Facility Outsourcing Providers (${outsourcingCount})`;
      }

    getBranchesHeader(): string {
        const branchCount = this.provider.branches ? this.provider.branches.length : 0;
        return `3. Branches (${branchCount})`;
      }

    getNotesHeader(): string {
        const noteCount = this.provider.providerNotes ? this.provider.providerNotes.length : 0;
        return `4. Notes (${noteCount})`;
      }

    getDocsHeader(): string {
        const docCount = this.provider.docs ? this.provider.docs.length : 0;
        return `5. Docs (${docCount})`;
      }

}