<div [formGroup]="formGroup">
    <div class="card" style="background-color: powderblue;">
        <h5>Information</h5>
        <div formGroupName="car" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-4">
                <label for="tagNo">Tag No</label>
                <input id="tagNo" type="text" pInputText formControlName="tagNo">
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="carColor">Color</label>
                <input id="carColor" type="text" pInputText formControlName="carColor">
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="carYear">Year</label>
                <p-inputMask formControlName="carYear" mask="9999"></p-inputMask>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-4">
                <label for="towed">Was Vehicle Towed?</label>
                <p-dropdown formControlName="towed" [options]="yesno" optionLabel="name" optionValue="towed"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="towing_amount">Towing Amount</label>
                <p-inputNumber mode="currency" currency="USD" id="towing_amount" type="text"
                    formControlName="towing_amount"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="towed">Is Vehicle Drivable?</label>
                <p-dropdown formControlName="drivable" [options]="yesno" optionLabel="name" optionValue="drivable"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
        </div>
    </div>
    <div class="card">
        <h5>Location</h5>
        <div class="p-fluid p-formgrid p-grid">
            <address formGroupName="address"></address>
        </div>
    </div>
    <div class="card">
        <h5>Ownership</h5>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-4">
                <label for="driver">Driver</label>
                <p-dropdown formControlName="driver" [options]="yesno" optionLabel="name" optionValue="value"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="owner">Owner</label>
                <p-dropdown formControlName="owner" [options]="yesno" optionLabel="name" optionValue="value"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
        </div>
    </div>

    <!-- <p-tabView >
            <p-tabPanel header="Repairable Costs" formGroupName="repairable">
            </p-tabPanel>
            <p-tabPanel header="Total Loss Costs" formGroupName="total_loss">
            </p-tabPanel>
        </p-tabView> -->

    <div class="card">
        <h5>If Repairable</h5>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="towed">Is Car Repairable?</label>
                <p-dropdown formControlName="repairable" [options]="yesno" optionLabel="name" optionValue="repairable"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="property_damage_amount">PD Amount</label>
                <p-inputNumber mode="currency" currency="USD" id="property_damage_amount" type="text"
                    formControlName="property_damage_amount"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="towed">DOV Needed?</label>
                <p-dropdown formControlName="dov" [options]="yesno" optionLabel="name" optionValue="value"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="dov">Date DOV requested</label>
                <p-calendar id="dov_date_requested" formControlName="dov_date_requested"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="dov_study_cost">DOV Study Cost</label>
                <p-inputNumber mode="currency" currency="USD" id="dov_study_cost" type="text"
                    formControlName="dov_study_cost"></p-inputNumber>
            </div>
        </div>
    </div>

    <div class="card">
        <h5>If Total Loss</h5>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="towed">Car Total Loss</label>
                <p-dropdown formControlName="total_loss" [options]="yesno" optionLabel="name" optionValue="total_loss"
                    [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="total_loss_amount">Total Loss Amount</label>
                <p-inputNumber mode="currency" currency="USD" id="total_loss_amount" type="text"
                    formControlName="total_loss_amount"></p-inputNumber>
            </div>
        </div>
    </div>

</div>


<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"
    (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid"
    (click)="store()"></button>