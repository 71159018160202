import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'providerrecord',
    templateUrl: './record.component.html',
    styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit, OnDestroy {
    providerrecord: any;
    subscriptions: Subscription[] = [];  
    mainForm: FormGroup;

    statuses:any[]=[
        {name: 'Active', value: 'A'},
        {name: 'Temporary Closed', value: 'TC'},
        {name: 'Closed', value: 'C'},        
    ];

    recTypes:any[]=[
        {name: 'All Medical Records', value: 'ALL'},
        {name: 'ER Medical Records', value: 'ER'},
        {name: 'Trauma Records', value: 'TR'},
        {name: 'Ortho Records', value: 'OR'},        
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private providersService: ProvidersService, private healthcareUseCase : HealthCareUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {
            this.mainForm = fb.group({
                id:[],
                _id:[],                
                recType:[],
                creationDate:[],
                createdBy:[],
                deptName:[],
                phoneNumber:[],
                outsourcingCo:[],
                initialDate:[],
                finalDate:[],
                feeToPay:[],
                status:[],
                statusChangeDate:[],
                comments:[],                                
            })        
        }

        get valid() : boolean {
            return this.mainForm.valid;
        }

        ngOnInit() {
            this.subscriptions.push(this.healthcareUseCase.providerrecordChange.subscribe(providerrecord => this.updateModel(providerrecord)));
            if(this.config.data) {
                this.updateModel(this.config.data);    
            }
                                                        
        }
    
        ngOnDestroy(): void {
            this.subscriptions.forEach(subscription => subscription.unsubscribe());
        }

        updateModel(providerrecord) {
            this.providerrecord = providerrecord;
            this.mainForm.patchValue(providerrecord);
            
        }

        store() {        
            if(this.mainForm.valid) {
                this.healthcareUseCase.appendRecord(Object.assign(this.providerrecord, this.mainForm.value));
            }
            this.clean();
        }

        clean() {
            this.mainForm.reset();
        }

        cancel() {
            this.healthcareUseCase.closeRecord();
            this.clean();
        }

    }
