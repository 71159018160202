<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'460px'}" [formGroup]="mainForm">    
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="username">Created By</label>
                <input id="username" type="text" pInputText formControlName="username" readonly autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="createdOn">Created On</label>
                <p-calendar id="createdOn" showTime="true" showSeconds="false" formControlName="createdOn" disabled="true"></p-calendar>
            </div>        
            <div class="p-field p-col-12 p-md-2">
                <label for="startDate">Start Date</label>
                <p-calendar id="startDate" formControlName="startDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="endDate">End Date</label>
                <p-calendar id="endDate" formControlName="endDate"></p-calendar>
            </div>                
            <div class="p-field p-col-12 p-md-2">
                <label for="daysAbsent">Total Days Off</label>
                <p-inputNumber formControlName="daysAbsent" id="daysAbsent" [disabled]="true"> </p-inputNumber>
            </div>  
            <div class="p-field p-col-12 p-md-2">
                <label for="notClaim">Form on File?</label>
                        <span class="p-float-label">
                            <p-dropdown id="notClaim" formControlName="formOnFile" [options]="yesnoOp" optionValue="value" optionLabel="name"                  
                            autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                        </span>           
            </div> 
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                <label for="comments">Comments</label>                
                <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="false" id="the-textarea"
                    formControlName="comments" appCharacterCounter [maxLength]="1000">                    
                </textarea>
                <label *ngIf="mainForm.get('comments')?.value">{{ 1000 - mainForm.get('comments').value.length}}/1000</label>                                
            </div>             
        </div>
    </div>
</div>