<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'720px'}" [formGroup]="mainForm">

    <!-- <div class="p-grid p-formgrid p-fluid"> -->
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-formgrid p-grid">            
            <div class="p-field p-col-12 p-md-2">
                <label for="licensePlate">License Plate</label>
                <p-inputMask id="licensePlate" mask="*?**-***-***-***-***-***-***" formControlName="licensePlate"></p-inputMask>                
            </div>
            <div class="p-field p-col-12 p-md-1">
                <label for="licenseState">State</label>
                <input id="licenseState" type="text" pInputText formControlName="licenseState" 
                pTooltip="Specify the State where registration was issued" 
                tooltipPosition="top"
                autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="vinNo">VIN No</label>
                <p-inputMask id="vinNo" mask="*?**-***-***-***-***-***-***" formControlName="vinNo"></p-inputMask>                
            </div>
            <!-- <div class="p-field p-col-12 p-md-2">
                <label for="bodyStyle">Body Style</label>
                <span class="p-float-label">
                    <p-dropdown id="bodyStyle" formControlName="bodyStyle" [options]="bodyStyles" optionValue="value" optionLabel="name"                  
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                </span>
            </div> -->
            <div class="p-field p-col-12 p-md-2">
                <label for="bodyStyle">Body Style</label>
                <p-dropdown id="bodyStyle" formControlName="bodyStyle" [options]="bodyStyles" optionValue="value" optionLabel="name" 
                    filter="true" autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-1">
                <label for="rowsOfSeats"># Rows</label>
                <p-inputNumber inputId="integeronly" formControlName="rowsOfSeats" autocomplete="doNotAutoComplete"></p-inputNumber>
            </div>
            <!-- <div class="p-field p-col-12 p-md-2">
                <label for="make">Make</label>
                <span class="p-float-label">
                    <p-dropdown id="make" formControlName="make" [options]="makes" optionValue="id" optionLabel="name"                  
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                </span>
            </div> -->
            <div class="p-field p-col-12 p-md-2">
                <label for="make"><label style="color:red">*</label> Make</label>
                <p-dropdown id="make" formControlName="make" [options]="makes" optionValue="id" optionLabel="name" 
                    filter="true" autoDisplayFirst="false" placeholder="Choose">
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="model">Model</label>
                <input id="model" type="text" pInputText formControlName="model">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="year">Year</label>
                <input id="year" type="text" pInputText formControlName="year">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="vcolor">Color</label>
                <input id="vcolor" type="text" pInputText formControlName="vcolor">
            </div>            
            <div class="p-field p-col-12 p-md-2">
                <label for="airbagsDeployed">Airbags deployed</label>
                <p-dropdown formControlName="airbagsDeployed" [options]="xAirbag" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="pInCar"><label style="color:red">*</label># People Inside</label>
                <p-inputNumber inputId="integeronly" formControlName="pInCar" autocomplete="doNotAutoComplete"></p-inputNumber>
            </div>                        
            <div class="p-field p-col-12 p-md-2">
                <label for="vDefendant"><label style="color:red">*</label> At Fault?</label>
                <span class="p-float-label">
                    <p-dropdown id="vDefendant" formControlName="vDefendant" [options]="yesno" optionValue="value" optionLabel="name"                  
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                </span>
            </div>   
            <div class="p-field p-col-12 p-md-2">
                <label for="vPlaintiff"><label style="color:red">*</label> Plaintiff's Car?</label>
                <span class="p-float-label">
                    <p-dropdown id="vPlaintiff" formControlName="vPlaintiff" [options]="yesno" optionValue="value" optionLabel="name"                  
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
                    <!-- <small id="vPlaintiff" class="p-error block">Please specify</small> -->
                </span>
            </div>                                
            <title>Vehicle Condition</title>
            <div class="p-field p-col-12 p-md-2">
                <label for="vConditions">After the Accident the car was</label>
                <p-dropdown inputId="vConditions" formControlName="conditions"
                pTooltip="Vehicle conditions just after the accident happened" 
                tooltipPosition="top" 
                [options]="vConditions" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                <!-- <p-dropdown inputId="vConditions" formControlName="vConditions" [options]="vConditions" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown> -->
            </div>                  
            <div class="p-field p-col-12 p-md-2">                                                    
                <h6>Car Was Towed</h6>
               <p-inputSwitch formControlName="wasTowed"></p-inputSwitch>
           </div>
            <div class="p-field p-col-12 p-md-2">                                                    
                <h6>Has Car Been in Prior Accident?</h6>
               <p-inputSwitch formControlName="carAccidentB"
                pTooltip="Information needed for DOV purposes" 
                tooltipPosition="top">>
                </p-inputSwitch>
           </div>
           <div class="p-field p-col-12 p-md-2">                                                    
            <h6>Rebuilt Title?</h6>
           <p-inputSwitch formControlName="rebuiltTitle" 
                pTooltip="When a car is severely damaged, it is issued a salvage title. The salvaged car is then repaired and inspected, and consequently, is issued a rebuilt title" 
                tooltipPosition="top">
            </p-inputSwitch>
       </div>
            <!-- <div class="p-field p-col-12 p-md-2">
                <label for="pdAmount">Property Damage Amount</label>
                <p-inputNumber mode="currency" currency="USD" id="pdAmount" type="text" formControlName="pdAmount" pTooltip="Amount paid to repair the vehicle" tooltipPosition="top" ></p-inputNumber>
            </div> -->
        </div>        
    </div>    

    <p-tabView [style]="{'height':'150px'}">        
        <p-tabPanel header="1. Status Notes">
            <div class="p-grid p-formgrid p-fluid">
                <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                    <div class="p-field p-col-12 p-md-3">
                        <label for="vehicleStatus">Status</label>
                        <p-dropdown formControlName="vehicleStatus" [options]="vehileStatuses" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                    </div> 
                    <div class="p-field p-col-12 p-md-3">
                        <label for="statusChangeDate">Last Status Changed On</label>
                        <p-calendar id="statusChangeDate"showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="statusChangeDate" disabled="true"></p-calendar>
                    </div>
                </div>
            </div>
            <p-table #dtc [value]="vehicleStatusNotes"  dataKey="id" selectionMode="single" 
                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"                    
                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" 
                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                    [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Created By</th>
                            <th pSortableColumn="noteCreatedOn">Created On
                                <p-sortIcon field="noteCreatedOn"></p-sortIcon>
                            </th>
                            <th>Notes</th>
                            <th>
                                <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                    (click)="addvNote()"></p-button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-object>
                        <tr [pSelectableRow]="note">
                            <td>{{object.username}}</td>    
                            <td>{{object.noteCreatedOn | date:'short'}}</td>                                            
                            <!-- <td>{{object.comments}}</td> -->
                            <td>{{(object.comments.length>100)? (object.comments | slice:0:100)+'...':(object.comments)}}</td>

                            <td>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="editvNote(object)"></button>
                                <!-- <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            Total of {{vehicleStatusNotes ? vehicleStatusNotes.length : 0 }}.
                        </div>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No notes found.</td>
                        </tr>
                    </ng-template>
            </p-table>            
        </p-tabPanel>
        
        
        <p-tabPanel header="2. Related Expenses">
            <p-table #dtc [value]="carexpenses" dataKey="id" selectionMode="single" 
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" 
            [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="3" [paginator]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Expenses</th>
                    <th>Paid on</th>
                    <th>Reimbursed on</th>
                    <th>
                        <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                            (click)="addCarExpense()"></p-button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-object>
                <tr [pSelectableRow]="carexpenses">
                    <td>{{object.expenseAmount}}</td>    
                    <td></td>
                    <td></td>    
                    <td>
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-success p-mr-2" (click)="editCarExpense(object)"></button>
                        <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-success p-mr-2" (click)="deleteCarExpense(object)"></button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    Total of {{objects ? objects.length : 0 }}.
                </div>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No expenses found yet.</td>
                </tr>
            </ng-template>
            </p-table>   
        </p-tabPanel>        
        <p-tabPanel header="3. Valuations and Losses">           
        <!-- <p-tabPanel header="3. Total Loss or DOV">            -->
            <div class="p-fluid p-formgrid p-grid">                                                                                              
                <div class="p-field p-col-12 p-md-2">
                    <label for="blueBookValue">Car Blue Book Value</label>
                    <p-inputNumber mode="currency" currency="USD" id="blueBookValue" type="text" formControlName="blueBookValue"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="pdAmount">Property Damage Amount</label>
                    <p-inputNumber mode="currency" currency="USD" id="pdAmount" type="text" formControlName="pdAmount" pTooltip="Amount paid to repair the vehicle" tooltipPosition="top" ></p-inputNumber>
                </div>                
            </div>
            <div class="p-fluid p-formgrid p-grid">                                                                                              
               <div class="p-field p-col-12 p-md-2">
                    <h6>DOV Applies?</h6>
                    <p-inputSwitch formControlName="isDov" ></p-inputSwitch>                    
               </div>
               <div class="p-field p-col-12 p-md-2">
                    <label for="preloss">Pre-Loss Retail Value</label>
                    <p-inputNumber mode="currency" currency="USD" id="preloss" formControlName="preloss" type="text" [disabled]="!mainForm.get('isDov').value"></p-inputNumber>                    
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="inherentDV">Inherent DV *</label>
                    <p-inputNumber mode="currency" currency="USD" id="inherentDV" formControlName="inherentDV" type="text" [disabled]="!mainForm.get('isDov').value"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="repairRelated">Repair Related DV *</label>
                    <p-inputNumber mode="currency" currency="USD" id="repairRelated" formControlName="repairRelated" type="text" [disabled]="!mainForm.get('isDov').value"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="totaldov">Total DV *</label>
                    <p-inputNumber mode="currency" currency="USD" id="totaldov" type="text" formControlName="totaldov" readonly="true" [disabled]="!mainForm.get('isDov').value"></p-inputNumber>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="postRepair">Post Repair Retail Value</label>
                    <p-inputNumber mode="currency" currency="USD" id="post" type="text" formControlName="postRepairValue" readonly="true" [disabled]="!mainForm.get('isDov').value"></p-inputNumber>
                </div>
                <!-- <div class="p-field p-col-12 p-md-2">
                    <strong><label>* Diminished Value</label></strong>
                </div>   -->
                
            </div>   
            
            <div class="p-fluid p-formgrid p-grid">    
                <div class="p-field p-col-12 p-md-2">
                    <h6>Is Vehicle Total Loss?</h6>
                    <p-inputSwitch formControlName="isTotal" ></p-inputSwitch>                    
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="post">Total Loss Valuation</label>
                    <p-inputNumber mode="currency" currency="USD" id="post" type="text" formControlName="totalLoss" [disabled]="!mainForm.get('isTotal').value"></p-inputNumber>
                </div>
            </div>  
            <div class="p-fluid p-formgrid p-grid">    
                <div class="p-field p-col-12 p-md-2">
                    <label for="insurancesource">Paid by</label>
                    <span class="p-float-label">
                            <p-dropdown id="insurancesource" formControlName="insurancesource" [options]="insuranceSource" optionValue="value" optionLabel="name"                  
                            autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                    </span>
                </div>
            </div>
                                  
        </p-tabPanel>
        <p-tabPanel header="4. Location">
            
                <h5>Last known Location</h5>
                <address formGroupName="address"></address>    
                <div class="p-fluid p-formgrid p-grid"> 
                    <div class="p-field p-col-12 p-md-4">
                        <label for="locationName">Facility Name</label>
                        <input id="locationName" type="text" pInputText formControlName="locationName">
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="phoneNumber">Phone</label>
                        <p-inputMask id="phoneNumber" mask="(999) 999-9999" formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label for="faxNumber">Fax</label>
                        <p-inputMask id="faxNumber" mask="(999) 999-9999" formControlName="faxNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <label for="website">website</label>
                        <input id="website" type="text" pInputText formControlName="website">
                    </div>
                </div>
            
        </p-tabPanel> 
        <p-tabPanel header="5. Seating Positions and Relationships">
            <h5>Positioning and Relationships inside the car </h5> 
                <p-table #dtc [value]="positioning"  dataKey="id" selectionMode="single" 
                        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"                    
                        [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4"
                        [paginator]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Person</th>
                                <th>Position</th>
                                <th>Relationship</th>
                                <th>Relative Name</th>
                                <th>
                                    <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                        (click)="addPositioning()"></p-button>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-object>
                            <tr [pSelectableRow]="positions">
                                <td></td>    
                                <td></td>                                            
                                <td></td>
    
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-success p-mr-2" (click)="editPositioning(object)"></button>
                                    <!-- <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                                </td>
                            </tr>
                        </ng-template>
    
                        <ng-template pTemplate="summary">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                Total of {{positioning ? positioning.length : 0 }}.
                            </div>
                        </ng-template>
    
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Unknown Positioning</td>
                            </tr>
                        </ng-template>
                </p-table> 
            </p-tabPanel>            
        <p-tabPanel header="6. Docs and Pics">            
        </p-tabPanel>
    </p-tabView> 
</div>