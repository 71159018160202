import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ProvidersService {
    constructor(private http: HttpClient) { }

    store(healthCareProvider): Promise<any> {
        return this.http.post<any>(environment.clientsEndpointUrl + '/providers', healthCareProvider).toPromise();
    }

    findAll(search: any): Promise<any[]> {
        return this.http.get<any>(environment.clientsEndpointUrl + '/providers', { params: search }).toPromise();
    }

    findById(id): Promise<any> {
        return this.http.get<any>(environment.clientsEndpointUrl + '/providers/' + id).toPromise().then(_hcprovider => {
            _hcprovider.departments = _hcprovider.departments || [];
            _hcprovider.departments.forEach(department => {
                department.services = department.services || [];
                department.services.forEach(service => {
                    service.requestMethods = service.requestMethods || [];
                });
            })
            _hcprovider.outsourcings = _hcprovider.outsourcings || [];
            _hcprovider.recordsReq = _hcprovider.recordsReq || [];
            _hcprovider.locations = _hcprovider.locations || [];
            _hcprovider.providerNotes = _hcprovider.providerNotes || [];
            _hcprovider.docs = _hcprovider.docs || [];
            return _hcprovider;

        });
    }

    getCareGroups(): Promise<any[]> {
        return this.http.get<any[]>(`${environment.clientsEndpointUrl}/providers/care-groups`).toPromise();
    }

    getOutsourcing(): Promise<any[]> {
        return this.http.get<any[]>(`${environment.clientsEndpointUrl}/providers/outsourcingCos`).toPromise();
    }

    getDepartments(providerId): Promise<any[]> {
        return this.http.get<any[]>(`${environment.clientsEndpointUrl}/providers/${providerId}/departments`).toPromise();
    }

    getServices(providerId, departmentId): Promise<any[]> {
        return this.http.get<any[]>(`${environment.clientsEndpointUrl}/providers/${providerId}/departments/${departmentId}/services`).toPromise();
    }
}