import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';

@Component({
    selector: 'complaint',
    templateUrl: './complaint.component.html',
    styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {
    complaint: any;
    mainForm: FormGroup;
    complaints = [];
    symptoms=[];
        
    leftright: any[] = [
        {name: '', value:null},
        {name: 'Left', value:'Left'},
        {name: 'Right', value: 'Right'},
        {name: 'Lower', value: 'Lower'},
        {name: 'Upper', value: 'Upper'},        
        {name: 'Lower and Upper', value: 'Lower and Upper'},
        {name: 'Middle', value: 'Middle'},
        {name: 'Both', value: 'Both'}
    ];
        
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase,
        private catalogsService: CatalogsService, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            complaint:[],
            symptom:[null, Validators.required],            
            position:[],
            hitCar:[],
            specify:[]
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.complaint = this.config.data;
        }
        this.catalogsService.findComplaintsAll().then(complaints => this.complaints = complaints);
        this.catalogsService.findSymptomsAll().then(symptoms => this.symptoms = symptoms);
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendComplaint(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeComplaint();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}
