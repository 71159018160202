 import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
 import { ActivatedRoute, Router } from '@angular/router';
 import { CasesService } from '../../../cases.service';
 import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
 import { DynamicDialogConfig } from 'primeng/dynamicdialog';
 import Dates from 'src/app/utilities/Dates';
 import { CatalogsService } from 'src/app/catalogs/catalogs.service';

 @Component({
     selector: 'carexpense',
     templateUrl: './carexpense.component.html',
     styleUrls: ['./carexpense.component.scss']
 })
 export class CarExpenseComponent implements OnInit {
     carexpense: any;
     mainForm: FormGroup;
     carexpenses = [];       
       
     constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private catalogsService: CatalogsService, private casesService: CasesService, private intakeUseCase : IntakeUseCase,
         private config: DynamicDialogConfig) {

         this.mainForm = fb.group({
             id:[],
             type:[],
             expenseAmount:[],
             paidBy:[],
             paidOn:[],
             reimbursedBy:[],
             reimbursedOn:[],
             evidenceAttached:[],
             comments:[]            
         });
     }

     get valid() : boolean {
         return this.mainForm.valid;
     }

     ngOnInit() {
         if(this.config.data) {
             this.mainForm.patchValue(this.config.data);
             this.carexpense = this.config.data;
         }
         this.catalogsService.findCarExpensesAll().then(carexpenses => this.carexpenses = carexpenses);
        
     }

     store() {
         if(this.mainForm.valid) {
             this.intakeUseCase.appendCarExpense(this.mainForm.value);
             this.clean();
         }
     }

     clean() {
         this.mainForm.reset();
     }

     cancel() {
         this.clean();
     }

     activate() {

     }

     inactivate() {

     }

 }
