import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class ContactsService {
    constructor(private http: HttpClient) {
    }

    findPeople(search) : Promise<any[]> {
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/contacts', search).toPromise();
    }

    findParalegals(search) : Promise<any[]> {
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/paralegals', null).toPromise();
    }

    findInsurances(search) : Promise<any[]> {
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/insurances', null).toPromise();
    }
}