import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';

@Component({
    selector: 'witness',
    templateUrl: './witness.component.html',
    styleUrls: ['./witness.component.scss']
})
export class WitnessComponent implements OnInit {
    case: any;
    mainForm: FormGroup;

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            person: fb.group({
                id:[],
                firstName: [null, Validators.required],
                middleName:[],
                lastName: [null, Validators.required],
                sex: [null, Validators.required],
                dob: [],
                phoneNumber:[],
                optionalPhoneNumber:[],
                emailAddress:[],
                address: fb.group({
                    country: [],
                    street:[],
                    apartment:[],
                    city:[],
                    state:[],
                    zipcode:[],
                })
            })
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
        }
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendWitness(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}
