import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { CasesService } from '../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import {SelectButtonModule} from 'primeng/selectbutton';
import * as moment from 'moment';
import Dates from 'src/app/utilities/Dates';
import { Subscription } from 'rxjs';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';

@Component({
    selector: 'defendant',
    templateUrl: './defendant.component.html',
    styleUrls: ['./defendant.component.scss']
})
export class DefendantComponent implements OnInit, OnDestroy {
    defendant: any;
    insurances = [];
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];

    genders = [{name:'FEMALE', label:'Female'}, {name:'MALE', label:'Male'}];
    
    personalIdTypes: any[] = [
        {name: 'State Id', value: 1},
        {name: 'Passport', value: 2},        
        {name: 'Native Country Id', value: 3},
        {name: 'Casa Maryland', value: 4},
        {name: 'Not Available', value: 5},
    ];

    yesno: any[] = [
        {name: 'Unknown', value: null},
        {name: 'Driver does not', value: true},
        {name: 'Driver does', value: false},
    ];

    adyesno: any[] = [
        {name: 'Unknown', value: null},
        {name: 'Driver is not', value: true},
        {name: 'Driver is', value: false},
    ];

    ticketyesno: any[] = [
        {name: 'Unknown', value: null},
        {name: 'No, it was not', value: true},
        {name: 'Yes, it was', value: false},
    ];

    duiyesno: any[] = [
        {name: 'Unknown', value: null},
        {name: 'Yes', value: true},
        {name: 'No', value: false},
    ];
    stateOptions: any[] = [
        {name: 'Driver is also the Insured', value: true},
        {name: 'Driver is NOT the Insured', value: false}
    ];
    

    liabilityStatuses = [{name:'PENDING'}, {name:'INVESTIGATING'}, {name:'ACCEPTED'}, {name:'DENIED'}];

    liabilities = [];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private catalogsService: CatalogsService, 
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            person: fb.group({
                id:[],
                firstName: [],
                middleName:[],
                lastName: [],
                sex: [],
                dob: [],
                phoneNumber:[],
                optionalPhoneNumber:[],
                emailAddress:[],
                personalIdType: [],
                personalIdNo: [],
                driverLicenseNo: [],
                licenseState: [],
                address: fb.group({
                    country: [],
                    street:[],
                    apartment:[],
                    city:[],
                    state:[],
                    zipcode:[],
                })
            }),
            // insurance: fb.group({
            //     id:[],
            //     name: [],
            // }),
            claim: fb.group({
                claimNumber:[],
                insurance:[],
                policyNumber:[],
                biEachPerson:[],
                biEachOccurrence:[],
                adjuster: fb.group({
                    name: [],                    
                   // lname: [],
                    // sex: [],
                    phone: [],
                    //ext: [],
                    fax:[],
                    email:[],
                    address: fb.group({
                        country: [],
                        street:[],                      
                        city:[],
                        state:[],
                        zipcode:[],
                    })
                }),
                pdAdjuster: fb.group({
                    name: [],                    
                   // lname: [],
                    // sex: [],
                    phone: [],
                    //ext: [],
                    fax:[],
                    email:[],
                    address: fb.group({
                        country: [],
                        street:[],                      
                        city:[],
                        state:[],
                        zipcode:[],
                    })
                })
            }),
            ddriver: fb.group({
                id:[],
                person: fb.group({
                    id:[],
                    firstName: [],
                    middleName:[],
                    lastName: [],
                    sex: [],
                    dob: [],
                    personalIdType: [],
                    personalIdNo: [],
                    driverLicenseNo: [],
                    licenseState: [],
                    address: fb.group({
                        country: [],
                        street:[],
                        apartment:[],
                        city:[],
                        state:[],
                        zipcode:[],
                    })
                }),
                noDriverLicense:[],
                authorizedDriver:[],
                wasGivenTicket:[],
                duiCharges:[],
                takenByPolice:[],
                driverAlsoInsured:[],
                anyComments:[],
            })            
        });
        
    }

    ngOnInit() {
        this.subscriptions.push(this.intakeUseCase.defendantChange.subscribe(defendant => this.updateModel(defendant)));
        if(this.config.data) {
            this.updateModel(this.config.data);
        }
        
        this.catalogsService.findInsurancesAll().then(insurances => this.insurances= insurances);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    updateModel(defendant) {
        this.defendant = defendant;
        this.liabilities = defendant.liabilities;
        this.mainForm.patchValue(defendant);
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendDefendant(Object.assign(this.defendant, this.mainForm.value));
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeDefendant();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

    addLiability() {
        this.intakeUseCase.addLiability();
    }

    editLiability(liability) {
        this.intakeUseCase.editLiability(liability);
    }

}
