import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class CatalogsService {
    constructor(private http: HttpClient) {

    }

    getMetaData() : Promise<any[]> {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/metadata').toPromise();
    }

    findObjectsByType(type) : Promise<any> {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/' + type).toPromise();
    }

    findObjectByReference(reference) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/' + reference.type + '/' + reference.id).toPromise();
    }

    deleteObjectByReference(reference) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/' + reference.type + '/' + reference.id).toPromise();
    }

    storeObject(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/' + object.type, object).toPromise();
    }

    findIdTypesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/idtypes').toPromise();
    }

    storeIdType(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/idtypes', object).toPromise();
    }

    findIdTypeById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/idtypes/' + id).toPromise();
    }

    deleteIdTypeById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/idtypes/' + id).toPromise();
    }

    /*Language*/

    findLanguagesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/languages').toPromise();
    }

    findLanguages(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/languages',null).toPromise();
    }

    storeLanguage(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/languages', object).toPromise();
    }

    findLanguageById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/languages/' + id).toPromise();
    }

    deleteLanguageById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/languages/' + id).toPromise();
    }

    /*States*/

    findStatesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/states').toPromise();
    }

    storeState(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/states', object).toPromise();
    }

    findStateById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/states/' + id).toPromise();
    }

    deleteStateById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/states/' + id).toPromise();
    }

    /*Complaints*/
    findComplaintsAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/complaints').toPromise();
    }

    findComplaints(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/catalogs/complaints/',null).toPromise();
    }

    storeComplaint(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/complaints', object).toPromise();
    }

    findComplaintById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/complaints/' + id).toPromise();
    }

    deleteComplaintById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/complaints/' + id).toPromise();
    }

    /*Symptoms*/

    findSymptomsAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/symptoms').toPromise();
    }

    findSymptoms(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/catalogs/symptoms/',null).toPromise();
    }

    storeSymptom(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/symptoms', object).toPromise();
    }

    findSymptomById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/symptoms/' + id).toPromise();
    }

    deleteSymptomById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/symptoms/' + id).toPromise();
    }

    /*Car Expenses*/
    findCarExpensesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/carexpensestype').toPromise();
    }

    findCarExpenses(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/catalogs/carexpensestype/',null).toPromise();
    }

    storeCarExpense(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/carexpensestype', object).toPromise();
    }

    findCarExpenseById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/carexpensestype/' + id).toPromise();
    }

    deleteCarExpenseById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/carexpensestype/' + id).toPromise();
    }

    /*Vehicle Make*/
    findVehicleMakesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/vehiclemake').toPromise();
    }

    findVehicleMakes(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/catalogs/vehiclemake/',null).toPromise();
    }

    storeVehicleMake(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/vehiclemake', object).toPromise();
    }

    findVehicleMakeById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/vehiclemake/' + id).toPromise();
    }

    deleteVehicleMakesById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/vehiclemake/' + id).toPromise();
    }

     /*Insurance Companies*/

     findInsurancesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/insurances').toPromise();
    }

    findInsurances(search) :Promise<any[]>{
        return this.http.post<any[]>(environment.clientsEndpointUrl + '/catalogs/insurances/',null).toPromise();
    }

    storeInsurance(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/insurances', object).toPromise();
    }

    findInsuranceById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/insurances/' + id).toPromise();
    }

    deleteInsuranceById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/insurances/' + id).toPromise();
    }

    // DocumentType
    findDocumentTypesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/documentTypes').toPromise();
    }

    storeDocumentType(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/documentTypes', object).toPromise();
    }

    findDocumentTypeById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/documentTypes/' + id).toPromise();
    }

    deleteDocumentTypeById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/documentTypes/' + id).toPromise();
    }

    // Portal
    findPortalsAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/portals').toPromise();
    }

    storePortal(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/portals', object).toPromise();
    }

    findPortalId(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/portals/' + id).toPromise();
    }

    deletePortalById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/portals/' + id).toPromise();
    }

    // Health Care Provider Document Type
    findDocTypesAll() {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/healthcaredocTypes').toPromise();
    }

    storeDocType(object) {
        return this.http.post<any>(environment.clientsEndpointUrl + '/catalogs/healthcaredocTypes', object).toPromise();
    }

    findDocTypeById(id) {
        return this.http.get<any>(environment.clientsEndpointUrl + '/catalogs/healthcaredocTypes/' + id).toPromise();
    }

    deleteDocTypeById(id) {
        return this.http.delete<any>(environment.clientsEndpointUrl + '/catalogs/healthcaredocTypes/' + id).toPromise();
    }
}
