<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

<div [style]="{'height':'360px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;">         
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="position">Position</label>
                <p-dropdown id="position" formControlName="position" [options]="leftright" optionLabel="name" optionValue="value" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div> 
            <div class="p-field p-col-12 p-md-2">
                <label for="complaint"><label style="color:red">*</label>Part of the body</label>
                <p-dropdown id="complaint" formControlName="complaint" [options]="complaints" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>   
            <!-- <div class="p-field p-col-12 p-md-2">
                <label for="complaint">Part of the body</label>
                <p-dropdown inputId="complaint" formControlName="complaint" [options]="complaints" optionLabel="name" optionValue="id" [autoDisplayFirst]="false"></p-dropdown>
            </div>     -->            
            <!-- <div class="p-field p-col-12 p-md-2">
                <label for="position">Position</label>
                <p-dropdown inputId="position" formControlName="position" [options]="leftright" optionLabel="name" optionValue="name" [autoDisplayFirst]="false" ></p-dropdown>
            </div>                                 -->
            <div class="p-field p-col-12 p-md-2">
                <label for="symptom"><label style="color:red">*</label>Symptoms</label>
                <p-dropdown id="symptom" formControlName="symptom" [options]="symptoms" optionLabel="name" optionValue="id" filter="true"
                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                
            </div>            
            <div class="p-field p-col-12 p-md-2">                                                    
                <h6><span>Hit any part of the Car?</span></h6>
                <p-inputSwitch formControlName="hitCar"></p-inputSwitch>
            </div>                
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">
                <label for="specify"><span class="label success"><strong>Please specify &nbsp;</strong></span> which part of the car plaintiff hit or add anything else you consider relevant</label>
                <textarea inputId="textarea" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                    formControlName="specify"></textarea>                       
                    <label></label>
            </div>                   
        </div>        
    </div>
</div>