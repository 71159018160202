<div [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;">         
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label for="documentType">Document Type</label>
                <p-dropdown inputId="documentType" formControlName="documentType" [options]="documentTypes" optionLabel="name" optionValue="id" filter="true" 
                [autoDisplayFirst]="false"  placeholder="Choose"></p-dropdown>
            </div>   
                
        </div>
        <div class="p-fluid p-grid">

            <div class="p-field p-col-12 p-md-6">
                <label for="upload">Select Files</label>
                <p-fileUpload name="files[]" [multiple]="true" [customUpload]="true" 
                (onSelect)="selectFile($event)" (onRemove)="removeFile($event)" [showUploadButton]="false" cancelLabel="Clear" maxFileSize="26214400">
                <!-- maxFileSize is 20MB -->
                </p-fileUpload>  
            </div>    

         
        </div>             
    </div>

</div>

<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>


