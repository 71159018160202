import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { Directive, ElementRef, HostListener} from '@angular/core';
import { Subscription } from 'rxjs';




@Component({
    selector: 'adjuster',
    templateUrl: './adjuster.component.html',
    styleUrls: ['./adjuster.component.scss']
})
export class AdjusterComponent implements OnInit {
    adjuster: any;
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];  

    adjusterTypes: any[] = [
        {name: 'BI', value:'BI'},
        {name: 'PD', value: 'PD'},
        {name: 'BIPD', value: 'BI/PD'},
        {name: 'UM', value: 'UM'},
        {name: 'PIP', value: 'PIP'},
    ];
       
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            name:[null, Validators.required],
            phone:[],
            fax:[],
            ext:[],
            email:[],
            adjusterType:[null, Validators.required],
            address: fb.group({
                country: [],
                street:[],                      
                city:[],
                state:[],
                zipcode:[],
            })                    
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);            
        }
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendAdjuster(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeAdjuster();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }
    
}