import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { ProvidersService } from '../providers.service';
import { LocationComponent } from "../form/location/location.component";
import { RecordComponent } from "../form/record/record.component";
import { ServiceComponent } from "../form/department/service/service.component";
import * as uuid from 'uuid';
import Dates from "src/app/utilities/Dates";
import { NoteComponent } from "../form/note/note.component";
import * as moment from "moment";
import { DepartmentComponent } from "../form/department/department.component";
import { ProviderDocumentComponent } from "../form/document/document.component";
import { RequestMethodComponent } from "../form/department/service/requestmethod/requestmethod.component";
import { OutsourcingComponent } from '../form/outsourcing/outsourcing.component';


@Injectable()
export class HealthCareUseCase {
    provider: any;
    providerlocation: any;
    outsourcing: any;
    providerrecord: any;
    department: any;
    service: any;
    requestmethod: any;
    document: any;
    ref: DynamicDialogRef;
    ref1: DynamicDialogRef;
    ref2: DynamicDialogRef;

    public providerChange = new Subject();
    public serviceChange = new Subject();
    public departmentChange = new Subject();
    public providerlocationChange = new Subject();
    public providerrecordChange = new Subject();
    public requestmethodChange = new Subject();

    types: any[] = [
        { name: 'Hospital', value: 'H' },
        { name: 'Physician Group', value: 'PG' },
        { name: 'Imaging', value: 'I' },
        { name: 'Collection Agency', value: 'CA' },
        { name: 'Chiropractic', value: 'CH' },
        { name: 'Physical Therapy', value: 'PT' },
        { name: 'Orthopedic', value: 'OR' },
        { name: 'Urgent Care', value: 'UC' },
        { name: 'Care Group', value: 'CG' },
        { name: 'Copy Services', value: 'CS' }
    ];

    constructor(private route: ActivatedRoute, private router: Router, private providersService: ProvidersService, private dialogService: DialogService) { }

    start(providerId?) {
        if (providerId) {
            return this.providersService.findById(providerId)
                .then(_hcprovider => {
                    this.provider = _hcprovider;
                    this.providerChange.next();
                });
        } else {
            this.provider = this.createNewProvider();
            return Promise.resolve();
        }
    }

    private createNewProvider(): any {
        return {
            locations: [],
            notes: [],
            docs: [],
            bills: [],
            recs: [],
        };
    }

    stop() { }

    /*Locations*/
    addLocation() {
        this.providerlocation = { _id: uuid.v4() };
        this.ref = this.dialogService.open(LocationComponent, { header: 'New Location Information', width: '70%', data: this.providerlocation });
    }

    editLocation(providerlocation) {
        let cloned = JSON.parse(JSON.stringify(providerlocation));
        //let cloned = {...providerlocation};
        this.proccessLocationModel(cloned);
        this.providerlocation = cloned;
        this.ref = this.dialogService.open(LocationComponent, { header: 'Location Information', width: '70%', data: this.providerlocation });
    }

    proccessLocationModel(providerlocation) {
        if (providerlocation.creationDate) {
            providerlocation.creationDate = Dates.toISO(providerlocation.creationDate);
        }

        return providerlocation;
    }

    appendLocation(providerlocation) {
        this.addOrUpdate(this.provider.locations, providerlocation);
        if (providerlocation.creationDate) {
            providerlocation.creationDate = Dates.fromISO(providerlocation.creationDate);
        }
        this.storeProvider()
        this.ref.close();
    }

    closeLocation() {
        this.ref.close();
    }

    /*Records*/
    addRecord() {
        this.providerrecord = { _id: uuid.v4() };
        this.ref = this.dialogService.open(RecordComponent, { header: 'New Record Information', width: '70%', data: this.providerrecord });
    }

    editRecord(providerrecord) {
        let cloned = JSON.parse(JSON.stringify(providerrecord));
        //let cloned = {...providerlocation};
        this.proccessRecordModel(cloned);
        this.providerrecord = cloned;
        this.ref = this.dialogService.open(RecordComponent, { header: 'Location Information', width: '70%', data: this.providerrecord });
    }

    proccessRecordModel(providerrecord) {
        if (providerrecord.creationDate) {
            providerrecord.creationDate = Dates.toISO(providerrecord.creationDate);
        }

        return providerrecord;
    }

    appendRecord(providerrecord) {
        this.addOrUpdate(this.provider.recordsReq, providerrecord);
        if (providerrecord.creationDate) {
            providerrecord.creationDate = Dates.toISO(providerrecord.creationDate);
        }
        this.storeProvider()
        this.ref.close();
    }

    closeRecord() {
        this.ref.close();
    }

    /*Department*/
    addDepartment() {
        this.department = { _id: uuid.v4(), services: [] };
        this.ref = this.dialogService.open(DepartmentComponent, { header: 'II. New Department Information', width: '70%', data: this.department });
    }

    editDepartment(department) {
        let cloned = JSON.parse(JSON.stringify(department));
        this.processDepartmentModel(cloned);
        this.department = cloned;
        this.ref = this.dialogService.open(DepartmentComponent, { header: 'II. Department Information', width: '70%', data: this.department });
    }

    processDepartmentModel(department) {
        if (department.creationDate) {
            department.creationDate = Dates.fromISODateTime(department.creationDate);
        }

        return department;
    }

    storeDepartment(department) {
        this.department = department;
        this.addOrUpdate(this.provider.departments, department);
        return this.storeProvider().then(() => this.ref.close());
    }

    appendDepartment(department) {
        this.department = department;
        this.addOrUpdate(this.provider.departments, department);
    }

    closeDepartment() {
        this.ref.close();
    }

    /*Services*/
    addService() {
        this.service = { _id: uuid.v4(), requestMethods: [] };
        this.ref1 = this.dialogService.open(ServiceComponent, { header: 'III. New Service', width: '50%', data: this.service });
    }

    editService(service) {
        let cloned = JSON.parse(JSON.stringify(service));
        this.processServiceModel(cloned)
        this.service = cloned;
        this.ref1 = this.dialogService.open(ServiceComponent, { header: 'III. Service', width: '50%', data: cloned });
    }

    storeService(service) {
        this.addOrUpdate(this.department.services, service);
        this.addOrUpdate(this.provider.departments, this.department);
        let departmentIx = this.indexOf(this.provider.departments, this.department);

        return this.storeProvider()
            .then(() => this.department = this.provider.departments[departmentIx])
            .then(() => this.departmentChange.next(this.processDepartmentModel(this.department)))
            .then(() => this.ref1.close());
    }

    appendService(service) {
        this.service = service;
        this.addOrUpdate(this.department.services, service);
        this.addOrUpdate(this.provider.departments, this.department);
    }

    deleteService(service) {
        let departmentIx = this.indexOf(this.provider.departments, this.department);
        let serviceIx = this.indexOf(this.department.services, service);
        this.department.services.splice(serviceIx, 1);
        this.storeProvider()
            .then(() => this.department = this.provider.departments[departmentIx])
            .then(() => this.departmentChange.next(this.processDepartmentModel(this.department)));
    }

    processServiceModel(service) {
        if (service.doctype) {
            service.doctype = service.doctype.id;
        }
        if (service.responsePortal) {
            service.responsePortal = service.responsePortal.id;
        }
        if (service.portal) {
            service.portal = service.portal.id;
        }
        if (service.creationDate) {
            service.creationDate = Dates.fromISODateTime(service.creationDate);
        }
        return service;
    }

    closeService() {
        this.ref1.close();
    }

    /*Request Method*/
    addRequestMethod() {
        this.ref2 = this.dialogService.open(RequestMethodComponent, { header: 'IV. New Request Method', width: '50%', data: { _id: uuid.v4() } });
    }

    editRequestMethod(requestmethod) {
        let cloned = { ...requestmethod };
        this.processRequestMethodModel(cloned)
        this.requestmethod = cloned;
        this.ref2 = this.dialogService.open(RequestMethodComponent, { header: 'IV. Request Method', width: '50%', data: cloned });
    }

    storeRequestMethod(requestMethod) {
        this.addOrUpdate(this.service.requestMethods, requestMethod);
        this.addOrUpdate(this.department.services, this.service);
        this.addOrUpdate(this.provider.departments, this.department);

        let serviceIx = this.indexOf(this.department.services, this.service);
        let departmentIx = this.indexOf(this.provider.departments, this.department);

        return this.storeProvider()
            .then(() => this.department = this.provider.departments[departmentIx])
            .then(() => this.departmentChange.next(this.processDepartmentModel(this.department)))
            .then(() => this.service = this.department.services[serviceIx])
            .then(() => this.serviceChange.next(this.processServiceModel(this.service)))
            .then(() => this.ref2.close());
    }

    // deleteRequestMethod(service) {
    //     let departmentIx = this.indexOf(this.provider.departments, this.department);
    //     let serviceIx = this.indexOf(this.department.services, service);
    //     this.department.services.splice(serviceIx, 1);
    //     this.storeProvider()
    //         .then(() => this.department = this.provider.departments[departmentIx])
    //         .then(() => this.departmentChange.next(this.proccessDepartmentModel(this.department)));
    // }

    processRequestMethodModel(requestmethod) {
        if (requestmethod.portal) {
            requestmethod.portal = requestmethod.portal.id;
        }
        if (requestmethod.responsePortal) {
            requestmethod.responsePortal = requestmethod.responsePortal.id;
        }
        if (requestmethod.createdOn) {
            requestmethod.createdOn = Dates.fromISODateTime(requestmethod.createdOn);
        }
        return requestmethod;
    }

    closeRequestMethod() {
        this.ref2.close();
    }



    /*Provider Status Notes*/
    addNote() {
        this.ref1 = this.dialogService.open(NoteComponent, { header: 'New Note', width: '50%', data: { _id: uuid.v4() } });
    }

    editNote(note) {
        let cloned = { ...note };
        if (cloned.noteCreatedOn) {
            cloned.noteCreatedOn = Dates.fromISODateTime(cloned.noteCreatedOn);
        }
        if (cloned.eventDate) {
            cloned.eventDate = Dates.fromISO(cloned.eventDate);
        }
        if (cloned.completedOn) {
            cloned.completedOn = Dates.fromISODateTime(cloned.completedOn);
        }

        this.ref1 = this.dialogService.open(NoteComponent, { header: 'Notes', width: '50%', data: cloned });
    }

    appendNote(note) {
        this.addOrUpdate(this.provider.providerNotes, note);
        if (note.noteCreatedOn) {
            note.noteCreatedOn = Dates.toISODateTime(note.noteCreatedOn);
        } else {
            note.noteCreatedOn = Dates.toISODateTime(new Date());
        }

        if (note.eventDate) {
            note.eventDate = Dates.toISO(note.eventDate);
        }
        this.storeProvider()
        this.ref1.close();
    }



    closeNote() {
        this.ref1.close();
    }


    addOrUpdate(arr: any[], element) {
        for (let i = 0; i < arr.length; i++) {
            let other = arr[i];
            if ((element.id != null && element.id == other.id) || (element._id != null && element._id == other._id)) {
                arr[i] = element;
                return;
            }
        }
        arr.push(element);
    }

    indexOf(arr: any[], element): number {
        for (let i = 0; i < arr.length; i++) {
            let other = arr[i];
            if ((element.id != null && element.id == other.id) || (element._id != null && element._id == other._id)) {
                arr[i] = element;
                return i;
            }
        }
        return -1;
    }

    storeProvider() {
        if (this.provider.creationDate) {
            this.provider.creationDate = Dates.toISO(this.provider.creationDate);
            const currentDate = Dates.toISODateTime(new Date());
            this.provider.creationDate = currentDate;
            console.log('Health Care Provider Case --> this.provider.creationDate', currentDate); // Debugging line
        }
        return this.providersService.store(this.provider)
            .then(providerId => this.providersService.findById(providerId))
            .then(_hcprovider => this.provider = _hcprovider)
            .then(() => this.providerChange.next())
    }

    refreshProvider() {
        if (this.provider.id) {
            this.providersService
                .store(this.provider)
                .then(() => this.providersService.findById(this.provider.id))
                .then(_hcprovider => this.provider = _hcprovider);
        }
    }

    remove(arr: any[], element) {
        arr.splice(arr.indexOf(element), 1);
    }

    /*Provider Documents*/
    addDocument() {
        this.document = { _id: uuid.v4() };
        this.ref = this.dialogService.open(ProviderDocumentComponent, { header: 'New Document Information', width: '70%', data: this.document });
    }

    editDocument(document) {
        let cloned = { ...document };
        this.proccessDocumentModel(cloned);
        this.document = cloned;
        this.ref = this.dialogService.open(ProviderDocumentComponent, { header: 'Document Information', width: '70%', data: this.document });
    }

    proccessDocumentModel(document) {
        if (document.createdOn) {
            document.createdOn = Dates.fromISO(document.createdOn);
        }
        return document;
    }

    appendDocuments(docs) {
        docs.forEach(providerdocument => {
            this.addOrUpdate(this.provider.docs, providerdocument);
            // if(document.createdOn) {
            //     document.createdOn = Dates.toISODateTime(document.createdOn); 
            // }
            if (providerdocument.createdOn) {
                providerdocument.createdOn = Dates.toISODateTime(providerdocument.createdOn);
            } else {
                providerdocument.createdOn = Dates.toISODateTime(new Date());
            }
        })
        this.storeProvider()
        this.ref.close();
    }

    appendDocument(document) {
        this.addOrUpdate(this.provider.docs, document);
        if (document.createdOn) {
            document.createdOn = Dates.toISODateTime(document.createdOn);
        } else {
            document.createdOn = Dates.toISODateTime(new Date());
        }
        this.storeProvider()
        this.ref.close();
    }

    closeDocument() {
        this.ref.close();
    }

    /*Outsourcing*/
    addOutsourcing() {
        this.outsourcing = { _id: uuid.v4() };
        this.ref = this.dialogService.open(OutsourcingComponent, { header: 'New Outsourcing Provider', width: '70%', data: this.outsourcing });
    }

    editOutsourcing(outsourcing) {
        this.outsourcing = this.processOutsourcingModel({ ...outsourcing });
        this.ref = this.dialogService.open(OutsourcingComponent, { header: 'Edit Outsourcing Provider', width: '70%', data: this.outsourcing });
    }

    processOutsourcingModel(outsourcing) {
        if (outsourcing.company) outsourcing.company = outsourcing.company.id;
        if (outsourcing.department) outsourcing.department = outsourcing.department.id;
        if (outsourcing.service) outsourcing.service = outsourcing.service.id;
        return outsourcing;
    }

    deleteOutsourcing(outsourcing) {
        let ix = this.indexOf(this.provider.outsourcings, outsourcing);
        this.provider.outsourcings.splice(ix, 1);
        this.storeProvider();
    }

    appendOutsourcing(outsourcing: any) {
        this.addOrUpdate(this.provider.outsourcings, outsourcing);
        if (outsourcing.createdOn) {
            outsourcing.createdOn = Dates.toISODateTime(outsourcing.createdOn);
        } else {
            outsourcing.createdOn = Dates.toISODateTime(new Date());
        }
        this.storeProvider()
        this.ref.close();
    }

    closeOutsourcing() {
        this.ref.close();
    }



}