import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '../primeng/primeng.module';
import { ContactsModule } from '../contacts/contacts.module';
import { CaseListComponent } from './list/caselist.component';
import { CaseFormComponent } from './form/caseform.component';
import { CasesService } from './cases.service';
import { CasesRoutes } from './cases.routing';
import { AccidentComponent } from './form/accident/accident.component';
import { PlaintiffcarComponent } from './form/plaintiffcar/plaintiffcar.component';
import { PlaintiffComponent} from './form/plaintiff/plaintiff.component';
import { WitnessComponent} from './form/witness/witness.component';
import { VehicleComponent} from './form/vehicle/vehicle.component';
import { DefendantComponent} from './form/defendant/defendant.component';
import {IntakeUseCase} from './usecase/IntakeUseCase';
import { DialogService } from 'primeng/dynamicdialog';
import { LiabilityComponent } from './form/defendant/liability/liability.component';
import { ComplaintComponent } from './form/plaintiff/complaint/complaint.component';
import { InsuranceComponent } from './form/insurance/insurance.component';
import { CarExpenseComponent } from './form/vehicle/expense/carexpense.component';
import { NoteComponent } from './form/plaintiff/note/note.component';
import { InsuranceNoteComponent } from './form/insurance/note/inote.component';
import { VehicleNoteComponent } from './form/vehicle/vnote/vnote.component';
import { DocumentComponent } from './form/document/document.component';
import { AdjusterComponent } from './form/insurance/adjuster/adjuster.component';
import { NDefendantComponent } from './form/insurance/defendant/defendant.component';
import { LoeComponent } from './form/plaintiff/LOE/loe.component';
import { DisabilityComponent } from './form/plaintiff/Disability/disability.component';
import { BlobStorageService } from './blobstorage.service';



@NgModule({
    declarations: [
        AccidentComponent,
        CarExpenseComponent,
        CaseListComponent,
        CaseFormComponent,
        ComplaintComponent,
        DefendantComponent,
        DocumentComponent,
        LiabilityComponent,
        NoteComponent,
        PlaintiffComponent,
        PlaintiffcarComponent,
        WitnessComponent,
        VehicleComponent,
        VehicleNoteComponent,
        InsuranceComponent,
        InsuranceNoteComponent,
        AdjusterComponent,
        NDefendantComponent,
        LoeComponent,
        DisabilityComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNGModule,
        ContactsModule,
        RouterModule.forChild(CasesRoutes)
    ],
    providers: [
        CasesService, IntakeUseCase, BlobStorageService, DialogService
    ]
})
export class CasesModule {

}
