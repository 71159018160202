import * as moment from 'moment';

export default class Dates {

    static fromISO(str : string) : Date {
        return moment(str, 'YYYY-MM-DD').toDate();
    }

    static toISO(date: Date) : string {
        return moment(date).format('YYYY-MM-DD');
    }

    static fromISODateTime(str : string) : Date {
        return moment(str).toDate();
    }

    static toISODateTime(date: Date) : string {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss');
    }

    static getAge(dob: string) : number {
        return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
    }

    static getDDiff(date: Date) : string {
        let dateObj = moment(date, 'YYYY-MM-DD');
        let now = moment();
    
        let years = dateObj.diff(now, 'years');
        now.add(years, 'years');
    
        let months = dateObj.diff(now, 'months');
        now.add(months, 'months');
    
        let days = dateObj.diff(now, 'days')+1;
    
        let result = [];
        if (years > 0) result.push(years + ' years');
        if (months > 0) result.push(months + ' months');
        if (days > 0) result.push(days + ' days');
        if (days < 0) result.push('Expired');

        console.log(result.join(' '));
        return result.join(' ');
    }
}