import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AppBreadcrumbService } from '../../../app.breadcrumb.service';
import { CasesService } from '../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';
import Dates from 'src/app/utilities/Dates';
import { FgEventRenderer } from '@fullcalendar/core';
import { TabView } from 'primeng/tabview';


@Component({
    selector: 'plaintiff',
    templateUrl: './plaintiff.component.html',
    styleUrls: ['./plaintiff.component.scss']
})
export class PlaintiffComponent implements OnInit, OnDestroy {
    plaintiff: any;
    complaints = [];  
    statusPlaintiffNotes = [];
    loes=[];
    disabilities=[];
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];  
    languages = [];
    

    languageLevels: any[] = [
        {name:'None', label:'None'},
        {name:'Basic', label:'Basic'},
        {name:'Medium', label:'Medium'}, 
        {name:'Fluent', label:'Fluent'}
    
    ];

    taxDocTypes= [
        {name: 'Social Security', value: 'SS'},
        {name: 'Tax Id', value: 'Tid'},        
        {name: 'None', value: 'None'}
    ];

    personalIdTypes: any[] = [        
        {name: 'State Id', value: 1},
        {name: 'Passport', value: 2},        
        {name: 'Native Country Id', value: 3},
        {name: 'Casa Maryland', value: 4},
        {name: 'None', value: 5},
    ];

    yesno: any[] = [
        {name: 'Yes', value: 'y'},
        {name: 'No', value: 'n'}
    ];

    entitleTo: any[] = [
        {name: 'Part A', value: 'A'},
        {name: 'Part B', value: 'B'}
    ];

    maritalStatus: any[] = [
        {name: 'Single', value: 'S'},
        {name: 'Married', value: 'M'},
        {name: 'Divorced', value: 'D'},
        {name: 'Separated', value: 'SE'},
        {name: 'Domestic Partners', value: 'DP'},
        {name: 'Widowed', value: 'W'}
    ];

    econtactRel: any[] = [
        {name: 'Son/Daughter', value: 1},
        {name: 'In Law', value: 2},        
        {name: 'Boyfriend/Girlfriend', value: 3},
        {name: 'Friend', value: 4},
        {name: 'Sibling', value: 5},
        {name: 'Parent', value: 6},
        {name: 'N/A', value: 7}
    ];

    lmessagesTo: any[] = [
        {name: 'Text', value: 't'},
        {name: 'Whatsapp', value: 'w'}        
    ];

    orderLetter: any[] = [
        {name: 'A', value: 'A'},
        {name: 'B', value: 'B'},
        {name: 'C', value: 'C'},
        {name: 'D', value: 'D'},
        {name: 'E', value: 'E'},
        {name: 'F', value: 'F'}        
    ];

    hasInsurancev: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];

    plaintiffStatuses:any[]=[
        {name: 'Treating', value: 'T'},
        {name: 'Gathering Bills and Records', value: 'GBR'},
        {name: 'Ready for Demand', value: 'RD'},
        {name: 'Pending Demand', value: 'PD'},
        {name: 'Demand Sent', value: 'DS'},
        {name: 'Offer', value: 'O'},
        {name: 'Pending settlement', value: 'PS'},
        {name: 'Settled', value: 'S'},
        {name: 'Litigation', value: 'L'},
        {name: 'Pending Litigation', value: 'PL'},
        {name: 'Changed Attorney', value: 'CHA'},
        {name: 'Dumped', value: 'D'},    
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            _id:[],           
            person: fb.group({
                id:[],
                firstName: [null, Validators.required],
                middleName:[null,""],
                lastName: [null, Validators.required],
                sex: [null, Validators.required],
                dob: [null, Validators.required],
                language: [null, Validators.required],
                languageLevel: [null, Validators.required],                        
                taxDocType: [],
                personalIdType: [],
                personalIdNo: [],
                driverLicenseNo: [],
                licenseState:[],
                idNumber:[],
                phoneNumber:[],
                messagesTo:[],
                optionalPhoneNumber:[],
                emailAddress:[],
                erContactName:[],
                erContactRelation:[],
                address: fb.group({
                    country: [],
                    street:[],
                    apartment:[],
                    city:[],
                    state:[],
                    zipcode:[],
                })
            }),       
            hasInsurance:[],                 
            medicareHolder:[],
            referenceNo:[],
            orderLetter:[],
            medicareNumber:[],
            entitleTo:[],
            healthInsurance:[],            
            healthInsurancePolicy:[],
            medicaid:[],
            priorAccidents:[],
            maritalStatus:[],
            spouseName:[],
            spousePhoneNumber:[],
            spouseEmail:[],
            notClaim:[],
            pregnant:[],
            weeksPregnant:[],
            plaintiffStatus:[],
            statusChangeDate:[],
            spouseEnglish:[],
            comments:[],
            policy: fb.group({
                id:[],
                policyNumber:[],
                biEachPerson:[],
                biEachOccurrence:[],
                propertyDamageLiability:[],
                umBIEachPerson:[],
                umBIEachOccurrence:[],
                umPDDeductible:[],
                umPD:[],
                umCollision:[],
                gapInsurance:[],
                rentalInsurance:[],
            })
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }



    ngOnInit() {
        this.subscriptions.push(this.intakeUseCase.plaintiffChange.subscribe(plaintiff => this.updateModel(plaintiff)));
        if(this.config.data) {
            this.updateModel(this.config.data);    
        }
                
        this.catalogsService.findLanguagesAll().then(response => this.languages = response);
        
        this.mainForm.get('hasInsurance').valueChanges.subscribe((value: boolean) => 
        {
            if (!value) {
              this.mainForm.get('medicareHolder').setValue(null);
              this.mainForm.get('medicareNumber').setValue(null);
              this.mainForm.get('healthInsurance').setValue(null);
              this.mainForm.get('healthInsurancePolicy').setValue(null);
              this.mainForm.get('medicaid').setValue(null);
            }
        });             
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    updateModel(plaintiff) {
        this.plaintiff = plaintiff;
        this.complaints = plaintiff.complaints;        
        this.statusPlaintiffNotes = plaintiff.statusPlaintiffNotes;   
        this.loes=plaintiff.loes;   
        this.disabilities=plaintiff.disabilities;          
        this.mainForm.patchValue(plaintiff); 
    }


    // get case() : any {
    //     return this.intakeUseCase.case;
    // }

    // getNotesHeader(): string {        
    //     const NotesCount = this.case.plaintiffs.statusPlaintiffNotes ? this.case.plaintiffs.statusPlaintiffNotes.length : 0;
    //     return `2.Status Notes (${NotesCount})`;
    //   }
    
    store() {        
        if(this.mainForm.valid) {
            this.intakeUseCase.appendPlaintiff(Object.assign(this.plaintiff, this.mainForm.value));
        }
        this.clean();
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closePlaintiff();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

    /*Complaint */
    addComplaint() {
        this.intakeUseCase.addComplaint();
    }

    editComplaint(complaint) {
        this.intakeUseCase.editComplaint(complaint);
    }
    deleteComplaint(complaint) {
        this.intakeUseCase.deleteComplaint(complaint);
    }

    /*Note*/
    addNote() {
        this.intakeUseCase.addNote();
    }

    editNote(note) {
        this.intakeUseCase.editNote(note);
    }
    
    /*Loe*/
    addLoe() {
        this.intakeUseCase.addLoe();
    }

    editLoe(loe) {
        this.intakeUseCase.editLoe(loe);
    }

     /*Disability*/
     addDisability() {
        this.intakeUseCase.addDisability();
    }

    editDisability(disability) {
        this.intakeUseCase.editDisability(disability);
    }

    // getNotesHeader(): string {
    //     if (this.plaintiff !== null && this.plaintiff !== undefined) {
    //     const notesCount = this.plaintiff ? this.plaintiff.statusPlaintiffNotes.length : 0;
    //      if (notesCount === 0) {
    //          return "2. Status Notes (No notes available)";
    //      }
    //     return `2. Status Notes (${notesCount})`;
    //     }
    // }
    // getLOEHeader(): string {        
    //     const loeCount = this.plaintiff ? this.plaintiff.loes.length : 0;
    //     if (loeCount === 0) {
    //         return "4. LOE (0)";
    //     }
    //     return `4. LOE (${loeCount})`;
    //   }      

    // getslipsHeader(): string {        
    //     const slipsCount = this.plaintiff ? this.plaintiff.disabilities.length : 0;
    //     if (slipsCount === 0) {
    //         return "5. Disability Slips (0)";
    //     }
    //     return `5. Disability Slips (${slipsCount})`;
    //   }      

    // getComplaintsHeader(): string {
    //     const complaintsCount = this.plaintiff ? this.plaintiff.complaints.length : 0;
    //     if (complaintsCount === 0) {
    //         return "7. Complaints (0)";
    //     }
    //     return `7. Complaints (${complaintsCount})`;
    //   }
}
