<div [style]="{'height':'950px'}" [formGroup]="mainForm">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

    <div class="p-col-12"></div> <!-- Forces a new line -->

    <div class="card" style="background-color: powderblue;">
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="serviceType">Service Types</label>
                <p-dropdown id="serviceType" formControlName="serviceType" [options]="serviceType" optionLabel="name"
                    optionValue="value" filter="true" autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="doctype"><label style="color:red">*</label>Services</label>
                <p-dropdown id="doctype" formControlName="doctype" [options]="healthcaredocTypes" optionLabel="name"
                    optionValue="id" filter="true" autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="fee">Fee</label>
                <p-inputNumber mode="currency" currency="USD" id="fee" type="text"
                    formControlName="fee"></p-inputNumber>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="payableTo">Fee Payable To</label>
                <input id="payableTo" type="text" pInputText formControlName="payableTo"
                    autocomplete="doNotAutoComplete">
            </div>
        <!-- </div> -->
        <!-- <div class="p-fluid p-grid" style="display: flex; justify-content: flex-end;"> -->
            <div class="p-field p-col-12 p-md-2">
                <label for="creationDate"><label></label>Creation Date</label>
                <p-calendar id="creationDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy"
                    [hourFormat]="12" formControlName="creationDate" disabled="true"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="createdBy"><label></label>Created By</label>
                <input id="createdBy" type="text" pInputText formControlName="createdBy" readonly
                    autocomplete="doNotAutoComplete">
            </div>
        <!-- </div> -->
    </div>
</div>

    <p-table #dtc [value]="requestMethods" dataKey="id" selectionMode="single"
        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        reorderableColumns="true" sortField="createdOn" sortOrder="-1" [scrollable]="true" scrollHeight="600px"
        [filterDelay]="0" [rows]="4" [paginator]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>Contact</th>
                <th>Priority</th>
                <th>Status</th>
                <th>
                    <p-button label="Add a New Request Method" icon="pi pi-plus"
                        styleClass="p-button-rounded p-button-text" (click)="addRequestMethod()"></p-button>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-object>
            <tr [pSelectableRow]="note">
                <td>
                    <span>
                        <i *ngIf="object.reqmethodBy == 2" class="pi pi-inbox"></i>
                        <i *ngIf="object.reqmethodBy == 4" class="pi pi-send"></i>
                        <i *ngIf="object.reqmethodBy == 1" class="pi pi-phone"></i>
                        <i *ngIf="object.reqmethodBy == 3" class="pi pi-globe"></i>
                    </span>
                    <span> {{object.fax || object.emailAddress || getAddressString(object.address) ||
                        object.portal.name}}</span>
                </td>
                <td>{{object.priorityLevel}}</td>
                <td>{{object.status}}</td>
                <td>
                    <p-button type="button" label="Edit Info." icon="pi pi-pencil"
                        styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button"
                        (click)="editRequestMethod(object)"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                Total of {{requestMethods ? requestMethods.length : 0 }}.
            </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No records available.</td>
            </tr>
        </ng-template>
    </p-table>