import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import Dates from 'src/app/utilities/Dates';
import { Directive, ElementRef, HostListener} from '@angular/core';

@Component({
    selector: 'loe',
    templateUrl: './loe.component.html',
    styleUrls: ['./loe.component.scss']
})
export class LoeComponent implements OnInit {
    loe: any;
    mainForm: FormGroup;    

    eStatus: any[] = [
        {name: 'Fulltime', value:'Fulltime'},
        {name: 'Partime', value: 'Partime'},        
    ];

    yesnoOp: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            jobTitle:[],
            companyName:[],
            authorizedBy:[],
            authorizedOn:[],
            phone:[],
            title:[],
            email:[],
            address: fb.group({
                country: [],
                street:[],                
                city:[],
                state:[],
                zipcode:[],
            }),
            employmentStatus:[null, Validators.required],
            startDate:[],
            endDate:[],
            present:[],
            absentFrom:[null, Validators.required],
            absentTo:[null, Validators.required],
            hoursDay:[],
            daysWorkWeek:[],
            daysAbsent:[],
            rateOfPay:[],
            rateType:[],
            totalLOE:[],
            loeOnFile:[],
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false,
            // comments:[null, Validators.required],
        });
        this.mainForm.get("absentFrom").valueChanges.subscribe(v => this.updatePostDaysAbsent());
        this.mainForm.get("absentTo").valueChanges.subscribe(v => this.updatePostDaysAbsent());
       // this.mainForm.get("daysAbsent").valueChanges.subscribe(v => this.updatePostDaysAbsent());      
        this.mainForm.get("totalLOE").valueChanges.subscribe(v => this.updatePostDaysAbsent());     
        this.mainForm.get("daysWorkWeek").valueChanges.subscribe(value => {
            this.calculateTotalLOE(this.mainForm.get("daysAbsent").value, this.mainForm.get("hoursDay").value, this.mainForm.get("rateOfPay").value);
          });
        
          this.mainForm.get("hoursDay").valueChanges.subscribe(value => {
            this.calculateTotalLOE(this.mainForm.get("daysAbsent").value, this.mainForm.get("hoursDay").value, this.mainForm.get("rateOfPay").value);
          });
        
          this.mainForm.get("rateOfPay").valueChanges.subscribe(value => {
            this.calculateTotalLOE(this.mainForm.get("daysAbsent").value, this.mainForm.get("hoursDay").value, this.mainForm.get("rateOfPay").value);
          }); 
          
      }
    
    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.loe = this.config.data;
        }       
    }
    
    store() {
        if(this.mainForm.invalid) {
            Object.values(this.mainForm.controls).forEach(control => {
                control.markAsTouched();
            });
        }
        if(this.mainForm.valid) {
            this.intakeUseCase.appendLoe(this.mainForm.value);
            this.clean();
        }
    }

    updatePostDaysAbsent() {   
        var daysDifference = moment(this.mainForm.get("absentTo").value).diff(moment(this.mainForm.get("absentFrom").value), 'days')+1;
        if (daysDifference> 0)
        {        
            var i=0;
            var xdaysExc=0;
            while(i<=daysDifference)
                {
                    if (i % 7 === 0) 
                    {xdaysExc++;} i++
                }
                //daysDifference=(daysDifference-(xdaysExc*2))+2;
                daysDifference=(daysDifference-(xdaysExc*(7-this.mainForm.get("daysWorkWeek").value)))+2;
                this.mainForm.patchValue({daysAbsent: daysDifference});
        }
        else {
            this.mainForm.patchValue({daysAbsent:0});
        }
    }

    calculateTotalLOE(daysAbsent: number, hoursWorkedInDay: number, rateOfPay: number) {
        const totalLOE = daysAbsent * hoursWorkedInDay * rateOfPay;
        this.mainForm.patchValue({ totalLOE: totalLOE });
      }




    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeLoe();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }

}