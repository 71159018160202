import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { Directive, ElementRef, HostListener} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'defendant',
    templateUrl: './defendant.component.html',
    styleUrls: ['./defendant.component.scss']
})
export class NDefendantComponent implements OnInit {
    defendant: any;
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];  

    defendantTypes: any[] = [
        {name: 'Driver', value:'Driver'},
        {name: 'Insured', value:'Insured'},
    ];

    sexList: any[] = [
        {name: true, value:'Male'},
        {name: false, value:'Female'},
        {name: null, value:''},
    ];

    personalIdTypes: any[] = [
        {name: 'State Id', value: 1},
        {name: 'Passport', value: 2},        
        {name: 'Native Country Id', value: 3},
        {name: 'Casa Maryland', value: 4},
        {name: 'Not Available', value: 5},
    ];
       
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            name:[null, Validators.required],
            dob:[],
            phone:[],                    
            email:[],
            isCompany:[],
            isex:[],
            defendantType:[null, Validators.required],
            noDriverLicense:[],
            authorizedDriver:[],
            wasGivenTicket:[],
            duiCharges:[],
            takenByPolice:[],
            driverAlsoInsured:[],
            driverLicenseNo:[],
            licenseState:[],
            taxDocType:[],
            idNumber:[],
            address: fb.group({
                country: [],
                street:[],                      
                apartment:[],
                city:[],
                state:[],
                zipcode:[],
            })                    
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);            
        }
    }

    get addressEnabled() : boolean {
        return this.mainForm.get('address') != null;
    }

    store() {
        if(this.mainForm.valid) {
            this.intakeUseCase.appendNDefendant(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeAdjuster();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }
    
}