import {Component, OnInit, ViewChild} from '@angular/core';
import {Customer, Representative} from '../../demo/domain/customer';
import {CustomerService} from '../../demo/service/customerservice';
import {Product} from '../../demo/domain/product';
import {ProductService} from '../../demo/service/productservice';
import {Table} from 'primeng/table';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';
import { PortalService } from '../../demo/service/portalservice';
import { Router } from '@angular/router';

@Component({
    templateUrl: './clientlist.component.html',
    styleUrls: ['./tabledemo.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
})
export class ClientListComponent implements OnInit {
    persons : any[];

    customers1: Customer[];

    customers2: Customer[];

    customers3: Customer[];

    selectedCustomers1: Customer[];

    selectedCustomer: Customer;

    representatives: Representative[];

    statuses: any[];

    products: Product[];

    rowGroupMetadata: any;

    @ViewChild('dt') table: Table;

    activityValues: number[] = [0, 100];

    constructor(private router: Router, private customerService: CustomerService, private productService: ProductService,
                private breadcrumbService: AppBreadcrumbService, private portalService: PortalService) {
        this.breadcrumbService.setItems([
            { label: 'Inquiries' },
            { label: 'Clients', routerLink: ['/uikit/table'] }
        ]);
    }

    ngOnInit() {
        this.portalService.findAll().then(persons => {
            this.persons = persons;
        });

        this.representatives = [
            {name: 'Amy Elsner', image: 'amyelsner.png'},
            {name: 'Anna Fali', image: 'annafali.png'},
            {name: 'Asiya Javayant', image: 'asiyajavayant.png'},
            {name: 'Bernardo Dominic', image: 'bernardodominic.png'},
            {name: 'Elwin Sharvill', image: 'elwinsharvill.png'},
            {name: 'Ioni Bowcher', image: 'ionibowcher.png'},
            {name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png'},
            {name: 'Onyama Limba', image: 'onyamalimba.png'},
            {name: 'Stephen Shaw', image: 'stephenshaw.png'},
            {name: 'XuXue Feng', image: 'xuxuefeng.png'}
        ];

        this.statuses = [
            {label: 'Unqualified', value: 'unqualified'},
            {label: 'Qualified', value: 'qualified'},
            {label: 'New', value: 'new'},
            {label: 'Negotiation', value: 'negotiation'},
            {label: 'Renewal', value: 'renewal'},
            {label: 'Proposal', value: 'proposal'}
        ];
    }

    onSort() {
        this.updateRowGroupMetaData();
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};

        if (this.customers3) {
            for (let i = 0; i < this.customers3.length; i++) {
                const rowData = this.customers3[i];
                const representativeName = rowData.representative.name;

                if (i === 0) {
                    this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
                }
                else {
                    const previousRowData = this.customers3[i - 1];
                    const previousRowGroup = previousRowData.representative.name;
                    if (representativeName === previousRowGroup) {
                        this.rowGroupMetadata[representativeName].size++;
                    }
                    else {
                        this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
                    }
                }
            }
        }
    }

    findIndexById(id: string): number {
        let index = -1;
        for (let i = 0; i < this.persons.length; i++) {
            if (this.persons[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    addNew() {
        this.router.navigate(['/clients/form'])
    }

    edit(person) {
        this.router.navigate(['/clients/form/', person.id])
    }
}
