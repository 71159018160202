import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CasesService } from '../../cases.service';
import { ControlContainer, FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import Dates from "src/app/utilities/Dates";
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IntakeUseCase } from '../../usecase/IntakeUseCase';
import { Directive, ElementRef, HostListener} from '@angular/core';

@Component({
    selector: 'accident',
    templateUrl: './accident.component.html',
    styleUrls: ['./accident.component.scss']
})
export class AccidentComponent implements OnInit {
    formGroup: FormGroup
    subscriptions: Subscription[] = [];

    weatherConditions: any[] = [
        {name: 'Clear', value: '1'},
        {name: 'Rain', value: '2'},
        {name: 'Rain Storm', value: '3'},
        {name: 'Drizzle - light rain', value: '4'},
        {name: 'Snow', value: '5'},        
        {name: 'Snow Storm', value: '6'},
        {name: 'Fog', value: '7'}
    ];


    yesno: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];

    policeArrivedYN:any[]=[
        {name: 'Yes', value:true},
        {name: 'No', value: false},        
    ];

    exchInfoOpt:any[]=[
        {name: 'Pending', value:'p'},
        {name: 'Client will Provide', value: 'cwp'},
        {name: 'On File', value: 'of'},
        {name: 'None available', value: 'na'},
    ];

    constructor(private fb: FormBuilder, private intakeUseCase : IntakeUseCase, private config: DynamicDialogConfig, private casesService: CasesService) {
        this.formGroup = fb.group({
            id:[],
            date:[null, Validators.required],
            accidentTime:[null, Validators.required],
            weatherConditions: [],
            speedLimit:[],
            speedTravel:[],
            cFrom:[null, Validators.required],
            gCoordinates:[],
            goingTo:[null, Validators.required],
            noLanes:[null, Validators.required],
            laneLocation:[null, Validators.required],
            policeArrived:[],
            investigator:[],
            investigatorPh:[],
            investigatorCounty:[],
            descriptionAccident:[null, Validators.required],
            address: fb.group({
                country: [null],
                street:[],
                city:[''],
                county:[],
                state:[null, Validators.required],
                // zipcode:[],
            }),
            policeReport: fb.group({
                id:[],
                exchInfoOnFile:[],
                exchInfo:[],
                dateRequested:[],
                dateReceived:[],
                dateRejected:[],
                reportNo:[],
                status:[],
                comments:[],
            })    
        })
    }

    get valid() : boolean {
        return this.formGroup.valid;
    }

    ngOnInit() { 
        if(this.config.data) {
            this.formGroup.patchValue(this.config.data);
        }
    }

    

    store() {
        if(this.formGroup.value.timeAcc) {
            this.formGroup.value.timeAcc= moment(this.formGroup.value.timeAcc).format('YYYY-MM-DD HH:MM AM/PM');
        }   

        // if(this.formGroup.value.accidentDateTime) {
        //     this.formGroup.value.accidentDateTime = Dates.toISODateTime(this.formGroup.value.accidentDateTime); 
        // } else {
        //     this.formGroup.value.accidentDateTime = Dates.toISODateTime(new Date());
        // }

        if(this.formGroup.valid) {
            this.intakeUseCase.accidentEdited(this.formGroup.value);
        }
        this.clean();
    }

    cancel() {
        this.intakeUseCase.closeAccident();
        this.clean();
    }

    clean() {

    }

    policeReportWillNotBeCompleted() {
        this.casesService.policeReportWillNotBeCompleted(this.intakeUseCase.case.id)
        .then(() => this.casesService.findById(this.intakeUseCase.case.id))
        .then(c => {
            this.intakeUseCase.case = c; 
            this.formGroup.patchValue(this.intakeUseCase.processAccidentModel(c.accident));
        });
    }

    policeReportReceived() {
        this.casesService.policeReportReceived(this.intakeUseCase.case.id)
        .then(() => this.casesService.findById(this.intakeUseCase.case.id))
        .then(c => {
            this.intakeUseCase.case = c; 

            this.formGroup.patchValue(this.intakeUseCase.processAccidentModel(c.accident));
        });
    }
    // Example coordinates
  latitude: number = 40.712776; // Replace with your latitude
  longitude: number = -74.005974; // Replace with your longitude

  openGoogleMaps() {
    // Split the 'gCoordinates' form control value into latitude and longitude
    const [latitude, longitude] = this.formGroup.get('gCoordinates').value.split(',');
    // Use the split latitude and longitude for the URL
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, '_blank');
    console.log(latitude, longitude); // Log the latitude and longitude to verify
  }
  
  openStreetView() {
    // Split the 'gCoordinates' form control value into latitude and longitude
    const [latitude, longitude] = this.formGroup.get('gCoordinates').value.split(',');
    // Use the split latitude and longitude for the URL
    const url = `https://www.google.com/maps?layer=c&cbll=${latitude},${longitude}`;
    window.open(url, '_blank');
  }
}

@Directive({
    selector: '[appCharacterCounter]'
  })
  
  export class CharacterCounterDirective {

    @Input() maxLength: number;
  
    constructor(private el: ElementRef) { }
  
    @HostListener('input', ['$event.target'])
    onInput(textArea: HTMLTextAreaElement): void {
      if (textArea.value.length > this.maxLength) {
        textArea.value = textArea.value.substring(0, this.maxLength);
      }
    }
  }
