import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '../primeng/primeng.module';
import { ContactsModule } from '../contacts/contacts.module';
import { ProviderListComponent } from './list/providerlist.component';
import { ProviderFormComponent } from './form/providerform.component';
import { ProvidersService } from './providers.service';
import { ProvidersRoutes } from './providers.routing';
import { LocationComponent } from './form/location/location.component';
import { DepartmentComponent } from './form/department/department.component';
import { ServiceComponent } from './form/department/service/service.component';
import { NoteComponent } from './form/note/note.component';
import { RecordComponent } from './form/record/record.component';
import { HealthCareUseCase} from './usecase/HealthCareUseCase';
import { DialogService } from 'primeng/dynamicdialog';
import { BlobStorageService } from './blobstorage.service';
import { ProviderDocumentComponent } from './form/document/document.component';
import { RequestMethodComponent } from './form/department/service/requestmethod/requestmethod.component';
import { OutsourcingComponent } from './form/outsourcing/outsourcing.component';

import { TooltipModule } from 'primeng/tooltip';


@NgModule({
    declarations: [
        LocationComponent, 
        DepartmentComponent,
        NoteComponent,
        ProviderDocumentComponent,
        ServiceComponent,
        RecordComponent,       
        ProviderListComponent,
        ProviderFormComponent, 
        RequestMethodComponent,       
        OutsourcingComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule,
        ReactiveFormsModule,
        PrimeNGModule,
        ContactsModule,        
        RouterModule.forChild(ProvidersRoutes)
    ],
    providers: [
        ProvidersService, HealthCareUseCase, BlobStorageService, DialogService
    ]
})
export class ProvidersModule {

}
