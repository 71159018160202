import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class BlobStorageService {
    constructor(private http: HttpClient){

    }

    storeBlob(providerId, type, blob) : Promise<any> {
        let formData = new FormData();
        formData.append('file', blob);
        return this.http.post<any>(environment.clientsEndpointUrl + '/healthcareproviderblobs/?providerId=' + providerId 
            + '&blobType=' + type, formData).toPromise();
    }

   // deleteBlob(docuemnt): Promise<any> {
     //   return environment.clientsEndpointUrl + "/blobs/public/gs/" + document.fileInfo.uri.replace("gs://", "");
      //}      


}