import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvidersService } from '../../../providers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HealthCareUseCase } from '../../../usecase/HealthCareUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { CatalogsService } from 'src/app/catalogs/catalogs.service';
import { Subscription } from 'rxjs';
import { Addresses } from 'src/app/utilities/Addresses';

@Component({
    selector: 'service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
    service: any;
    requestMethods = [];
    mainForm: FormGroup;
    subscriptions: Subscription[] = [];
    servicesTypes = [];
    healthcaredocTypes = [];

    serviceType: any[] = [
        { name: 'General Care', value: 'G' },
        { name: 'Inpatient', value: 'I' },
        { name: 'Outpatient', value: 'O' },
        { name: 'Emergency', value: 'E' }
    ];

    resultBy: any[] = [
        { name: 'N/A', value: 0 },
        { name: 'Fax', value: 1 },
        { name: 'Mail', value: 2 },
        { name: 'Portal', value: 3 },
        { name: 'Email', value: 4 }
    ];

    priorities: any[] = [
        { name: 'N/A', value: 0 },
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 }
    ];

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private zone: NgZone,
        private providersService: ProvidersService, private healthcareUseCase: HealthCareUseCase,
        private catalogsService: CatalogsService,
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id: [],
            _id: [],
            serviceType: [],
            service: [],
            doctype: [null, Validators.required],
            creationDate: [],
            createdBy: [],
            comments: [],
            fee: [],
            payableTo: [],
        });

        this.subscriptions.push(this.healthcareUseCase.serviceChange.subscribe(service => this.zone.run(() => this.updateModel(service))));
    }

    get valid(): boolean {
        return this.mainForm.valid;
    }

    get addressEnabled(): boolean {
        return this.mainForm.get('address') != null;
    }

    ngOnInit() {
        if (this.config.data) {
            this.updateModel(this.config.data);
        }
        this.catalogsService.findDocTypesAll().then(healthcaredocTypes => this.healthcaredocTypes = healthcaredocTypes);
    }


    updateModel(service) {
        this.service = service;
        this.requestMethods = service.requestMethods;
        this.mainForm.patchValue(service);
    }

    store() {
        if (this.mainForm.valid) {
            this.healthcareUseCase.storeService(Object.assign(this.service, this.mainForm.value));
            this.clean();
        }
    }

    activate() {

    }

    inactivate() {

    }

    /*Request Method*/
    addRequestMethod() {
        if (this.mainForm.valid) {
            if (this.mainForm.dirty) {
                this.healthcareUseCase.appendService(Object.assign(this.service, this.mainForm.value));
            }
            this.healthcareUseCase.addRequestMethod();
        }
    }

    editRequestMethod(RequestMethod) {
        this.healthcareUseCase.editRequestMethod(RequestMethod);
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.healthcareUseCase.closeService();
        this.clean();
    }

    getAddressString(address: any): string {
        return Addresses.toText(address);
    }
}
