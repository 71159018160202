<div [formGroup]="mainForm">
    <person formGroupName="person"></person>
    <br><br>
    <div class="card"> 
        <h5>Contact Information</h5>
        <div formGroupName="person" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="phoneNumber">Mobile phone</label>
                <p-inputMask id="phoneNumber" mask="(999) 999-9999" formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>
                        
            <div class="p-field p-col-12 p-md-2">
                <label for="emailAddress">Email</label>
                <input id="emailAddress" type="text" pInputText formControlName="emailAddress" autocomplete="doNotAutoComplete">
            </div>
            
            
            <div class="p-field p-col-12 p-md-2">
                <label for="optionalPhoneNumber">Optional phone</label>
                <p-inputMask id="optionalPhoneNumber" mask="(999) 999-9999" formControlName="optionalPhoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>
        </div>
    </div>
</div>

<!-- style="background-color: whitesmoke;" -->



<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>
