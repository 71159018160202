<div [formGroup]="mainForm">       
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary"(click)="cancel()"></button>  
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>
    
    <div class="p-col-12"></div> <!-- Forces a new line -->        

    <div class="card" style="background-color: white;">
        <div class="p-grid p-fluid">            
            <div class="p-field p-col-12 p-md-3">
                <label for="recType"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Service</label>
                <p-dropdown formControlName="recType" [options]="recTypes" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="deptName"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Dept. Name</label>
                <input id="deptName" type="text" pInputText formControlName="deptName" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="phoneNumber"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Office phone</label>
                <p-inputMask id="phoneNumber" mask="(999) 999-9999"  formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                <!-- unmask="true" -->
            </div>                        
            <div class="p-field p-col-12 p-md-2">
                <label for="status"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Status</label>
                <p-dropdown formControlName="status" [options]="statuses" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div> 
            <div class="p-field p-col-12 p-md-2">
                <label for="statusChangeDate"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Last Status Changed On</label>
                <p-calendar id="statusChangeDate"showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="statusChangeDate" disabled="true"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-1">        
                <label for="createdBy"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Created By</label>
                <input id="createdBy" type="text" pInputText formControlName="createdBy" readonly autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">        
                <label for="creationDate"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Creation Date</label>
                <p-calendar id="creationDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="creationDate" disabled="true"></p-calendar>
            </div>
        </div>
    </div>
</div>