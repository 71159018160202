import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { iif } from 'rxjs';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';
import { CatalogsService } from '../../catalogs/catalogs.service';
import { PortalService } from '../../demo/service/portalservice';

@Component({
    templateUrl: './clientform.component.html'
})
export class ClientFormComponent implements OnInit {
    client: any;

    dob: Date;

    states: any[] = [
        {name: 'Maryland', value: 1},
        {name: 'Virginia', value: 2},
        {name: 'Washington DC', value: 3}
    ];

    sex: any[] = [
        {name: 'Male', value: 'MALE'},
        {name: 'Female', value: 'FEMALE'}
    ];

    languages: any[] = [
        {name: 'English', value: 1},
        {name: 'Spanish', value: 2},
        {name: 'Vietnamese', value: 3},
        {name: 'French', value: 4},        
    ];

    elevel: any[] = [
        {name: 'None', value: 1},
        {name: 'Low Intermediate', value: 2},
        {name: 'High Intermediate', value: 3},
        {name: 'Fluent', value: 4},        
        {name: 'Bilingual', value: 5},        
    ];

    documentType: any[] = [
        {name: 'Social Secutiry', value: '1'},
        {name: 'Tax Id', value: '2'}        
    ];

    taxDocType: any[] = [
        {name: 'Social Security', value: '1'},
        {name: 'Tax Id', value: '2'},
        {name: 'Pending', value: '3'},
        {name: 'None Available', value: '4'}
    ];

    personalIdTypes: any[] = [
        {name: 'Drivers License', value: '1'},
        {name: 'Casa Maryland', value: '2'},
        {name: 'Personal Id', value: '3'},
    ];


    phoneType: any[] = [
        {name: 'Home', value: '1'},
        {name: 'Work', value: '2'}
    ];
    econtactRel: any[] = [
        {name: 'Son/Daughter', value: '1'},
        {name: 'In Law', value: '2'},        
        {name: 'Boyfriend/Girlfriend', value: '3'},
        {name: 'Friend', value: '4'},
        {name: 'Sibling', value: '5'},
    ];
    yesno: any[] = [
        {name: 'Yes', value: true},
        {name: 'No', value: false}
    ];
    entitle: any[] = [
        {name: 'Part A', value: 1},
        {name: 'Part B', value: 2}
    ];
    maritalStatus: any[] = [        
        {name: 'Married', value: '1'},
        {name: 'Divorced', value: '2'},
        {name: 'Most Significant One', value: '3'},
        {name: 'Single', value: '4'},
    ];

    constructor(private breadcrumbService: AppBreadcrumbService, private route: ActivatedRoute, 
                private  portalService: PortalService, 
                        private catalogsService: CatalogsService, private router: Router) {
        this.breadcrumbService.setItems([
            { label: 'Clients' },
            { label: 'Personal Information', routerLink: ['/uikit/formlayout'] }
        ]);
    }

    ngOnInit(): void {
        this.client = {person:{address:{}}};
        
        this.route.paramMap.subscribe(params => {
            let personId = params.get("id");
            if(personId) {
                this.portalService.findById(personId).then(client => {
                    this.client = client;
                    if(this.client.dob) {
                        this.dob = moment(this.client.dob, 'YYYY-MM-DD').toDate();
                    }
                });
            }
        })

        //Este alimenta las listas de valores por medio de los catalogos
        this.catalogsService.findIdTypesAll().then(types => this.personalIdTypes = types);
        /*this.catalogsService.findLanguagesAll().then(response => this.languages = response);*/
    }

    store() {
        if(this.dob) {
            this.client.dob = moment(this.dob).format('YYYY-MM-DD');
        }
        this.portalService.store(this.client)
         .then(() => this.router.navigate(['/clients']));
         
    }

    cancel(){
        this.router.navigate(['/clients']);
    }


}
