import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Country, State, City }  from 'country-state-city';
import { ControlContainer, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'address',
    templateUrl: './addressform.component.html',
    styleUrls: ['./addressform.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
    countries: any[];
    states: any[];
    cities: any[];
    counties: any[];

    formGroup: FormGroup
    subscriptions: Subscription[] = [];

    constructor(private controlContainer: ControlContainer) {
        
    }

    ngOnInit() { 
        this.formGroup = <FormGroup>this.controlContainer.control;
        this.handleValueChanges();

        if(environment.location.countries) {
            this.countries = environment.location.countries.map(country => Country.getCountryByCode(country));
            if(this.countries.length == 1) {
                this.formGroup.patchValue({country: this.countries[0].isoCode});
            }
        } else {
            this.countries = Country.getAllCountries();
        }

        if(!this.formGroup.value.country) {
            this.formGroup.patchValue({country: environment.location.defaults.country});
        }

        if(!this.formGroup.value.state) {
            this.formGroup.patchValue({state: environment.location.defaults.state});
        } else {
            if(this.cityEnabled) {
                this.cities = City.getCitiesOfState(this.formGroup.value.country, this.formGroup.value.state);
            }
            if(this.countyEnabled) {
                this.counties = City.getCitiesOfState(this.formGroup.value.country, this.formGroup.value.state).filter(city => city.name.toLowerCase().indexOf('county') != -1);
            }
        }
    }

    

    handleValueChanges() {
        this.subscriptions.push(this.formGroup.get('country').valueChanges.subscribe(country => {
            this.states = State.getStatesOfCountry(country);

            if (!this.formGroup.pristine) {
                this.formGroup.patchValue({state: null});
            }
        }));

        this.subscriptions.push(this.formGroup.get('state').valueChanges.subscribe(state => {
            if(this.cityEnabled) {
                this.cities = City.getCitiesOfState(this.formGroup.value.country || environment.location.defaults.country, state);
            }

            if(this.countyEnabled) {
                this.counties = City.getCitiesOfState(this.formGroup.value.country || environment.location.defaults.country, state).filter(city => city.name.toLowerCase().indexOf('county') != -1);
            }

            if (!this.formGroup.pristine) {
                this.formGroup.patchValue({city: null});
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    get countryVisible() : boolean {
        return this.countries.length > 1;
    }

    get apartmentEnabled() : boolean {
        return this.formGroup.get('apartment') != null;
    }

    get cityEnabled() : boolean {
        return this.formGroup.get('city') != null;
    }

    get countyEnabled() : boolean {
        return this.formGroup.get('county') != null;
    }
    get zipcodeEnabled() : boolean {
        return this.formGroup.get('zipcode') != null;
    }
}
