<div class="p-grid">
	<div class="p-col-12">

		<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
		<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

		<div class="card" style="background-color: powderblue;">
			<h5>Personal Information</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="firstname">First name</label>
					<input id="firstname" type="text" pInputText [(ngModel)]="client.person.name">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="lastname5">Middle name</label>
					<input id="lastname5" type="text" pInputText [(ngModel)]="client.person.mname">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="lastname6">Last name</label>
					<input id="lastname6" type="text" pInputText [(ngModel)]="client.person.lname">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="dob">Date of Birth</label>
					<p-calendar [(ngModel)]="dob"></p-calendar>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="sex">Sex</label>
					<span class="p-float-label">
						<p-dropdown inputId="sex" [options]="sex" [(ngModel)]="client.person.sex" optionLabel="name"
							optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>

				<div class="p-field p-col-12 p-md-2">
					<label for="language">Language</label>
					<span class="p-float-label">
						<p-dropdown inputId="language" [options]="languages" [(ngModel)]="client.language"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>

				<!-- <div class="p-field p-col-12 p-md-2">
                    <label for="language">Preferred Language</label>
                    <span class="p-float-label">
                        <p-dropdown inputId="language" [options]="language" [(ngModel)]="client.language" optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
                        <label>Choose</label>
                    </span>
				</div> -->

				<div class="p-field p-col-12 p-md-2">
					<label for="eLevel">English Level</label>
					<span class="p-float-label">
						<p-dropdown inputId="eLevel" [options]="elevel" [(ngModel)]="client.eLevel" optionLabel="name"
							optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="address">Street Address</label>
					<input id="address" type="text" pInputText [(ngModel)]="client.person.address.streetAddress">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="apartment">Apartment No. (optional)</label>
					<input id="apartment" type="text" pInputText [(ngModel)]="client.person.apartment">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="city">City</label>
					<input id="city" type="text" pInputText [(ngModel)]="client.person.address.city">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="state">State</label>
					<span class="p-float-label">
						<p-dropdown inputId="state" [options]="states" [(ngModel)]="client.person.address.us_state"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="zip">Zip</label>
					<input id="zip" type="text" pInputText [(ngModel)]="client.person.address.zipcode">
				</div>
			</div>
		</div>

		<div class="card">
			<h5>Identification Documents</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="taxDocType">Tax Type</label>
					<span class="p-float-label">
						<p-dropdown inputId="taxDocType" [options]="taxDocType" [(ngModel)]="client.taxDocType"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="idnum">Id No.</label>
					<p-inputMask mask="999-99-9999" [(ngModel)]="client.idNumber" placeholder="999-99-9999">
					</p-inputMask>
				</div>

				<div class="p-field p-col-12 p-md-2">
					<label for="personalIdType">Personal Id Types</label>
					<span class="p-float-label">
						<p-dropdown inputId="personalIdType" [options]="personalIdTypes" [(ngModel)]="client.personalIdType" 
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="personalIdNo">Personal Id Number</label>
					<p-inputMask mask="***-***-***-***-*" [(ngModel)]="client.personalIdNo" placeholder="999-99-9999">
					</p-inputMask>

				</div>
			</div>
		</div>


		<div class="card" style="background-color: powderblue;">
			<h5>Contact Information</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="cellphone">Mobile phone</label>
					<p-inputMask mask="(999) 999-9999" [(ngModel)]="client.mobilePh"></p-inputMask>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="phType">Optional Phone Type</label>
					<span class="p-float-label">
						<p-dropdown inputId="phoneTyp" [options]="phoneType" [(ngModel)]="client.phType"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="optphone">Optional Phone</label>
					<p-inputMask mask="(999) 999-9999" [(ngModel)]="client.optionalPh"></p-inputMask>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="email">Email</label>
					<input id="email" type="text" pInputText [(ngModel)]="client.email">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="econtact">Emergency Contact (Other than Spouse)</label>
					<input id="econtact" type="text" pInputText [(ngModel)]="client.erContactName">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="ephone">Emergency Contact Phone</label>
					<p-inputMask mask="(999) 999-9999" [(ngModel)]="client.optionalPh"></p-inputMask>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="phType">Emergency Contact Relationship</label>
					<span class="p-float-label">
						<p-dropdown inputId="econtactRel" [options]="econtactRel" [(ngModel)]="client.erContactRelation"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
			</div>
		</div>

		<div class="card">
			<h5>Medicare Insurance Information</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="phType">Medicare Holder?</label>
					<span class="p-float-label">
						<p-dropdown inputId="medicareyesno" [options]="yesno" [(ngModel)]="client.medicareHolder"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="medicareNo">Medicare No</label>
					<p-inputMask mask="999-99-9999-a" [(ngModel)]="client.medicareNo"></p-inputMask>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="entitle">Entitle To</label>
					<span class="p-float-label">
						<p-dropdown inputId="entitle" [options]="entitle" [(ngModel)]="client.entitle"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
			</div>
		</div>

		<div class="card" style="background-color: powderblue;">
			<h5>Spouse Information</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-2">
					<label for="spouseFullName">Full Name</label>
					<input id="sFullName" type="text" pInputText [(ngModel)]="client.spouseName">
				</div>

				<div class="p-field p-col-12 p-md-2">
					<label for="spphone">Phone</label>
					<p-inputMask mask="(999) 999-9999" [(ngModel)]="client.spousePh"></p-inputMask>
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="spemail">Email</label>
					<input id="semail" type="text" pInputText [(ngModel)]="client.spouseEmail">
				</div>
				<div class="p-field p-col-12 p-md-2">
					<label for="maritalStatus">Martial Status</label>
					<span class="p-float-label">
						<p-dropdown inputId="maritalStatus" [options]="maritalStatus" [(ngModel)]="client.maritalStatus"
							optionLabel="name" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
						<label>Choose</label>
					</span>
				</div>
			</div>
		</div>

		<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
		<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>
	</div>
</div>
