<div [formGroup]="formGroup">
        <div class="card" style="background-color: powderblue;">
            <div class="p-grid p-fluid">
                <div class="p-field p-col-12 p-md-2">
                    <label for="firstName"><label style="color:red">*</label> First Name</label>
                    <input id="firstName" type="text" pInputText formControlName="firstName" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="middleName">Middle Name</label>
                    <input id="middleName" type="text" pInputText formControlName="middleName" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="lastName"><label style="color:red">*</label> Last Name</label>
                    <input id="lastName" type="text" pInputText formControlName="lastName" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="dob"><label style="color:red">*</label> Date of Birth</label>
                    <p-calendar id="dob" formControlName="dob"></p-calendar>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label for="sex"><label style="color:red">*</label> Sex</label>
                    <p-dropdown id="sex" formControlName="sex" [options]="genders" optionValue="name" optionLabel="label" filter="true"
                        autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
                </div>
                <!-- <div class="p-field p-md-2">
                    <label for="ssn">Social Security Number</label>
                    <input id="ssn" type="text" pInputText formControlName="ssn">
                </div> -->
            </div>
            <address *ngIf="addressEnabled" formGroupName="address"></address>
        </div>

        <!-- Contact info -->
        <!-- <div *ngIf="formGroup.get('phoneNumber') != null || formGroup.get('optionalPhoneNumber') != null || formGroup.get('emailAddress') != null" class="card">
            <h5>Contact Information</h5>
            <div class="p-grid p-fluid">
                <div *ngIf="formGroup.get('phoneNumber') != null" class="p-field p-col-12 p-md-2">
                    <label for="phoneNumber">Mobile Phone</label>
                    <input id="phoneNumber" pInputText formControlName="phoneNumber">
                    <p-inputMask id="phoneNumber" mask="(999) 999-9999" formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                </div>
                <div *ngIf="formGroup.get('optionalPhoneNumber') != null" class="p-field p-col-12 p-md-2">
                    <label for="optionalPhoneNumber">Optional Phone</label>
                    <input id="optionalPhoneNumber" pInputText formControlName="optionalPhoneNumber">
                    <p-inputMask id="optionalPhoneNumber" mask="(999) 999-9999" formControlName="optionalPhoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                </div>
                <div *ngIf="formGroup.get('emailAddress') != null" class="p-field p-col-12 p-md-2">
                    <label for="emailAddress">Email</label>
                    <input id="emailAddress" pInputText formControlName="emailAddress" autocomplete="doNotAutoComplete">
                </div>
            </div>
        </div> -->
</div>