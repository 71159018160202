import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { ControlContainer, FormGroup } from '@angular/forms';
import Dates from 'src/app/utilities/Dates';

@Component({
    selector: 'person',
    templateUrl: './personform.component.html',
    styleUrls: ['./personform.component.scss']
})
export class PersonFormComponent implements OnInit {
    case: any;     
    formGroup: FormGroup;

    genders = [{name:'FEMALE', label:'Female'}, {name:'MALE', label:'Male'}];
    
    constructor(private controlContainer: ControlContainer, private location: Location, private route: ActivatedRoute) {

    }

    ngOnInit() { 
        this.formGroup = <FormGroup>this.controlContainer.control;
        // Make sure the 'firstName' field is part of the formGroup
        if (this.formGroup.get('firstName')) {
            this.formGroup.get('firstName').valueChanges.subscribe(value => {
                if(value){
                    this.formGroup.get('firstName').setValue(this.capitalize(value), { emitEvent: false });
                }
            });
        }
        // For middleName
    if (this.formGroup.get('middleName')) 
        {
            this.formGroup.get('middleName').valueChanges.subscribe(value => {
                if(value){
                    this.formGroup.get('middleName').setValue(this.capitalize(value), { emitEvent: false });
                }
            });
        }

    // For lastName
        if (this.formGroup.get('lastName')) 
        {
            this.formGroup.get('lastName').valueChanges.subscribe(value => {
                if(value){
                    this.formGroup.get('lastName').setValue(this.capitalize(value), { emitEvent: false });
                }
            });
        }

    }

    // Helper method to capitalize the first letter and make the rest lowercase
    capitalize(str: string) {
        return str.split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ');
    }
    

    get addressEnabled() : boolean {
        return this.formGroup.get('address') != null;
    }

    back() {
        this.case = null;
        this.location.back()
    }

    cancel() {
        this.back();
    }

    activate() {

    }

    inactivate() {

    }

}
