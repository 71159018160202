import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Case Management', icon: 'pi pi-fw pi-home',
                items: [
                    //{label: 'Cases', icon: 'pi pi-chart-bar', routerLink: ['/cases'], badgeClass: 'p-badge-info'},
                    {label: 'Cases', icon: 'pi pi-fw pi-briefcase', routerLink: ['/cases'], badgeClass: 'p-badge-info'},
                    {label: 'Health Care Providers', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/providers'], badgeClass: 'p-badge-help'},
                    // {label: 'Analytics', icon: 'pi pi-chart-line',
                    //     routerLink: ['/favorites/dashboardanalytics'], badgeClass: 'p-badge-success'}
                ]
            },
            {
                label: 'Parameters', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                items: [
                    {label: 'Insurance Companies', icon: 'pi pi-fw pi-clone', routerLink: ['/catalogs/insurances'], badgeClass: 'p-badge-help'},                    
                    {label: 'Parts of the body', icon: 'pi pi-fw pi-book', routerLink: ['/catalogs/complaints'], badgeClass: 'p-badge-help'},
                    {label: 'Symptoms', icon: 'pi pi-fw pi-check-circle', routerLink: ['/catalogs/symptoms'], badgeClass: 'p-badge-help'},
                    // {label: 'Client Contact Info', icon: 'pi pi-fw pi-user-plus ', routerLink: ['/clients'], badgeClass: 'p-badge-help'},
                    {label: 'Languages', icon: 'pi pi-fw pi-list', routerLink: ['/catalogs/languages'], badgeClass: 'p-badge-help'},
                    {label: 'Identification Types', icon: 'pi pi-fw pi-id-card', routerLink: ['/catalogs/idtypes'], badgeClass: 'p-badge-help'},
                    {label: 'Paralegals', icon: 'pi pi-fw pi-users', routerLink: ['/catalogs/paralegals'], badgeClass: 'p-badge-help'},
                    {label: 'Car Expenses', icon: 'pi pi-credit-card', routerLink: ['/catalogs/carexpensestype'], badgeClass: 'p-badge-help'},
                    {label: 'Vehicle Makes', icon: 'pi pi-briefcase', routerLink: ['/catalogs/vehiclemake'], badgeClass: 'p-badge-help'},
                    {label: 'Document Types', icon: 'pi pi-fw pi-id-card', routerLink: ['/catalogs/documentTypes'], badgeClass: 'p-badge-help'},
                    {label: 'Online Portals', icon: 'pi pi-cloud', routerLink: ['/catalogs/portals'], badgeClass: 'p-badge-help'},
                    {label: 'Health Care Provider Document Types', icon: 'pi pi-fw pi-file-pdf', routerLink: ['/catalogs/healthcaredocTypes'], badgeClass: 'p-badge-help'},
                    // {label: 'States', icon: 'pi pi-fw pi-id-card', routerLink: ['/catalogs/states'], badgeClass: 'p-badge-help'},
                    // {label: 'Counties', icon: 'pi pi-fw pi-id-card', routerLink: ['/catalogs/counties'], badgeClass: 'p-badge-help'},
                ]
            },
            // {
            //     label: 'New Cases (Steps)', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
            //     items: [
            //         {label: '1. Case', icon: 'pi pi-fw pi-folder', routerLink: ['/']},
            //         {label: '2. Accident', icon: 'pi pi-fw pi-bookmark', routerLink: ['/accidents']},
            //         {label: '3. Cars', icon: 'pi pi-fw pi-key ', routerLink: ['/clients'], badgeClass: 'p-badge-help'},
            //         {label: '4. Insurance Involved', icon: 'pi pi-fw pi-shield ', routerLink: ['/clients'], badgeClass: 'p-badge-help'}
            //         {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
            //          {label: 'List of Accidentes', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/table'], badgeClass: 'p-badge-help'}
            //     ]
            // },

            /*Desde aqui en adelante debemos tenerlo como comentarios
            para que no aparezcan las opciones de menu en el programa.
            {
                label: 'UI Kit', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'],
                items: [
                    {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'], badge: '6', badgeClass: 'p-badge-danger'},
                    {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
                    {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate']},
                    {label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'], class: 'rotated-icon'},
                    {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'], badge: '6', badgeClass: 'p-badge-help'},
                    {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                    {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                    {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                    {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                    {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                    {label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu']},
                    {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                    {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                    {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                    {label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc']}
                ]
            },
            {
                label: 'Utilities', icon: 'pi pi-fw pi-compass', routerLink: ['utilities'],
                items: [
                    {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'], badge: '6', badgeClass: 'p-badge-warning'},
                    {label: 'Display', icon: 'pi pi-fw pi-desktop', routerLink: ['utilities/display']},
                    {label: 'Elevation', icon: 'pi pi-fw pi-external-link', routerLink: ['utilities/elevation']},
                    {label: 'FlexBox', icon: 'pi pi-fw pi-directions', routerLink: ['utilities/flexbox']},
                    {label: 'Icons', icon: 'pi pi-fw pi-search', routerLink: ['utilities/icons']},
                    {label: 'Text', icon: 'pi pi-fw pi-pencil', routerLink: ['utilities/text']},
                    {label: 'Widgets', icon: 'pi pi-fw pi-star-o', routerLink: ['utilities/widgets']},
                    {label: 'Grid System', icon: 'pi pi-fw pi-th-large', routerLink: ['utilities/grid']},
                    {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', routerLink: ['utilities/spacing']},
                    {label: 'Typography', icon: 'pi pi-fw pi-align-center', routerLink: ['utilities/typography']}
                ]
            },
            {
                label: 'Pages', icon: 'pi pi-fw pi-briefcase', routerLink: ['/pages'],
                items: [
                    {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['/pages/crud']},
                    {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/pages/calendar']},
                    {label: 'Timeline', icon: 'pi pi-fw pi-calendar', routerLink: ['/pages/timeline']},
                    {
                        label: 'Landing', icon: 'pi pi-fw pi-globe', badge: '2', badgeClass: 'p-badge-warning',
                        items: [
                            {label: 'Static', icon: 'pi pi-fw pi-globe', url: 'assets/pages/landing.html', target: '_blank'},
                            {label: 'Component', icon: 'pi pi-fw pi-globe', routerLink: ['/landing']}
                        ]
                    },
                    {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['/login']},
                    {label: 'Invoice', icon: 'pi pi-fw pi-dollar', routerLink: ['/pages/invoice']},
                    {label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/pages/help']},
                    {label: 'Error', icon: 'pi pi-fw pi-times-circle', routerLink: ['/error']},
                    {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/notfound']},
                    {label: 'Access Denied', icon: 'pi pi-fw pi-lock', routerLink: ['/access']},
                    {label: 'Contact Us', icon: 'pi pi-fw pi-pencil', routerLink: ['/contactus']},
                    {label: 'Empty', icon: 'pi pi-fw pi-circle-off', routerLink: ['/pages/empty']}
                ]
            },
            {
                label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
                                    {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
                                items: [
                                    {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Start', icon: 'pi pi-fw pi-download',
                items: [
                    {
                        label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: ['https://www.primefaces.org/store']
                    },
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-info-circle', routerLink: ['/documentation']
                    }
                ]
            }
            */
        ];
    }
}
