<div [formGroup]="formGroup">
    <div class="p-grid p-formgrid p-fluid">
        <div class="p-field p-col-12 p-md-2">
            <label for="state"><label style="color:red">* </label> State</label>
            <p-dropdown id="state" formControlName="state" [options]="states" optionLabel="name" optionValue="isoCode"
                filter="true" autoDisplayFirst="false" placeholder="Choose" [scrollHeight]="'120px'"></p-dropdown>
        </div>
        <div *ngIf="countyEnabled" class="p-field p-col-12 p-md-2">
            <label for="county">County</label>
            <p-dropdown id="county" formControlName="county" [options]="counties" optionLabel="name" optionValue="name"
                filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'120px'"></p-dropdown>
        </div>        
        <div *ngIf="cityEnabled" class="p-field p-col-12 p-md-2">
            <label for="city">City</label>
            <p-dropdown id="city" formControlName="city" [options]="cities" optionLabel="name" optionValue="name"
                filter="true" autoDisplayFirst="false" placeholder="Choose" [scrollHeight]="'120px'"></p-dropdown>
        </div>        
        <div class="p-field p-col-12 p-md-4">
            <label for="street">Street</label>
            <input id="street" type="text" pInputText formControlName="street" autocomplete="doNotAutoComplete">
        </div>
        <div *ngIf="apartmentEnabled" class="p-field p-col-12 p-md-2">
            <label for="apartment">Apartment</label>
            <input id="apartment" type="text" pInputText formControlName="apartment" autocomplete="doNotAutoComplete">
        </div>
        <div *ngIf="countryVisible" class="p-field p-col-12 p-md-2">
            <label for="country">Country</label>
            <p-dropdown id="country" formControlName="country" [options]="countries" optionLabel="name"
                optionValue="isoCode" filter="true" autoDisplayFirst="false" placeholder="Choose"></p-dropdown>
        </div>                
        <div *ngIf="zipcodeEnabled" class="p-field p-col-12 p-md-2">
            <label for="zipcode">Zipcode</label>
            <input id="zipcode" type="text" pInputText formControlName="zipcode" autocomplete="doNotAutoComplete">
        </div>
    </div>
</div>