<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card">
                <div class="card-header">
                    <h5>I. Health Care Facility / Provider Information</h5>
                </div>
                <div *ngIf="provider != null" class="p-grid" [formGroup]="mainForm">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" 
                            class="p-button-text p-button-secondary"
                            (click)="cancel()">
                    </button>
                    <button pButton pRipple label="Save" icon="pi pi-check" 
                            class="p-button-text" 
                            [disabled]="!valid" 
                            (click)="store()">
                    </button>                    
                    <div class="p-grid p-fluid p-col-12 card" style="background-color: powderblue; margin: 5px; ">                        
                            <div class="p-field p-col-12 p-md-1">
                                <label for="type"><label style="color:red; font-size: 20px;">* </label>Provider type</label>
                                <span class="p-float-label">
                                    <p-dropdown id="type" formControlName="type" [options]="types" optionLabel="name" optionValue="value"
                                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'220px'"></p-dropdown>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label for="careGroup"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Affiliated Group</label>
                                <span class="p-float-label">
                                    <p-dropdown id="careGroup" formControlName="careGroup" [options]="careGroupList" optionLabel="name" optionValue="value"
                                        filter="true" autoDisplayFirst="false" autocomplete="doNotAutoComplete" placeholder="Choose" [scrollHeight]="'220px'"></p-dropdown>
                                </span>
                            </div>
                            <div class="p-field p-col-12 p-md-2">
                                <label for="providerName">
                                    <label style="color:red; font-size: 20px;">* </label>
                                        <label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Facility/Provider Name</label>
                                <span class="p-float-label">
                                    <input id="providerName" type="text" pInputText formControlName="providerName" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                                </span>
                            </div>  
                            <div class="p-field p-col-12 p-md-2">
                                <label for="aka">
                                    <label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Previously or Also Known As</label>
                                <span class="p-float-label">
                                    <input id="aka" type="text" pInputText formControlName="aka" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                                </span>
                            </div>  
                            <div class="p-field p-col-12 p-md-1">
                                <label for="acronym">                                
                                        <label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Provider Acronym</label>
                                <span class="p-float-label">
                                    <input id="acronym" type="text" pInputText formControlName="acronym" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
                                </span>
                            </div>  
                            <div class="p-field p-col-12 p-md-1">
                                <label for="phoneNumber"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Phone Number</label>
                                <span class="p-float-label">                            
                                    <p-inputMask id="phoneNumber" mask="(999) 999-9999"  formControlName="phoneNumber" autocomplete="doNotAutoComplete"></p-inputMask>
                                </span>
                            </div>
                            <!-- <div class="p-field p-col-12 p-md-1">
                                <label for="faxNumber"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Fax Number</label>
                                <span class="p-float-label">
                                    <p-inputMask id="faxNumber" mask="(999) 999-9999"  formControlName="faxNumber" autocomplete="doNotAutoComplete"></p-inputMask>                            
                                </span>
                            </div> -->
                            <div class="p-field p-col-12 p-md-2">
                                <label for="website"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Website</label>
                                <span class="p-float-label">
                                    <input id="website" type="text" pInputText formControlName="website" autocomplete="doNotAutoComplete">
                                </span>
                            </div>  
                            <div class="p-field p-col-12 p-md-1">
                                <label for="status"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Status</label>
                                <span class="p-float-label">
                                    <p-dropdown id="status" formControlName="status" [options]="statuses" optionValue="value" optionLabel="name"                  
                                    autoDisplayFirst="false" placeholder="Choose"></p-dropdown>                    
                                </span>
                            </div>                                                                                                        
                        <address *ngIf="addressEnabled" formGroupName="address"></address>
                    </div>

                   
                    <p></p>
                    <!-- TABS CONFIGURATIONS -->

                    <p-tabView #tabViewRef [style]="{'height':'600px'}">   
                        <p-tabPanel [header]="getDepartmentsHeader()">
                            <div style="text-align: left; margin-top: 10px;color:red;">
                                <p><strong>Health Information Management Departments (HIM) at <i>{{ provider.providerName }} {{ getProviderTypeName(provider.type) }}</i></strong></p>
                                <p></p>
                            </div>
                            <p-table #dtc [value]="provider.departments" dataKey="id" selectionMode="single"
                                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Office Name</th>
                                            <th>Phone Number</th>
                                            <th>Created By</th>
                                            <th>Created On</th>                                            
                                            <th>Status</th>
                                            <th>
                                                <p-button label="Add Department" icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                                    (click)="addDepartment()"></p-button>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-object>
                                        <tr [pSelectableRow]="note">
                                            <td>{{object.deptName}}</td>
                                            <td>{{object.phoneNumber}}</td>    
                                            <td>{{object.createdBy}}</td>                                            
                                            <td>{{object.creationDate | date:'E, MMM dd, yyyy'}}</td>
                                            <td></td>
                                            <td>
                                                <p-button type="button" label="Edit Department" icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button" (click)="editDepartment(object)"></p-button>
                                            </td>
                                        </tr>
                                    </ng-template>
                
                                    <ng-template pTemplate="summary">
                                        <div class="p-d-flex p-ai-center p-jc-between">
                                            Total of {{results ? results.total : 0 }}.
                                        </div>
                                    </ng-template>
                
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">No records available.</td>
                                        </tr>
                                    </ng-template>
                            </p-table>                             
                        </p-tabPanel>    
                        <p-tabPanel [header]="getOutsourcingsHeader()">
                            <div style="text-align: left; margin-top: 10px;color:red;">
                                <p><strong>Outsourced services for <i>{{ provider.providerName }}</i> </strong></p>
                                <p></p>
                            </div>
                            <p-table #dtc [value]="provider.outsourcings" dataKey="id" selectionMode="single"
                                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Outsourcing Co.</th>
                                            <th>Department</th>
                                            <th>Service</th>                                            
                                            <th>Status</th>
                                            <th>
                                                <p-button label="Add New Provider" icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                                    (click)="addOutsourcing()"></p-button>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-object>
                                        <tr [pSelectableRow]="note">
                                            <td>{{object.company.providerName}}</td>    
                                            <td>{{object.department.deptName}}</td>
                                            <td>{{object.service?.doctype.name}}</td>
                                            <td></td>
                                            <td>
                                                <button pButton pRipple icon="pi pi-pencil"
                                                    class="p-button-rounded p-button-success p-mr-2" (click)="editOutsourcing(object)"></button>
                                            </td>
                                        </tr>
                                    </ng-template>
                
                                    <ng-template pTemplate="summary">
                                        <div class="p-d-flex p-ai-center p-jc-between">
                                            Total of .
                                        </div>
                                    </ng-template>
                
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">No records available.</td>
                                        </tr>
                                    </ng-template>
                            </p-table>            
                        </p-tabPanel>
                        <p-tabPanel [header]="getBranchesHeader()">
                            <p-table #dtc  [value]="provider.locations" dataKey="id" selectionMode="single"
                                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="10" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>                            
                                            <th pSortableColumn="name">Contact</th>
                                            <th pSortableColumn="name">Address</th>
                                            <th pSortableColumn="name">Phone Number</th>
                                            <th pSortableColumn="name">Status</th>                                            
                                            <th>
                                                <p-button label="Add New Location" icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                                    (click)="addLocation()"></p-button>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-object>
                                        <tr [pSelectableRow]="provider">
                                            <td>{{object.officeContact}}</td>
                                            <td>
                                                <div *ngIf="object.address">
                                                <div>{{object.address.street}},
                                                <strong *ngIf="object.address.apartment">Suite No: {{object.address.apartment}}.</strong>
                                                {{object.address.city}}.{{object.address?.state}}. {{object.address?.zipcode}}</div>
                                                </div>
                                            </td>
                                            <td>{{object.phoneNumber}}</td>
                                            <td></td>                
                                            <td>
                                                <p-button type="button" label="Edit Location Info." icon="pi pi-pencil" styleClass="p-button-rounded p-button-raised p-button-success p-button-text large-button" (click)="editLocation(object)"></p-button>
                                            </td>
                                        </tr>
                                    </ng-template>
                
                                    <ng-template pTemplate="summary">
                                        <div class="p-d-flex p-ai-center p-jc-between">
                                            Total of {{objects ? objects.length : 0 }}.
                                        </div>
                                    </ng-template>
                
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">No providers found.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                        </p-tabPanel>                                        
                        <p-tabPanel [header]="getNotesHeader()">
                            <p-table #dtc [value]="provider.providerNotes" dataKey="id" selectionMode="single"
                                    styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    reorderableColumns="true" sortField="noteCreatedOn" sortOrder="-1"
                                    [scrollable]="true" scrollHeight="600px" [filterDelay]="0" [rows]="4" [paginator]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Created By</th>
                                            <th pSortableColumn="eventDate">Event Date
                                                <p-sortIcon field="eventDate"></p-sortIcon>
                                            </th>
                                            <th pSortableColumn="noteCreatedOn">Created On
                                                <p-sortIcon field="noteCreatedOn"></p-sortIcon>
                                            </th>
                                            <th>Notes</th>
                                            <th>
                                                <p-button label="Add New Note" icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                                    (click)="addNote()"></p-button>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-object>
                                        <tr [pSelectableRow]="note">
                                            <td>{{object.username}}</td>    
                                            <td>{{object.eventDate | date:'E, MMM dd, yyyy'}}</td>
                                            <td>{{object.noteCreatedOn | date:'short'}}</td> 
                                            <!-- <td>{{(object.comments | slice:0:100)+'...'}}</td> -->
                                            <td [pTooltip]="object.comments" tooltipPosition="top">{{(object.comments.length>100)? (object.comments | slice:0:100)+'...':(object.comments)}}</td>

                                            <td>
                                                <button pButton pRipple icon="pi pi-pencil"
                                                    class="p-button-rounded p-button-success p-mr-2" (click)="editNote(object)"></button>
                                                <!-- <button pButton pRipple icon="pi pi-trash"
                                                    class="p-button-rounded p-button-success p-mr-2" (click)="delete(object)"></button> -->
                                            </td>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="summary">
                                        <div class="p-d-flex p-ai-center p-jc-between">
                                            Total of {{notes ? notes.length : 0 }}.
                                        </div>
                                    </ng-template>

                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">No notes found.</td>
                                        </tr>
                                    </ng-template>
                            </p-table>  
                        </p-tabPanel>                
                        <p-tabPanel [header]="getDocsHeader()">
                            <p-table #dtc [value]="provider.docs" dataKey="id" selectionMode="single"
                            styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [scrollable]="true"  sortField="createdOn" sortOrder="-1"   scrollHeight="440px" [filterDelay]="0" [rows]="10" [paginator]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 2rem;"> <!-- Adjust width as needed -->
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox> <!-- This adds a checkbox to select/deselect all rows -->
                                    </th>
                                    <th >Type</th>
                                    <th >Name</th>
                                    <th>Created On</th>
                                    <th>Created By</th>
                                    <th>Preview</th>
                                    <th>
                                        <!-- <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-text"
                                            (click)="addDocuments()"></p-button> -->
                                        <p-button type="button" label="Upload Documents" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addDocuments()"></p-button>
                                        <!-- <p-button type="button" label="Download Selected" icon="pi pi-download" styleClass="p-button-rounded p-button-raised p-button-info p-button-text" [disabled]="!valid" (click)="addDocuments()"></p-button> -->
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-object>
                                <tr [pSelectableRow]="client">
                                    <td  style="width: 28px;">
                                        <p-tableCheckbox [value]="object"></p-tableCheckbox> <!-- This adds a checkbox for each row -->
                                    </td>
                                    <td>
                                        {{object.type?.name}}
                                    </td>
                                    <td>
                                        {{object.fileInfo.name}} <!--{{object.fileInfo.uri}} -->
                                    </td>
                                    <td>
                                        {{ object.createdOn | date}} {{ object.createdOn | date: 'h:mm a' }}
                                    </td>
                                    <td>{{object.createdBy}}</td>
                                    <td>
                                        <a *ngIf="object.fileInfo.mimeType.startsWith('image')" href="{{getBlobUrl(object)}}" target="_blank">
                                            <img src="{{getBlobUrl(object)}}" alt="{{object.fileInfo.name}}" width="75" [preview]="true"/>
                                        </a>                                
                                        <a *ngIf="!object.fileInfo.mimeType.startsWith('image')" href="{{getBlobUrl(object)}}" target="_blank">
                                            {{object.fileInfo.name}}
                                        </a>
                                    </td>

                                    <td>
                                        <!-- <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-success p-mr-2" (click)="deleteDocument(object)">
                                        </button> -->
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div class="p-d-flex p-ai-center p-jc-between">
                                    Total of {{provider.documents ? provider.documents.length : 0 }}.
                                </div>
                            </ng-template>

                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">No documents found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                            
                        </p-tabPanel>                                                    
                    </p-tabView>      
                    <div class="p-field p-col-12" style="display: flex; justify-content: flex-end;">
                        <div class="p-field p-col-12 p-md-2">
                            <label for="creationDate"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"></label>Created On</label>
                            <p-calendar [maxDate]="today" id="creationDate" showTime="true" showSeconds="false" dateFormat="D, MM dd, yy" [hourFormat]="12" formControlName="creationDate" disabled="true"></p-calendar>                        
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <label for="createdBy"><label style="color:rgba(255, 0, 0, 0); font-size: 20px;"> </label>Created By</label>
                            <input id="createdBy" type="text" pInputText formControlName="createdBy" readonly autocomplete="doNotAutoComplete">
                        </div>              
                    </div>                    

                    <!-- End of Tab Configurations -->

                    
                    <div class="p-sm-12"></div>        

                    <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text p-button-secondary" (click)="cancel()"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button> -->
                </div>
        </div>
    </div>
</div>