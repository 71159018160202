<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'500px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-5">
                <label for="name">Name</label>
                <input id="name" type="text" pInputText formControlName="name" style="text-transform: capitalize;" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="phone">Phone Ext</label>
                <p-inputMask id="phone" mask="(999) 999-9999? x9999999999" formControlName="phone" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>                                                                                   
            <div class="p-field p-col-12 p-md-2">
                <label for="fax">Fax</label>
                <p-inputMask id="fax" mask="(999) 999-9999" formControlName="fax" autocomplete="doNotAutoComplete"></p-inputMask>
            </div>
            <div class="p-field p-col-12 p-md-5">
                <label for="email">Email</label>
                <input id="email" type="text" pInputText formControlName="email" autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="adjusterType"><label style="color:red">*</label>Type</label>
                <p-dropdown inputId="adjusterType" formControlName="adjusterType" [options]="adjusterTypes" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            </div>
            <br>
            <div class="p-field p-col-12 p-md-20">
                <address formGroupName="address"></address>    
            </div>   
        </div>                       
    </div>                      
</div>
        