<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!valid" (click)="store()"></button>

<div [style]="{'height':'450px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="username">Created By</label>
                <input id="username" type="text" pInputText formControlName="username" readonly autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="noteCreatedOn">Created On</label>
                <p-calendar id="noteCreatedOn" showTime="true" showSeconds="false" formControlName="noteCreatedOn" disabled="true"></p-calendar>
            </div>        
            <div class="p-field p-col-12 p-md-3">
                <label for="notifiedBy"><label style="color:red">*</label>Event By</label>
                <p-dropdown formControlName="notifiedBy" [options]="notificationType" optionLabel="name" optionValue="value" [autoDisplayFirst]="false" [readonly]="vnote.id != null"></p-dropdown>
            </div>  
            <div class="p-field p-col-12 p-md-2">
                <label for="eventDate"><label style="color:red">*</label>Event Date</label>
                <p-calendar id="eventDate" formControlName="eventDate" [disabled]="vnote.id != null"></p-calendar>
            </div>
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                <label for="comments">Comments</label>
                <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="false" id="the-textarea"
                    formControlName="comments" maxlength="2000" [readonly]="vnote.id != null"></textarea>
            </div>                                
        </div>
    </div>
</div>