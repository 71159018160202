import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimeNGModule } from '../primeng/primeng.module';
import { ContactsRoutes } from './contacts.routing';

import { AddressFormComponent } from './addressform/addressform.component';
import { PersonFormComponent } from './personform/personform.component';
import { PersonInputComponent } from './personinput/personinput.component';
import { ContactsService } from './contacts.service';

@NgModule({
    declarations: [
        AddressFormComponent,
        PersonFormComponent,  
        PersonInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNGModule,
        RouterModule.forChild(ContactsRoutes)
    ],
    exports: [
        AddressFormComponent,
        PersonFormComponent,
        PersonInputComponent
    ],
    providers: [
        ContactsService
    ]
})
export class ContactsModule {

}