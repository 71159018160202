import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '../../../cases.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntakeUseCase } from '../../../usecase/IntakeUseCase';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import Dates from 'src/app/utilities/Dates';
import { Directive, ElementRef, HostListener} from '@angular/core';

@Component({
    selector: 'note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
    note: any;
    mainForm: FormGroup;
    // statuses: any[] =  [{value:'Pending'},{value:'Investigating'}, {value:'Accepted'},{value:'Denied'}];

    notificationType: any[] = [
        {name: 'Mail', value:1},
        {name: 'Email', value: 2},
        {name: 'Phone call', value: 3},
        {name: 'Fax', value: 6},
        {name: 'Whatsapp/Text Message', value: 5},        
        {name: 'In Person', value: 4}
    ];

    taskProgress: any[] = [
        {name: 'Pending', value:1},
        {name: 'In Progress', value: 2},
        {name: 'Completed', value: 3}
    ];

    noteTypes: any[] = [
        {name: 'Medical Providers', value:1},
        {name: 'Loss of Earnings', value: 2},
        {name: 'Disability Slips', value: 3},
        {name: 'Physical Complaints', value: 4},
        {name: 'Personal Injury Protection (PIP)', value: 5},
        {name: 'Talked to Attorney', value: 6},
        {name: 'Pending Documents', value: 7},
        {name: 'Offer', value: 8}
    ];
   
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private casesService: CasesService, private intakeUseCase : IntakeUseCase, 
        private config: DynamicDialogConfig) {

        this.mainForm = fb.group({
            id:[],
            username:[],
            noteCreatedOn:[],
            noteType:[],
            reminderDate:[],
            taskProgress:[],
            taskcomments:[],
            completedOn:[],
            /*noteType:[{value: null, disabled: true}, Validators.required], --Funciona pero no se como negar el null es decir, Not Null*/
            comments:[null, Validators.required],
            notifiedBy:[null, Validators.required],
            eventDate:[null, Validators.required],
        });
    }

    get valid() : boolean {
        return this.mainForm.valid;
    }

    ngOnInit() {
        if(this.config.data) {
            this.mainForm.patchValue(this.config.data);
            this.note = this.config.data;
        }
    }

    store() {
        if(this.mainForm.invalid) {
            Object.values(this.mainForm.controls).forEach(control => {
                control.markAsTouched();
            });
        }
        if(this.mainForm.valid) {
            this.intakeUseCase.appendNote(this.mainForm.value);
            this.clean();
        }
    }

    clean() {
        this.mainForm.reset();
    }

    cancel() {
        this.intakeUseCase.closeNote();
        this.clean();
    }

    activate() {

    }

    inactivate() {

    }
    
}


@Directive({
    selector: '[appCharacterCounter]'
  })
  
  export class CharacterCounterDirective {

    @Input() maxLength: number;
  
    constructor(private el: ElementRef) { }
  
    @HostListener('input', ['$event.target'])
    onInput(textArea: HTMLTextAreaElement): void {
      if (textArea.value.length > this.maxLength) {
        textArea.value = textArea.value.substring(0, this.maxLength);
      }
    }
  }

