<button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
<button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="store()"></button>

<div [style]="{'height':'360px'}" [formGroup]="mainForm">
    <div class="card" style="background-color: powderblue;"> 
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-2">
                <label for="username">User</label>
                <input id="username" type="text" pInputText formControlName="username" readonly autocomplete="doNotAutoComplete">
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="statusLastModified">Value Date</label>
                <p-calendar id="statusLastModified" showTime="true" showSeconds="false" formControlName="statusLastModified" disabled="true"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="liabilityStatus"><label style="color:red">*</label>Status</label>
                <p-dropdown inputId="liabilityStatus" formControlName="liabilityStatus" [options]="statuses" optionLabel="value" optionValue="value" pTooltip="Pending shouldn't be use" tooltipPosition="top" [autoDisplayFirst]="false" [readonly]="liability.id != null"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label for="datebyInsurance"><label style="color:red">*</label>Insurance Notification</label>
                <p-calendar id="datebyInsurance" formControlName="datebyInsurance" [disabled]="liability.id != null"></p-calendar>
            </div>          
            <div class="p-field p-col-12 p-md-3">
                <label for="notifiedBy"><label style="color:red">*</label>Notified By</label>
                <p-dropdown formControlName="notifiedBy" [options]="yesno" optionLabel="name" optionValue="value"  [readonly]="liability.id != null" [autoDisplayFirst]="false"></p-dropdown>
            </div>  
            <div class="p-fluid p-formgrid p-grid p-field p-col-12 p-md-12">                               
                <label for="comments">Comments</label>
                <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize" [readonly]="liability.id != null" 
                    formControlName="comments"></textarea>                       
            </div>                                
        </div>
    </div>
</div>
