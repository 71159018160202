import { Routes } from '@angular/router';
import { CatalogFormComponent } from './form/catalogform.component';
import { CatalogListComponent } from './list/cataloglist.component';

export const CatalogsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: ':catalogType/new',
                component: CatalogFormComponent
            },
            {
                path: ':catalogType/:objectId',
                component: CatalogFormComponent
            },
            {
                path: ':catalogType',
                component: CatalogListComponent
            }
        ]
    }
];
